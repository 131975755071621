import {defineStore} from 'pinia';
import {authApi} from '@/api/auth';

const STORAGE_KEYS = {
    ACCESS_TOKEN: 'accessToken',
    EXPIRES_IN: 'expiresIn',
    TOKEN_TYPE: 'tokenType',
    USER_PROFILE: 'userProfile',
    SUBSCRIPTIONS: 'subscriptions',
    NOTIFICATIONS: 'notifications',
    ORDERS: 'orders',
    NOTIFICATION_COUNT: 'notificationCount',
};

export const useAuthStore = defineStore('auth', {
    state: () => ({
        accessToken: null,
        expiresIn: null,
        tokenType: null,
        userProfile: null,
        subscriptions: [],
        notifications: [],
        orders: [],
        notificationInterval: null,
        userProfileInterval: null,
        ordersInterval: null,
        notificationCount: 0,
    }),

    getters: {
        isAuthenticated: (state) =>
            !!state.accessToken && Date.now() < state.expiresIn,

        hasSpecificPlanActive: (state) => (planId) =>
            state.subscriptions.some(({ plan_id, from, to }) => {
                const now = new Date();
                return plan_id === planId && new Date(from) <= now && now <= new Date(to);
            }),
    },

    actions: {
        loadOrdersFromStorage() {
            const storedOrders = localStorage.getItem(STORAGE_KEYS.ORDERS);
            if (storedOrders) {
                this.orders = JSON.parse(storedOrders);
            }
        },

        async login(email, password) {
            const data = await authApi.login(email, password);
            this.setTokenData(data);
            await this.loadInitialData();
            this.startAllIntervals();
        },

        async forgotPassword(email) {
            return await authApi.forgotPassword(email);
        },

        async resetPassword(token, newPassword, confirmPassword) {
            return await authApi.resetPassword(token, newPassword, confirmPassword);
        },

        async registration(email, name, password, ref) {
            const data = await authApi.registration(email, name, password, ref);
            this.setTokenData(data);
            await this.loadInitialData();
            this.startAllIntervals();
        },

        async logout() {
            if (this.accessToken) {
                await authApi.logout(this.accessToken);
            }
            this.clearAuthData();
        },

        clearAuthData() {
            Object.keys(STORAGE_KEYS).forEach((key) => localStorage.removeItem(STORAGE_KEYS[key]));

            this.$reset();
            this.stopAllIntervals();
        },

        setTokenData(data) {
            const { access_token, expires_in, token_type } = data;

            this.accessToken = access_token;
            this.expiresIn = Date.now() + expires_in * 1000;
            this.tokenType = token_type;

            localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token);
            localStorage.setItem(STORAGE_KEYS.EXPIRES_IN, this.expiresIn.toString());
            localStorage.setItem(STORAGE_KEYS.TOKEN_TYPE, token_type);

            if (this.userProfile) {
                localStorage.setItem(STORAGE_KEYS.USER_PROFILE, JSON.stringify(this.userProfile));
            }
        },

        restoreState() {
            const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
            const expiresIn = localStorage.getItem(STORAGE_KEYS.EXPIRES_IN);
            const tokenType = localStorage.getItem(STORAGE_KEYS.TOKEN_TYPE);

            if (accessToken && expiresIn && tokenType && Date.now() < parseInt(expiresIn)) {
                this.accessToken = accessToken;
                this.expiresIn = parseInt(expiresIn);
                this.tokenType = tokenType;

                this.userProfile = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_PROFILE)) || null;
                this.subscriptions = JSON.parse(localStorage.getItem(STORAGE_KEYS.SUBSCRIPTIONS)) || [];
                this.notifications = JSON.parse(localStorage.getItem(STORAGE_KEYS.NOTIFICATIONS)) || [];
                this.orders = JSON.parse(localStorage.getItem(STORAGE_KEYS.ORDERS)) || [];
                this.notificationCount = parseInt(localStorage.getItem(STORAGE_KEYS.NOTIFICATION_COUNT)) || 0;

                this.startAllIntervals();
            }
        },

        async fetchUserProfile() {
            if (!this.accessToken) return;
            try {
                const profile = await authApi.fetchUserProfile(this.accessToken);
                this.userProfile = profile;
                this.subscriptions = profile.paid;
                localStorage.setItem(STORAGE_KEYS.USER_PROFILE, JSON.stringify(profile));
                localStorage.setItem(STORAGE_KEYS.SUBSCRIPTIONS, JSON.stringify(this.subscriptions));
            } catch (error) {
                console.error('Ошибка при получении данных профиля', error);
            }
        },

        async fetchNotifications() {
            if (!this.accessToken) return;
            try {
                const { notifications } = await authApi.fetchNotifications(this.accessToken);
                this.notifications = notifications;
                this.notificationCount = notifications.length;
                localStorage.setItem(STORAGE_KEYS.NOTIFICATIONS, JSON.stringify(notifications));
                localStorage.setItem(STORAGE_KEYS.NOTIFICATION_COUNT, this.notificationCount.toString());
            } catch (error) {
                console.error('Ошибка при получении уведомлений', error);
            }
        },

        async fetchOrdersList() {
            if (!this.accessToken) return;
            try {
                const orders = await authApi.fetchOrdersList(this.accessToken);
                this.orders = orders;
                localStorage.setItem(STORAGE_KEYS.ORDERS, JSON.stringify(orders));
            } catch (error) {
                console.error('Ошибка при получении списка оплаченных подписок', error);
            }
        },

        async loadInitialData() {
            await Promise.all([
                this.fetchUserProfile(),
                this.fetchNotifications(),
                this.fetchOrdersList(),
            ]);
        },

        startNotificationInterval() {
            this.stopNotificationInterval();
            this.notificationInterval = setInterval(() => {
                if (this.isAuthenticated) this.fetchNotifications();
            }, 10000);
        },

        startUserProfileInterval() {
            this.stopUserProfileInterval();
            this.userProfileInterval = setInterval(() => {
                if (this.isAuthenticated) this.fetchUserProfile();
            }, 10000);
        },

        startOrdersInterval() {
            this.stopOrdersInterval();
            this.ordersInterval = setInterval(() => {
                if (this.isAuthenticated) this.fetchOrdersList();
            }, 10000);
        },

        stopNotificationInterval() {
            clearInterval(this.notificationInterval);
            this.notificationInterval = null;
        },

        stopUserProfileInterval() {
            clearInterval(this.userProfileInterval);
            this.userProfileInterval = null;
        },

        stopOrdersInterval() {
            clearInterval(this.ordersInterval);
            this.ordersInterval = null;
        },

        startAllIntervals() {
            this.startNotificationInterval();
            this.startUserProfileInterval();
            this.startOrdersInterval();
        },

        stopAllIntervals() {
            this.stopNotificationInterval();
            this.stopUserProfileInterval();
            this.stopOrdersInterval();
        },

        async notificationRead(notificationId) {
            if (!this.accessToken) return;
            try {
                await authApi.notificationRead(this.accessToken, notificationId);
                this.notifications = this.notifications.filter(({ id }) => id !== notificationId);
                this.notificationCount = this.notifications.length;
                localStorage.setItem(STORAGE_KEYS.NOTIFICATIONS, JSON.stringify(this.notifications));
                localStorage.setItem(STORAGE_KEYS.NOTIFICATION_COUNT, this.notificationCount.toString());
            } catch (error) {
                console.error('Ошибка при пометке уведомления как прочитанного', error);
            }
        },
    },
});
