<template>
  <div class="row user-select-none mb-3">
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-header">Платные инструменты</div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <router-link to="/tools/keyword-research-v1" class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="blinking-indicator"></span>
                      <small>Работает</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Поиск ключ. слов</h2>
                      <small class="toolVersion d-flex align-items-center">v1</small>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <router-link to="/tools/trending-videos-v1" class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="blinking-indicator"></span>
                      <small>Работает</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Трендовые видео</h2>
                      <small class="toolVersion d-flex align-items-center">v1</small>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <router-link to="/tools/trending-shorts-v1" class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="blinking-indicator"></span>
                      <small>Работает</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Трендовые Shorts</h2>
                      <small class="toolVersion d-flex align-items-center">v1</small>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <div class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Поиск ключ. слов</h2>
                      <small class="toolVersion d-flex align-items-center">v2</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <div class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body align-content-center">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Трендовые видео</h2>
                      <small class="toolVersion ms-2">v2</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3">
              <div class="card h-100 rounded-4 text-decoration-none">
                <div class="card-body align-content-center">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center justify-content-between">
                      <h2 class="mb-0 fw-bolder fs-5">Трендовые Shorts</h2>
                      <small class="toolVersion ms-2">v2</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3 mb-xxl-0">
              <div class="card rounded-4 text-decoration-none h-100">
                <div class="card-body align-content-center">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center">
                      <h2 class="mb-0 fw-bolder fs-5">Поиск каналов</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-4 col-md-6 mb-3 mb-xxl-0">
              <div class="card rounded-4 text-decoration-none h-100">
                <div class="card-body align-content-center">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center">
                      <h2 class="mb-0 fw-bolder fs-5">Аналитика канала</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-4 col-md-6">
              <div class="card rounded-4 text-decoration-none h-100">
                <div class="card-body align-content-center">
                  <div class="position-relative">
                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">
                      <span class="yellow-indicator"></span>
                      <small>В разработке</small>
                    </div>
                    <div class="title-wrapper d-flex align-items-center">
                      <h2 class="mb-0 fw-bolder fs-5">Аналитика видео</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="row user-select-none">-->
<!--    <div class="col-12">-->
<!--      <div class="card rounded-4">-->
<!--        <div class="card-header">Бесплатные инструменты</div>-->
<!--        <div class="card-body">-->
<!--          <div class="row">-->
<!--            <div class="col-12 col-xxl-5 col-md-6">-->
<!--              <router-link to="/tools/competitor-analysis" class="card h-100 rounded-4 text-decoration-none">-->
<!--                <div class="card-body">-->
<!--                  <div class="position-relative">-->
<!--                    <div class="status-wrapper position-absolute" style="top: -30px; right: -20px;">-->
<!--                      <span class="blinking-indicator"></span>-->
<!--                      <small>Работает</small>-->
<!--                    </div>-->
<!--                    <div class="title-wrapper d-flex align-items-center justify-content-between">-->
<!--                      <h2 class="mb-0 fw-bolder fs-5">Анализ конкурентов</h2>-->
<!--                      <small class="toolVersion d-flex align-items-center">Beta</small>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<style scoped>
@keyframes blinking {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.blinking-indicator {
  height: 10px;
  width: 10px;
  background-color: #0d6efd;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: blinking 1s infinite;
}

.red-indicator {
  height: 10px;
  width: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: blinking 1s infinite;
}

.yellow-indicator {
  height: 10px;
  width: 10px;
  background-color: #ffc107;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: blinking 1s infinite;
}
.toolVersion {
  font-size: 15px;
  background: #0D6EFDFF;
  color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
<script setup>
</script>