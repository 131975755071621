<template>
  <header class="user-select-none">
    <nav class="navbar navbar-expand-lg header pt-3 pb-3 fixed-top">
      <div class="container">
        <button class="btn my-btn-auth rounded-4 navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" v-if="isNarrowScreen">
          <font-awesome-icon icon="angles-right" />
        </button>
        <button class="btn my-btn-auth rounded-4 navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" v-else-if="isNarrowMenuScreen">
          Меню
        </button>
        <router-link to="/" class="navbar-brand fs-1">Youme<small style="color: #0d6efd; font-size: 20px;">.GG</small></router-link>
        <div class="d-flex align-items-center userMenu" v-if="isAuthenticated && userProfile">
          <notification-component v-if="isAuthenticated && userProfile" />
          <div class="d-flex align-items-center userMenu" @click="toggleDropdown" style="cursor: pointer">
            <img :src="getProfileImageUrl(userProfile.profile_image)" class="me-2" alt="Профиль" style="width: 35px; height: 35px; border-radius: 100%">
            <div class="fw-bolder d-flex align-items-center" style="color: #777e8c">
              <template v-if="!isVeryNarrowScreen">
                {{ userProfile.name }}
              </template>
              <font-awesome-icon :icon="isDropdownVisible ? 'angle-up' : 'angle-down'" :class="{'ms-2': !isVeryNarrowScreen}" style="padding-top: 5px;" />
            </div>
            <div v-if="isDropdownVisible" class="dropdownMenu rounded-3" style="position: absolute; top: 72px; width: 200px; display: block;" :style="{ right: isVeryNarrowScreen ? '10px' : '' }">
              <ul>
                <li>
                  <button class="dropdown-item" @click="handleLogout">Выход</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center" v-else>
          <button @click="openLoginModal" class="btn my-btn-auth rounded-4" v-if="isNarrowScreen">
            <font-awesome-icon icon="right-to-bracket" class="fs-4" />
          </button>
          <button @click="openLoginModal" class="btn my-btn-auth rounded-4" v-else>Авторизация</button>
        </div>
      </div>
    </nav>
  </header>
  <LoginComponent ref="loginModalRef" />
  <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
    <div class="offcanvas-header justify-content-between">
      <router-link to="/" class="navbar-brand fs-1">Youme<small style="color: #0d6efd; font-size: 20px;">.GG</small></router-link>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body border-top">
      <canvasMenu />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/store/auth';
import { BASE_URL } from '@/config';
import { useToast } from 'vue-toastification';
import NotificationComponent from "@/components/NotificationComponent.vue";
import canvasMenu from "@/components/menu/canvasMenu.vue";

const LoginComponent = defineAsyncComponent(() =>
    import('@/components/auth/LoginComponent.vue')
);

const authStore = useAuthStore();
const toast = useToast();

const userProfile = computed(() => authStore.userProfile);

const isAuthenticated = computed(() => authStore.isAuthenticated);

const loginModalRef = ref(null);

const isDropdownVisible = ref(false);

const isNarrowScreen = ref(window.innerWidth < 372);
const isNarrowMenuScreen = ref(window.innerWidth < 992);
const isMobileScreen = ref(window.innerWidth <= 767);
const isVeryNarrowScreen = ref(window.innerWidth <= 450);


const updateScreenSize = () => {
  isNarrowScreen.value = window.innerWidth < 372;
  isMobileScreen.value = window.innerWidth <= 767;
  isVeryNarrowScreen.value = window.innerWidth <= 450;
};

const openLoginModal = () => {
  loginModalRef.value?.showModal();
};

watch(isAuthenticated, (newVal) => {
  if (newVal) {
    loginModalRef.value?.closeModal();
  }
});

const getProfileImageUrl = (url) => {
  return url ? `${BASE_URL}/storage/${url}` : '';
};

const toggleDropdown = () => {
  isDropdownVisible.value = !isDropdownVisible.value;
};

const closeMenus = (event) => {
  if (!event.target.closest('.userMenu')) {
    isDropdownVisible.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', closeMenus);
  window.addEventListener('resize', updateScreenSize);
});


onUnmounted(() => {
  document.removeEventListener('click', closeMenus);
  window.removeEventListener('resize', updateScreenSize);
});

const handleLogout = async () => {
  try {
    await authStore.logout();
    toast.info("Вы успешно вышли из системы");
  } catch (error) {
    toast.error("Ошибка при выходе");
  }
};

</script>

<style scoped>
.navbar {
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: rgb(119 126 140 / 0%);
}

.dropdownMenu {
  background-color: var(--card-bg-color);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1000;
  border: 2px var(--card-border-color) solid;
}

.dropdownMenu>ul {
  list-style: none;
  margin: 0;
  padding: 10px 0 5px;
}

.dropdownMenu>ul>li {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.dropdownMenu>ul>li>a,button {
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-item {
  padding: 10px 20px;
  display: block;
}

.dropdown-item:hover {
  background-color: #1d1a31;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-toggler {
  border: 0;
  font-size: 1rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.my-btn-auth:hover {
  color: #ffffff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
</style>
