<template>
  <div class="modal fade" ref="hiddenChannelsShortsModalRef" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="hiddenChannelsShortsModalLabel" aria-hidden="true">
    <div class="modal-dialog user-select-none" style="max-width: 400px;">
      <div class="modal-content rounded-4">
        <div class="modal-header border-bottom pe-4 ps-4">
          Скрытые каналы
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="hiddenChannels.length === 0">Нет скрытых каналов</div>
          <div class="responsive-outer" v-else style="overflow: auto; max-height: 400px;">
            <div v-for="channel in hiddenChannels" :key="channel.id" class="d-flex align-items-center channelList">
              <img :src="channel.avatar" alt="avatar" class="rounded-circle me-3" style="width: 40px; height: 40px;">
              <div class="flex-grow-1 text-truncate">
                <a class="text-decoration-none" :href="`https://www.youtube.com/channel/${ channel.id }`" target="_blank">{{ channel.title }}</a>
              </div>
              <button @click="removeChannel(channel.id)" class="btn btn-danger btn-sm rounded-4">Удалить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap';

export default {
  name: "HiddenChannelsShortsModal",
  setup() {
    const hiddenChannelsShortsModalRef = ref(null);
    let modalInstance = null;

    const hiddenChannels = ref(JSON.parse(localStorage.getItem('excludedChannels_shorts')) || []);

    const updateHiddenChannels = () => {
      hiddenChannels.value = JSON.parse(localStorage.getItem('excludedChannels_shorts')) || [];
    };

    onMounted(() => {
      if (hiddenChannelsShortsModalRef.value) {
        modalInstance = new Modal(hiddenChannelsShortsModalRef.value);
      }

      window.addEventListener('storage', updateHiddenChannels);

      return () => {
        window.removeEventListener('storage', updateHiddenChannels);
      };
    });

    const showModal = () => {
      modalInstance?.show();
    };
    const closeModal = () => modalInstance?.hide();

    const removeChannel = (channelId) => {
      const updatedChannels = hiddenChannels.value.filter(channel => channel.id !== channelId);
      hiddenChannels.value = updatedChannels;
      localStorage.setItem('excludedChannels_shorts', JSON.stringify(updatedChannels));
      window.dispatchEvent(new Event('storage'));
    };

    return { showModal, closeModal, hiddenChannelsShortsModalRef, hiddenChannels, removeChannel };
  }
}
</script>

<style scoped>
.responsive-outer::-webkit-scrollbar {
  display: none;
}

.responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
}

.channelList:not(:last-child) {
  border-bottom: 1px var(--theme-color-border) solid;
  padding-bottom: 10px;
  padding-top: 10px;
}

.channelList:last-child {
  padding-top: 10px;
}
</style>
