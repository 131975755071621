import axios from 'axios';
import { API_URL } from '@/config';

export const authApi = {
    async login(email, password) {
        const response = await axios.post(`${API_URL}/auth/login`, {
            email,
            password,
        });
        return response.data;
    },

    async forgotPassword(email) {
        const response = await axios.post(`${API_URL}/auth/forgot-password`, {
            email
        });
        return response.data;
    },

    async resetPassword(token, newPassword, confirmPassword) {
        const response = await axios.post(`${API_URL}/auth/reset-password`, {
            token,
            password: newPassword,
            password_confirmation: confirmPassword
        });
        return response.data;
    },

    async registration(email, name, password, ref) {
        const response = await axios.post(`${API_URL}/auth/registration`, {
            email,
            name,
            password,
            ref
        });
        return response.data;
    },

    async fetchUserProfile(token) {
        const response = await axios.get(`${API_URL}/auth/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    async fetchNotifications(token) {
        const response = await axios.get(`${API_URL}/auth/notifications`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    async fetchOrdersList(token) {
        const response = await axios.get(`${API_URL}/auth/order/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    async notificationRead(token, notificationId) {
        const response = await axios.post(`${API_URL}/auth/notification/read`, {
            notificationId
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    async logout(token) {
        await axios.get(`${API_URL}/auth/logout`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }
};
