<template>
  <div class="row user-select-none mb-3">
    <div class="col-12 mb-3 d-flex align-items-center d-none d-xl-block">
      <h1 class="fw-bolder mb-0 d-flex align-items-center pe-2">Трендовые Shorts v1</h1>
    </div>
    <div class="col-12">
      <breadcrumbs-component />
    </div>
  </div>
  <div class="row user-select-none">
    <div :class="isNarrowMenuScreen ? 'col-12' : 'col-12 col-xxl-8 col-xl-8'">
      <not-authorized v-if="!isAuthenticated" />
      <no-subscription v-if="isAuthenticated && !hasPlan750Active" />
      <div class="card rounded-4">
        <hidden-results v-if="isAuthenticated" />
        <div class="card-body d-flex justify-content-center justify-content-sm-between align-items-center border-bottom" v-if="isNarrowMenuScreen">
          <h2 class="fs-5 mb-0 d-none d-sm-block">Фильтры</h2>
          <button class="btn btn-outline-warning w-25 rounded-4 d-none d-lg-block" @click="applyFilters" :disabled="trendingShortsStore.isLoading || !isAuthenticated || !hasPlan750Active">
            Применить фильтры
          </button>
          <button class="btn btn-outline-warning w-50 rounded-4 d-none d-sm-block d-lg-none" @click="applyFilters" :disabled="trendingShortsStore.isLoading || !isAuthenticated || !hasPlan750Active">
            Применить фильтры
          </button>
          <button class="btn btn-outline-warning w-75 rounded-4 d-block d-sm-none" @click="applyFilters" :disabled="trendingShortsStore.isLoading || !isAuthenticated || !hasPlan750Active">
            Применить фильтры
          </button>
        </div>
        <div class="card-footer border-bottom" v-if="isNarrowMenuScreen">
          <div class="row">
            <div class="col-12 col-sm-6 pe-sm-0">
              <div class="card">
                <channel-filters />
              </div>
            </div>
            <div class="col-12 col-sm-6 ps-sm-0">
              <div class="card">
                <video-filters />
              </div>
            </div>
          </div>
        </div>
        <search-result v-if="trendingShortsStore.trendingShortsData" />
        <top-trending-shorts v-else />
      </div>
    </div>
    <div class="col-xxl-4 col-xl-4" v-if="!isNarrowMenuScreen">
      <div class="sticky-top" style="top: 85px">
        <div class="card rounded-4">
          <div class="card-header">Поисковые фильтры</div>
          <div class="card-body">
            <div class="card rounded-4">
              <channel-filters />
              <video-filters />
            </div>
          </div>
          <div class="card-footer">
            <div class="row justify-content-center">
              <div class="col-12 col-xxl-8">
                <button class="btn btn-outline-warning w-100 rounded-4" @click="applyFilters" :disabled="trendingShortsStore.isLoading || !isAuthenticated || !hasPlan750Active">Применить фильтры</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { useTrendingShortsStore } from "@/store/tools/v1/TrendingShorts";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TopTrendingShorts from "@/components/tools/v1/TrendingShorts/TopTrendingShorts.vue";
import NotAuthorized from "@/components/tools/notifications/NotAuthorized.vue";
import NoSubscription from "@/components/tools/notifications/NoSubscription.vue";
import HiddenResults from "@/components/tools/v1/TrendingShorts/HiddenResults.vue";
import VideoFilters from "@/components/tools/v1/TrendingShorts/filters/VideoFilters.vue";
import ChannelFilters from "@/components/tools/v1/TrendingShorts/filters/ChannelFilters.vue";
import SearchResult from "@/components/tools/v1/TrendingShorts/SearchResult.vue";

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const hasPlan750Active = computed(() => authStore.hasSpecificPlanActive(750));
const trendingShortsStore = useTrendingShortsStore();

const isNarrowMenuScreen = ref(window.innerWidth < 1200);

const updateScreenSize = () => {
  isNarrowMenuScreen.value = window.innerWidth < 1200;
};

const applyFilters = async () => {
  const excludedChannels = JSON.parse(localStorage.getItem('excludedChannels_shorts')) || [];
  const excludedCountries = JSON.parse(localStorage.getItem('excludedCountries_shorts')) || [];
  const excludedChannelIds = excludedChannels.map(ch => ch.id);

  trendingShortsStore.setExcludedChannelIds(excludedChannelIds);
  trendingShortsStore.setExcludedCountryIds(excludedCountries);
  trendingShortsStore.isFirstLoad = true;
  trendingShortsStore.noMoreResults = false;
  await trendingShortsStore.fetchTrendingShortsData();
};

onMounted(() => {
  window.addEventListener('resize', updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenSize);
});
</script>