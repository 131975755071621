import axios from 'axios';
import { API_URL } from '@/config';

export const faqApi = {

    async getAllInstructions() {
        const url = `${API_URL}/faq/all`;
        const response = await axios.get(url);
        return response.data;
    },

    async fetchInstructionsBySlug(slug) {
        const url = `${API_URL}/faq/${slug}`;
        const response = await axios.get(url);
        return response.data;
    }
};