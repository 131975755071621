<template>
  <div class="card-body">
    <div class="loading-skeleton rounded-4" style="width: 340px; height: 25px;"></div>
  </div>
  <div class="card-footer">
    <div class="row mb-3" v-for="n in 5" :key="n">
      <div class="col-12 col-xxl-5 col-xl-6 col-lg-5 col-md-6 col-sm-5">
        <div class="sticky-top" style="top: 85px;">
          <div class="card rounded-4">
            <div class="card-header">
              <div class="row">
                <div class="col-12 mb-2 d-flex justify-content-center">
                  <div class="loading-skeleton" style="border-radius: 100%; width: 115px; height: 115px;"></div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <div class="loading-skeleton rounded-4" style="width: 156px; height: 15px;"></div>
                </div>
              </div>
            </div>
            <div class="card-body pt-2 pb-2">
              <div class="loading-skeleton rounded-4" style="width: 156px; height: 30px;"></div>
            </div>
            <div class="card-footer">
              <ul class="list-group list-group-flush">
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xxl-7 col-xl-6 col-lg-7 col-md-6 col-sm-7">
        <div class="card rounded-4">
          <div class="card-header p-0">
            <div class="loading-skeleton mb-3 rounded-top-4" style="width: 379px; height: 284px;"></div>
            <div class="loading-skeleton rounded-4 mb-3 me-3 ms-3" style="width: 320px; height: 20px;"></div>
          </div>
          <div class="card-body pt-2 pb-2">
            <div class="loading-skeleton rounded-4" style="width: 200px; height: 30px;"></div>
          </div>
          <div class="card-footer">
            <ul class="list-group list-group-flush">
              <li class="ps-0 pe-0 list-group-item">
                <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
              </li>
              <li class="ps-0 pe-0 list-group-item">
                <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
              </li>
              <li class="ps-0 pe-0 list-group-item">
                <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
              </li>
              <li class="ps-0 pe-0 list-group-item">
                <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-group-item {
  background-color: #ffffff00!important;
}
</style>