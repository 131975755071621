import { defineStore } from 'pinia';
import { useAuthStore } from "@/store/auth";
import { CompetitorAnalysisApi } from "@/api/tools/beta/CompetitorAnalysisApi";

export const useCompetitorAnalysisStore = defineStore('competitorAnalysisStore', {
    state: () => ({
        myProjects: [],
        myCompetitors: [],
        myPotentialCompetitors: [],
        currentProject: null,
        error: null,
        averageStatisticsByShorts: null,
        averageStatisticsByLong: null
    }),
    actions: {
        async fetchMyProjects() {
            await this.executeWithAuth(async (accessToken) => {
                const projects = await CompetitorAnalysisApi.fetchMyProjects(accessToken);
                this.setMyProjects(projects);
            });
        },

        async fetchCompetitors(projectId) {
            await this.executeWithAuth(async (accessToken) => {
                const project = await CompetitorAnalysisApi.fetchCompetitors(accessToken, projectId);
                this.setMyCompetitors(project);
            });
        },

        async fetchPotentialCompetitors(projectId) {
            await this.executeWithAuth(async (accessToken) => {
                const project = await CompetitorAnalysisApi.fetchPotentialCompetitors(accessToken, projectId);
                this.setMyPotentialCompetitors(project);
            });
        },

        async confirmPotentialCompetitor(requestData) {
            await this.executeWithAuth(async (accessToken) => {
                return await CompetitorAnalysisApi.confirmPotentialCompetitor(accessToken, requestData);
            });
        },

        async fetchProjectById(projectId) {
            await this.executeWithAuth(async (accessToken) => {
                const project = await CompetitorAnalysisApi.fetchProjectById(accessToken, projectId);
                this.setProject(project);
            });
        },

        async fetchAverageStatisticsByLongVideos(projectId) {
            await this.executeWithAuth(async (accessToken) => {
                const project = await CompetitorAnalysisApi.fetchAverageStatisticsByLongVideos(accessToken, projectId);
                this.setProjectAverageStatisticsByLongVideos(project);
            });
        },

        async fetchAverageStatisticsByShortsVideos(projectId) {
            await this.executeWithAuth(async (accessToken) => {
                const project = await CompetitorAnalysisApi.fetchAverageStatisticsByShortsVideos(accessToken, projectId);
                this.setProjectAverageStatisticsByShortsVideos(project);
            });
        },

        async executeWithAuth(callback) {
            const authStore = useAuthStore();

            if (!authStore.accessToken) {
                this.setError("No access token available");
                return;
            }

            try {
                await callback(authStore.accessToken);
            } catch (error) {
                this.setError(error.message || error);
            }
        },

        async createProject(requestData) {
            await this.executeWithAuth(async (accessToken) => {
                try {
                    const response = await CompetitorAnalysisApi.createProject(accessToken, requestData);
                    if (response && response.id) {
                        console.log('Проект успешно создан:', response);
                        this.setCurrentProjectId(response.id); // Устанавливаем ID текущего проекта в сторе
                    } else {
                        console.error('Ошибка при создании проекта: неверный формат ответа');
                    }
                } catch (error) {
                    console.error('Ошибка при создании проекта:', error.response.data);
                }
            });
        },

        async addChannelsToProject(requestData) {
            await this.executeWithAuth(async (accessToken) => {
                return await CompetitorAnalysisApi.addChannelsToProject(accessToken, requestData);
            });
        },

        setMyProjects(projects) {
            this.myProjects = projects;
        },

        setMyCompetitors(projects) {
            this.myCompetitors = projects;
        },

        setMyPotentialCompetitors(projects) {
            this.myPotentialCompetitors = projects;
        },

        setProject(project) {
            this.currentProject = project;
        },

        setProjectAverageStatisticsByLongVideos(project) {
            this.averageStatisticsByLong = project;
        },

        setProjectAverageStatisticsByShortsVideos(project) {
            this.averageStatisticsByShorts = project;
        },

        setCurrentProjectId(projectId) {
            this.currentProject = projectId;
        },

        setError(message) {
            this.error = message;
        }
    }
});
