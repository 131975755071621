import {defineStore} from 'pinia';
import {subscriptionsApi} from '@/api/subscriptions/subscriptions';
import {useAuthStore} from '@/store/auth';

export const useSubscriptionsStore = defineStore('subscriptions', {
    state: () => ({
        subscriptionsList: [],
    }),
    actions: {
        async fetchSubscriptions() {
            try {
                this.subscriptionsList = await subscriptionsApi.fetchSubscriptions();
            } catch (error) {
                console.error('Ошибка при получении подписок', error);
            }
        },

        async initiatePayment(subscriptionId) {
            const authStore = useAuthStore();
            if (!authStore.accessToken) {
                throw new Error('Требуется авторизация для инициации оплаты');
            }

            try {
                return await subscriptionsApi.initiateSbpPayment(subscriptionId, authStore.accessToken);
            } catch (error) {
                throw new Error('Ошибка при инициации оплаты: ' + error.message);
            }
        },

        async initiateCardPayment(subscriptionId) {
            const authStore = useAuthStore();
            if (!authStore.accessToken) {
                throw new Error('Требуется авторизация для инициации оплаты картой');
            }

            try {
                return await subscriptionsApi.initiateCardPayment(subscriptionId, authStore.accessToken);
            } catch (error) {
                throw new Error('Ошибка при инициации оплаты картой: ' + error.message);
            }
        },

        async initiateUsdPayment(subscriptionId) {
            const authStore = useAuthStore();
            if (!authStore.accessToken) {
                throw new Error('Требуется авторизация для инициации оплаты USDT');
            }

            try {
                return await subscriptionsApi.initiateUsdPayment(subscriptionId, authStore.accessToken);
            } catch (error) {
                throw new Error('Ошибка при инициации оплаты USDT: ' + error.message);
            }
        }
    }
});
