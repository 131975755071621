<template>
  <breadcrumbs-component />
  <div class="card rounded-4 user-select-none mt-3">
    <div class="card-header">
      <h1 class="mb-0 text-center fs-4">Публичная оферта</h1>
    </div>
    <div class="card-body">
      <p>
        Настоящий документ представляет собой предложение ИП Ерасова Е.В. (далее – «Администрация»)
        физическим лицам (далее – «Пользователи»), принявшим (акцептовавшим) настоящее предложение на
        заключение договора оказания услуг, оказываемых Администрацией с помощью программы для ЭВМ,
        размещённой в информационно-телекоммуникационной сети Интернет по адресу https://youme.gg
        (далее – «Сервис»).
      </p>
      <p>В соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на
        условиях, изложенных в оферте.
      </p>
      <p>
        Пользователь обязан полностью ознакомиться с настоящим документом до момента пользования ресурсами
        Сервиса и оформления заказа услуги на Сервисе.
      </p>
      <p>
        Зарегистрировавшись на Сервисе, а также используя Сервис или воспользовавшись любой его функциональной
        возможностью, обратившись к любой странице Сервиса без регистрации, Пользователь выражает свое
        безоговорочное согласие со всеми условиями настоящей Оферты и обязуется их соблюдать или прекратить
        использование Сервиса, на следующих условиях:
      </p>
      <ol>
        <li class="mb-4">
          <h2>Определения и термины</h2>
          <ol class="sub-list">
            <li>Нижеприведенные термины используются в настоящем документе в следующем значении:
              <ol class="sub-sub-list">
                <li>«Акцепт Оферты» - полное и безоговорочное принятие Пользователем условий настоящей
                  публичной оферты путем совершения действий, указанных в ней.</li>
                <li>«Пользователь» - физическое лицо, посетитель Сервиса, который знакомится с видами Услуг,
                  представленных Администрацией, пользуется справочными и информационными ресурсами Сервиса,
                  собирается сделать заказ Услуг, или уже осуществляет Заказ в режиме реального времени, или
                  который уже использует Услуги, приобретенные им ранее у Администрации.</li>
                <li>«Администрация» - гражданка РФ ИП Ерасова Е.В. (ИНН 632524064755, ОГРН 324632700053071),
                  которая оказывает Услуги посредством Сервиса.</li>
                <li>«Сервис» - Интернет-ресурс Администрации, расположенный по адресу https://youme.gg,
                  содержащий в себе все технические и функциональные возможности, необходимые для
                  осуществления Пользователем просмотра описаний Услуг и их приобретения (заказа).
                  Исключительные права на Сервис принадлежат Администрации.</li>
                <li>«Услуги» - услуги, реализуемые Администрацией Пользователям, включающие в себя подключение
                  Пользователю на определенный срок функциональных возможностей Сервиса в соответствии с
                  Подписками и условиями настоящей Оферты, информация о которых размещена на Сервисе.</li>
                <li> «Подписка» - совокупность функционала, предоставляемого Пользователю в течение оплаченного периода
                  использования Сервиса.</li>
                <li>«Договор» - договор оказания Услуг, заключаемый между Администрацией и Пользователем путем
                  Акцепта оферты последним.</li>
                <li>«Заказ» - заявка Пользователя на приобретение Услуг у Администрации, оформленная через
                  Сервис и представляющая собой свободное и самостоятельное намерение и волеизъявление
                  Пользователя приобрести у Администрации выбранную Пользователем Услугу с последующей
                  оплатой Подписки за определенный период оказания Услуг.</li>
              </ol>
            </li>
            <li> В настоящей Оферте могут быть использованы термины, не определенные в пункте 1.1. Оферты.
              В этих случаях толкование терминов производится в соответствии с текстом и смыслом данной
              Оферты. В случае отсутствия однозначного толкования термина в тексте Оферты, следует
              руководствоваться, во-первых, толкованием терминов, применяемым на Сервисе, в том числе
              в юридической документации, размещенной на Сервисе; во-вторых, законодательством Российской
              Федерации и обычаями деловой практики в соответствующей сфере деятельности.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Общие положения</h2>
          <ol class="sub-list">
            <li>Поиск, Заказ, оплата Пользователем Услуг, размещенных на Сервисе, использование Сервиса,
              включая просмотр размещенной в разделах Сервиса информации, пользование справочными и
              информационными ресурсами Сервиса, означает согласие Пользователя с настоящей Офертой.</li>
            <li>Администрация имеет право вносить изменения в Оферту без уведомления Пользователя.</li>
            <li>Администрация и Пользователь предоставляют взаимные гарантии своих прав и дееспособности,
              необходимых для заключения Договора и его исполнения на базе настоящей Оферты.</li>
            <li>Наименование, ассортимент и вид Услуг, их описание, стоимость и способы оплаты, а
              также иные условия, указываются на соответствующей веб-странице Сервиса.</li>
            <li>Оферта не требует скрепления печатями и/или подписания Администрацией и Пользователем
              (далее по тексту – «Стороны») на бумажном носителе, сохраняя при этом полную юридическую силу.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Предмет оферты</h2>
          <ol class="sub-list">
            <li>Администрация в рамках выбранной Пользователем Подписки предоставляет Пользователю право
              использовать Сервис в порядке, описанном в Оферте, на условиях простой неисключительной
              лицензии, если иное не предусмотрено Офертой.</li>
            <li>Пользователь вправе использовать Сервис на всей территории Российской Федерации, а также иных
              территориях, на которых он доступен с использованием стандартных компьютерных средств
              и программ.</li>
            <li>Сервис предоставляет Пользователям площадку для получения информационных данных (аналитику)
              по видеохостингу YouTube в объеме, предусмотренном техническими средствами Сервиса и Тарифами,
              установленными Администрацией.</li>
            <li>Функционал Сервиса и Услуги подробно описаны на странице Сервиса
              <router-link to="/tools" class="text-muted text-decoration-none fs-6">
                <span>https://youme.gg/tools</span>
              </router-link>.
            </li>
            <li>Стоимость Услуг, а также прочие необходимые условия Оферты определяются на основании сведений,
              предоставленных Пользователем при оформлении Заказа.</li>
            <li>Использование материалов и функций Сервиса регулируется нормами действующего законодательства
              Российской Федерации.</li>
            <li>Пользователь несет персональную ответственность за проверку настоящей Оферты на
              наличие изменений в ней.</li>
            <li>При несогласии Пользователя с соответствующими изменениями Пользователь обязан прекратить
              использование Сервиса и отказаться от Услуг, предоставляемых Администрацией.
              В противном случае продолжение использования Пользователем Сервиса означает, что
              Пользователь согласен с условиями Оферты в новой редакции.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Момент заключения Оферты (акцепт Оферты)</h2>
          <ol class="sub-list">
            <li>Акцептом настоящей Оферты (и как итог – заключение Договора) является оформление Пользователем
              Заказа в соответствии с условиями настоящей Оферты.</li>
            <li>Оферта вступает в силу с момента Акцепта и действует до полного исполнения
              Сторонами своих обязательств.</li>
            <li>Осуществляя Акцепт Оферты Пользователь гарантирует, что ознакомлен, соглашается,
              полностью и безоговорочно принимает все условия Оферты в том виде, в каком они изложены в тексте
              Оферты, в том числе в приложениях к Оферте, являющихся неотъемлемой частью Оферты
              (п. 3 ст. 438 ГК РФ). Пользователь понимает, что Акцепт Оферты равносилен заключению договора
              на условиях, изложенных в Оферте. Совершая действия по акцепту Оферты Пользователь гарантирует,
              что он имеет законные права вступать в договорные отношения с Администрацией.</li>
            <li>Акцептируя настоящую Оферту, Пользователь выражает согласие в том, что:
              <ol class="sub-sub-list">
                <li>данные Пользователя при входе в личный кабинет указаны им добровольно;</li>
                <li>иные данные, добровольно указанные Пользователем при Заказе, передаются Администрации
                  в электронной форме по каналам связи сети Интернет;</li>
                <li>данные переданы Администрации для реализации целей, указанных в настоящей Оферте;</li>
                <li>данное Пользователем согласие на обработку его данных является бессрочным и
                  может быть отозвано Пользователем или его законным представителем, подачей письменного
                  заявления, переданного Администрации.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Права и обязанности сторон</h2>
          <ol class="sub-list">
            <li>Администрация обязуется:
              <ol class="sub-sub-list">
                <li>Принять Заказ Пользователя согласно установленным на Сервисе формам.</li>
                <li>Предоставлять достоверную информацию об Услугах.</li>
                <li>На основании Заказа оказать соответствующую Услугу.</li>
                <li>Давать устные и письменные консультации Пользователю по
                  дополнительным вопросам Пользователя.</li>
                <li>Обеспечивать своевременное оказание Услуг.</li>
                <li>Использовать информацию, предоставленную Пользователем, только для оказания Услуг.</li>
                <li>Рассматривать и проверять мотивированные претензии, поступающие от Пользователя.</li>
              </ol>
            </li>
            <li>Администрация вправе:
              <ol class="sub-sub-list">
                <li>Отказаться от исполнения Договора в одностороннем порядке при нарушении
                  Пользователем условий, указанных в Оферте.</li>
                <li>Отказать Пользователю в исполнении его Заказа без объяснения причин.</li>
                <li>Расширять и сокращать предложение Услуг на Сервисе, регулировать доступ к заказу
                  Услуг, а также приостанавливать или прекращать реализацию любых Услуг по своему
                  собственному усмотрению.</li>
                <li>Изменять условия Оферты в одностороннем порядке.</li>
                <li>Отказать в оказании Услуг Пользователю в случае не поступления оплаты за Услугу в
                  установленные в Оферте сроки, а также при отсутствии от Пользователя своевременно
                  полученного Заказа или иных действий Пользователя, необходимых для оказания ему Услуги.</li>
                <li>Получать от Пользователя любую информацию, необходимую для выполнения своих обязательств
                  по Оферте. В случае непредставления либо неполного или неверного представления
                  Пользователем информации Администрация вправе приостановить исполнение своих
                  обязательств по Оферте до представления необходимой информации.</li>
                <li>Приостановить работу Сервиса в любое время без предварительного уведомления в целях
                  обновления Сервиса, проведения профилактических работ, в случае технических сбоев,
                  действием и/или бездействием третьих лиц и по иным причинам, не
                  зависящим от Администрации.</li>
                <li>За нарушение условий настоящей Оферты заблокировать доступ Пользователя к
                  Сервису без объяснения причин.</li>
              </ol>
            </li>
            <li>Пользователь обязуется:
              <ol class="sub-sub-list">
                <li>Знакомиться с информацией об Услугах, условиях их приобретения, Тарифах до
                  оформления Заказа.</li>
                <li>Самостоятельно и своевременно знакомится со всей информацией, размещаемой на Сервисе.</li>
                <li>Предоставить при регистрации только достоверные данные.</li>
                <li>Действовать добросовестно и не злоупотреблять своими правами, в частности не приобретать
                  Услуги с целью совершения недобросовестных действий, причиняющих ущерб, убытки или
                  затраты для Администрации.</li>
                <li>Не использовать информацию, полученную от Администрации способами, способными
                  привести к нанесению ущерба интересам Администрации.</li>
                <li>Не использовать материалы Сервиса без согласия Администрации как правообладателя
                  (статья 1270 ГК РФ).</li>
                <li>При цитировании материалов Сервиса в электронном виде размещать активные гиперссылки
                  на главную страницу Сервиса или на страницу размещения соответствующего материала.
                  При использовании информации на любом другом носителе необходимо указание источника
                  в виде «Источник: https://youme.gg».</li>
                <li>Оплатить Услугу в полном объеме (в случае выбора платной Подписки).</li>
                <li>Незамедлительно уведомлять Администрацию о наличии претензий к Услуге
                  (при наличии таковых).</li>
              </ol>
            </li>
            <li>Пользователь вправе:
              <ol class="sub-sub-list">
                <li>Требовать от Администрации надлежащего и своевременного оказания Услуг.</li>
                <li>Осуществить выбор Услуг, Подписок.</li>
                <li>Обращаться к Администрации по всем вопросам, связанным с оказанием Услуг.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Регистрация и авторизация</h2>
          <ol class="sub-list">
            <li>Предоставление Пользователю Услуг возможно при условии создания им на Сервисе соответствующей
              учетной записи для последующего входа в личный кабинет с целью получения доступа к функциям
              Сервиса.
            </li>
            <li>Учетная запись (регистрационный аккаунт) должна содержать имя Пользователя,
              адрес электронной почты, логин и пароль Пользователя.</li>
            <li>Пользователь в праве пройти регистрацию через свой аккаунт сервиса Google или Telegram</li>
            <li>После введения данных Пользователь обязан проставить «галочку», означающую его полное и
              безоговорочное согласие с настоящей Офертой.</li>
            <li>В регистрационной форме необходимо указывать только подлинные данные.</li>
            <li>Обязательным условием Регистрации является проставление «галочки» в форме «Google Captcha»
              (компьютерный тест, используемый для того, чтобы определить, что
              Пользователь является человеком.</li>
            <li>Авторизация на Сервисе производится путем заполнения Пользователем следующих данных:
              адрес электронной почты, пароль. Обязательным условием авторизации является проставление
              «галочки» в форме «Google Captcha» (компьютерный тест, используемый для того, чтобы определить,
              что Пользователь является человеком. Пользователь в праве пройти авторизацию через свой
              аккаунт сервиса Google или Telegram.</li>
            <li>Восстановление пароля осуществляется Пользователем самостоятельно.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Финансовые условия</h2>
          <ol class="sub-list">
            <li>Стоимость Услуг определяется Администрацией самостоятельно путем введения Подписок
              и их описания на страницах Сервиса.</li>
            <li>Подписки указаны на странице Сервиса https://youme.gg/subscriptions.</li>
            <li>Срок действия каждой Подписки – 1 месяц, под которым понимается период в
              30 календарных дней.</li>
            <li>Администрация вправе в любое время в одностороннем порядке изменять Подписки
              без уведомления об этом Пользователей.</li>
            <li>Цена Услуг указывается в рублях РФ. Все взаиморасчеты ведутся в рублях РФ.
              Стоимость Услуг НДС не облагается на основании п. 26 ч. 2 ст. 149 НК РФ.</li>
            <li>Оплата стоимости Услуг (заказ Услуг) производится Пользователем способами,
              установленными в соответствии с техническим и технологическим устройством Сервиса.</li>
            <li>Заказ Услуг производится Пользователем на условиях 100 % (стопроцентной) предварительной
              оплаты. Датой оплаты признается дата поступления средств на расчётный счет Администрации.</li>
            <li>Обязанность Пользователя по оплате считается исполненной в момент зачисления денежных средств
              на счет Администрации. Сроки зачисления денежных средств могут составлять до 3 (трех)
              банковских дней и не зависят от Администрации. При зачислении денежных средств
              Администрация направляет Пользователю на электронную почту либо в личный кабинет
              письмо об активации Подписки.</li>
            <li>В случае неоплаты либо просрочки оплаты Пользователем предусмотренной Офертой,
              Сервисом стоимости Услуг Администрация вправе не предоставлять доступ к Сервису и его
              функционалу до момента получения соответствующей суммы денежных средств.</li>
            <li>Выбор и использование способа оплаты Услуг производится Пользователем по
              собственному усмотрению и без какой-либо ответственности Оферента.
              Безопасность, конфиденциальность, а также иные условия использования
              выбранных Пользователем способов оплаты выходят за рамки Оферты и регулируются
              соглашениями между Пользователем и соответствующими организациями.</li>
            <li>В соответствии со ст. 14 Федерального Закона № 422-ФЗ от 27.11.2018,
              Администрация на каждую выплаченную ему Пользователем сумму Тарифа,
              обязуется направить на электронный адрес Пользователя, указанным им при регистрации на
              Сервисе, чек в электронном виде (или адресную ссылку на него),
              сформированный при расчете за Услуги, указанные в настоящей Оферте.</li>
            <li>Более подробную информацию о финансовых условиях Пользователь может получить по
              электронной почте admin@youme.gg.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Порядок приобретения Услуг. Отказ от Договора.</h2>
          <ol class="sub-list">
            <li>Пользователь вправе оформить Заказ на любую Услугу, представленную на Сервисе.</li>
            <li> Пользователь осуществляет выбор Услуг из перечня Услуг, предложенных на Сервисе
              с учетом Подписки и объема, после чего нажимает соответствующую кнопку.
              Тем самым Пользователь, реализуя алгоритм, предложенный Сервисом,
              самостоятельно создает Заказ через соответствующий веб-интерфейс.</li>
            <li>Выбор Услуг, оформление Заказа и все последующие действия Пользователя, описанные в
              настоящем разделе, осуществляются им самостоятельно, без участия Администрации.</li>
            <li>Для того, чтобы оформить Заказ на Сервисе Пользователю необходимо предоставить
              Администрации запрашиваемые интерфейсом Сервиса данные.</li>
            <li>Выбор способа оплаты и совершение иных сопутствующих действий, необходимых в связи
              с требованиями соответствующей выбранной платежной системы возлагается на Пользователя.</li>
            <li>Администрация не несет ответственности за содержание и достоверность информации,
              предоставленной Пользователем при оформлении Заказа. Пользователь берет на себя полную
              ответственность за предоставление неправильных данных о себе, которое сделало
              невозможным надлежащее исполнение Администрацией настоящей Оферты.</li>
            <li>Заказ с Сервиса передается в программу Администрации автоматически.</li>
            <li>Заключаемый между сторонами Договор имеет силу акта об оказании Услуг.
              Приемка-передача Товара производится без подписания между Сторонами соответствующих актов.
              В случае если в течение 5 (пяти) рабочих дней с момента окончания календарного месяца
              Администрация не получит претензий от Пользователя, связанных с оказанием Услуг,
              их объема и качества, Стороны будут считать, что Услуги оказаны Пользователю в полном объеме
              и надлежащим образом. Последующие претензии от Пользователя Администрацией не принимаются.</li>
            <li>Датой оказания Услуг является дата осуществления подключения (продления) функциональных
              возможностей Сервиса путем оплаты Пользователем выбранной им Подписки.</li>
            <li>Пользователь в праве в любое время отказаться от Договора в одностороннем порядке путем
              отправки электронного письма на адрес Исполнителя admin@youme.gg Администрация возвращает
              Пользователю денежные средства в объеме, пропорциональном времени, прошедшему с момента начала
              оказания Услуг (оплаты Подписки) до момента получения заявления на возврат,
              в течение 10 (десяти) дней с момента получения уведомления. Возврат денежных средств
              осуществляется за вычетом фактических затрат Исполнителя на момент возврата.
              К таким фактическим затратам Исполнителя относятся (но не ограничиваются), в частности,
              комиссии банковских, кредитных организаций и соответствующих платежных систем за
              осуществление возврата денежных средств, расходы на оплату услуг третьих лиц.
              Денежные средства переводятся по реквизитам, используемым Пользователем при оплате и
              указанные им в уведомлении.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Ответственность</h2>
          <ol class="sub-list">
            <li>При неисполнении или ненадлежащем исполнении обязательств Стороны несут ответственность,
              предусмотренную действующим законодательством РФ.</li>
            <li>Администрация никак не принуждает Пользователя, не настаивает делать какие-либо
              действия посредством Сервиса.</li>
            <li>Пользователь признает и соглашается, что:
              <ol class="sub-sub-list">
                <li>Он на добровольной основе производит оплату Подписок на Сервисе с целью получения
                  результата Услуг.</li>
                <li>Он несет ответственность за свои действия, совершенные на Сервисе или
                  сервере с его учетной записи.</li>
                <li>Услуги, приобретаемые Пользователем, предоставляется по принципу «как есть».
                  При этом Администрация не несет ответственность в какой бы то ни было форме за
                  несоответствие результата Услуг целям, задачам, представлениям или
                  желаниям Пользователя.</li>
                <li>Ничто в настоящей Оферте не может гарантировать для Пользователя полное удовлетворение
                  его интересов и потребностей, связанных с приобретением Услуг.</li>
              </ol>
            </li>
            <li>Администрация не несет ответственности:
              <ol class="sub-sub-list">
                <li>За убытки и расходы, возникшие у Пользователя, в частности, убытки и расходы,
                  вызванные действиями/бездействием третьих лиц и/или возникшие в связи со сбоями и
                  перерывами в работе Сервиса и/или возникшие в связи с воздействием компьютерных вирусов,
                  «троянов», «червей» и т.д., и/или связанные с блокировкой Пользователя,
                  использующего результат Услуг, после ее заказа, вне зависимости от причины блокировки.</li>
                <li>За аккаунт Пользователя.</li>
                <li>За неполучение результата Услуг Пользователем по причинам не зависящим от воли 
                  Администрации (отсутствие у Пользователя необходимого программного обеспечения, 
                  возможности выхода в Интернет, действий провайдеров, энергетических компаний, 
                  воздействием компьютерных вирусов и (или) вредоносных программ и т.п.).</li>
                <li>За практическую применимость и ценность аналитической информации, относящуюся к Услугам.</li>
                <li>За неполучение результата Услуг ниже ожиданий Пользователя, поскольку успешность
                  использования Пользователем полученных знаний, умений и навыков, отраженных в
                  аналитической информации, зависит от многих известных и неизвестных Администрации
                  факторов, что принимается обеими Сторонами.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Разрешение споров</h2>
          <ol class="sub-list">
            <li>Все споры, разногласия и претензии Стороны будут стремиться решить путем переговоров.
              Сторона, у которой возникли претензии и/или разногласия, направляет другой Стороне сообщение
              с указанием возникших претензий и/или разногласий.</li>
            <li>Сообщение направляется Пользователем по электронной почте на адрес admin@youme.gg.
              Сообщение должно содержать суть предъявляемого требования, доказательства,
              подтверждающие требование.</li>
            <li>В течение 10 (десяти) дней с момента получения оригинала сообщения,
              Сторона, получившая его, обязана направить ответ на это сообщение.</li>
            <li>В случае, если ответ на сообщение не будет получен направившей сообщение Стороной в течение
              10 (десяти) дней с даты направления соответствующего сообщения, либо если Стороны не придут
              к соглашению по возникшим претензиям и/или разногласиям спор подлежит рассмотрению
              в суде в соответствии с законодательством РФ.</li>
            <li>За неисполнение или ненадлежащее исполнение обязательств по настоящем Договоре,
              стороны несут ответственность в соответствии с законодательством Российской Федерации.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Срок действия Оферты</h2>
          <ol class="sub-list">
            <li>Настоящая Оферта вступает в силу с момента ее размещения в сети Интернет на Сервисе.</li>
            <li>Настоящая Оферта размещена на неопределенный срок и утрачивает свою силу
              при ее аннулировании Администрацией.</li>
            <li>Администрация может принять решение о прекращении Оферты в одностороннем внесудебном порядке
              с немедленным прекращением доступа и возможности использовать Сервис Пользователями в случае
              закрытия Сервиса или любого, в том числе однократного, нарушения Пользователем
              условий Оферты.</li>
            <li>Пользователь соглашается и признает, что внесение изменений в Оферту влечет за собой внесение
              этих изменений в заключенный и действующий между Пользователем и Администрацией Договор,
              и эти изменения вступают в силу одновременно с такими изменениями в Договор.</li>
          </ol>
        </li>
        <li class="mb-4">
          <h2>Дополнительные условия</h2>
          <ol class="sub-list">
            <li>Исключительные права на Сервис, включая, но не ограничиваясь на программы ЭВМ,
              базы данных, интерфейс, технические разработки, логотип, иные средства индивидуализации,
              контент, используемые на Сервисе и позволяющие реализовывать функциональные возможности
              Сервиса, принадлежат Администрации. Сервис и его компоненты, в том числе перечисленные в
              пункте выше, не могут быть скопированы (воспроизведены), переработаны, распространены,
              опубликованы, скачаны, переданы, проданы или иным способом использованы целиком или по
              частям без предварительного письменного разрешения Администрации.</li>
            <li>Сервис может включать ссылки на сайты, принадлежащие или контролируемые третьими лицами.
              Попадая на такой сайт по ссылке, пользователь должен прочитать правила использования этого
              сайта и согласиться на их соблюдение до начала использования Сервиса.
              Пользователь также соглашается с тем, что Администрация не может контролировать содержание
              такого сайта и брать на себя какую-либо ответственность за информацию, материалы,
              программное обеспечение и др., размещенные на нем. Наименования компаний,
              изображения и т.д., упомянутые на Сервисе, или содержащие ссылки на другие веб-сайты могут
              быть товарными знаками или товарными наименованиями третьих лиц. Доступ Пользователя к
              Сервису не должен рассматриваться как предоставление разрешения на использование
              или права собственности на любые знаки, указанные на Сервисе, без предварительного
              письменного согласия Исполнителя или третьего лица, являющегося
              собственником таких знаков.</li>
            <li>Администрация вправе переуступать либо каким-либо иным способом передавать свои права и
              обязанности, вытекающие из его отношений с Пользователем, третьим лицам.</li>
            <li>Сервис и предоставляемые сервисы могут временно частично или полностью быть недоступны по
              причине проведения профилактических, иных работ или по любым другим причинам технического
              характера. Техническая служба Сервиса имеет право периодически проводить необходимые
              профилактические или иные работы с предварительным уведомлением Пользователей
              или без такового.</li>
            <li>К отношениям между Пользователем и Администрацией применяются положения
              Российского законодательства.</li>
            <li>Признание судом недействительности какого-либо положения настоящей Оферты не влечет за
              собой недействительность остальных положений.</li>
          </ol>
        </li>
      </ol>
      <h3>Реквизиты Администрации</h3>
      <p class="m-0">ИП Ерасова Е.В.</p>
      <p class="m-0">ИНН: 632524064755</p>
      <p class="m-0">ОГРН: 324632700053071</p>
      <p class="m-0">Электронная почта: admin@youme.gg</p>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <p class="mb-0">Дата вступления в силу: 28 марта 2024</p>
          <p class="mb-0">Постоянная ссылка:
            <router-link to="/offer" class="text-muted text-decoration-none fs-6">
              <span>https://youme.gg/offer</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';


const BreadcrumbsComponent = defineAsyncComponent(() =>
    import("@/components/BreadcrumbsComponent.vue")
);
</script>

<style scoped>
ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.sub-list {
  counter-reset: subitem;
  padding-top: 5px;
}
.sub-list li:before {
  content: "1." counter(subitem) " ";
  counter-increment: subitem;
}

ol {
  counter-reset: section; /* Сбрасываем счетчик для основного списка */
}
ol li {
  counter-increment: section; /* Увеличиваем счетчик на 1 для каждого элемента списка */
}
ol li:before {
  content: counters(section, ".") " "; /* Выводим счетчик и разделитель */
}

.sub-list li {
  counter-increment: subsection;
  padding-bottom: 5px;
}
.sub-list li:before {
  content: counters(section, ".") "." counters(subsection, ".") " "; /* Выводим счетчик и разделитель */
}

.sub-sub-list {
  counter-reset: subsubsection; /* Сбрасываем счетчик для под-подсписка */
}
.sub-sub-list li {
  counter-increment: subsubsection; /* Увеличиваем счетчик на 1 для каждого элемента под-подсписка */
}
.sub-sub-list li:before {
  content: counters(section, ".") "." counters(subsection, ".") "." counter(subsubsection) " "; /* Выводим счетчик и разделитель */
}
li h2 {
  display: inline; /* Или 'inline-block', в зависимости от ваших нужд */
  margin-right: 10px; /* Добавляет небольшой отступ справа, если нужно */
  font-size: 20px;
}
h3 {
  font-size: 19px;
}
</style>