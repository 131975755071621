<template>
  <button v-if="visible" @click="scrollToTop" class="btn btn-outline-primary scroll-to-top">
    <font-awesome-icon icon="arrow-up" />
  </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const visible = ref(false);

const checkScroll = () => {
  visible.value = window.scrollY > 500;
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  right: 70px;
  bottom: 65px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  z-index: 9999999999999;
}
</style>
