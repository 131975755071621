<template>
  <div class="card-header">Для каналов</div>
  <div class="card-body responsive-outer" style="overflow: auto; max-height: 250px;">
    <div class="mb-2">
      <label :class="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Подписчики канала</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading"
              :style="{ cursor: !hasPlan700Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedSubscribersFilter"
              @change="updateSubscribersFilter">
        <option value="">Любое значение</option>
        <option value="max_channel_subscribers_1000" v-if="hasPlan700Active">До 1 000</option>
        <option value="min_channel_subscribers_1000__max_channel_subscribers_10000" v-if="hasPlan700Active">От 1 000 до 10 000</option>
        <option value="min_channel_subscribers_10000__max_channel_subscribers_100000" v-if="hasPlan700Active">От 10 000 до 100 000</option>
        <option value="min_channel_subscribers_100000__max_channel_subscribers_1000000" v-if="hasPlan700Active">От 100 000 до 1 000 000</option>
        <option value="min_channel_subscribers_1000000" v-if="hasPlan700Active">От 1 000 000</option>
      </select>
    </div>
    <div class="mb-2">
      <label :class="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Год канала</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading"
              :style="{ cursor: !hasPlan700Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedYearFilter"
              @change="updateYearFilter">
        <option value="">Любая год</option>
        <option value="2024" v-if="hasPlan700Active">2024</option>
        <option value="2023" v-if="hasPlan700Active">2023</option>
        <option value="2022" v-if="hasPlan700Active">2022</option>
        <option value="2021" v-if="hasPlan700Active">2021</option>
        <option value="2020" v-if="hasPlan700Active">2020</option>
        <option value="2019" v-if="hasPlan700Active">2019</option>
        <option value="2018" v-if="hasPlan700Active">2018</option>
        <option value="2017" v-if="hasPlan700Active">2017</option>
        <option value="2016" v-if="hasPlan700Active">2016</option>
        <option value="2015" v-if="hasPlan700Active">2015</option>
        <option value="2014" v-if="hasPlan700Active">2014</option>
        <option value="2013" v-if="hasPlan700Active">2013</option>
        <option value="2012" v-if="hasPlan700Active">2012</option>
        <option value="2011" v-if="hasPlan700Active">2011</option>
        <option value="2010" v-if="hasPlan700Active">2010</option>
        <option value="2009" v-if="hasPlan700Active">2009</option>
        <option value="2008" v-if="hasPlan700Active">2008</option>
        <option value="2007" v-if="hasPlan700Active">2007</option>
        <option value="2006" v-if="hasPlan700Active">2006</option>
        <option value="2005" v-if="hasPlan700Active">2005</option>
      </select>
    </div>
    <div>
      <label :class="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Страна канала</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan700Active || trendingVideosStore.isLoading"
              :style="{ cursor: !hasPlan700Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedCountryFilter"
              @change="updateCountryFilter">
        <option value="">Любая страна</option>
        <option value="AF" v-if="hasPlan700Active">Афганистан</option>
        <option value="AX" v-if="hasPlan700Active">Аландские острова</option>
        <option value="AL" v-if="hasPlan700Active">Албания</option>
        <option value="DZ" v-if="hasPlan700Active">Алжир</option>
        <option value="AS" v-if="hasPlan700Active">Американское Самоа</option>
        <option value="AD" v-if="hasPlan700Active">Андорра</option>
        <option value="AO" v-if="hasPlan700Active">Ангола</option>
        <option value="AI" v-if="hasPlan700Active">Ангилья</option>
        <option value="AQ" v-if="hasPlan700Active">Антарктида</option>
        <option value="AG" v-if="hasPlan700Active">Антигуа и Барбуда</option>
        <option value="AR" v-if="hasPlan700Active">Аргентина</option>
        <option value="AM" v-if="hasPlan700Active">Армения</option>
        <option value="AW" v-if="hasPlan700Active">Аруба</option>
        <option value="AU" v-if="hasPlan700Active">Австралия</option>
        <option value="AT" v-if="hasPlan700Active">Австрия</option>
        <option value="AZ" v-if="hasPlan700Active">Азербайджан</option>
        <option value="BS" v-if="hasPlan700Active">Багамские Острова</option>
        <option value="BH" v-if="hasPlan700Active">Бахрейн</option>
        <option value="BD" v-if="hasPlan700Active">Бангладеш</option>
        <option value="BB" v-if="hasPlan700Active">Барбадос</option>
        <option value="BY" v-if="hasPlan700Active">Беларусь</option>
        <option value="BE" v-if="hasPlan700Active">Бельгия</option>
        <option value="BZ" v-if="hasPlan700Active">Белиз</option>
        <option value="BJ" v-if="hasPlan700Active">Бенин</option>
        <option value="BM" v-if="hasPlan700Active">Бермудские острова</option>
        <option value="BT" v-if="hasPlan700Active">Бутан</option>
        <option value="BO" v-if="hasPlan700Active">Боливия, Многонациональное Государство</option>
        <option value="BQ" v-if="hasPlan700Active">Бонайре, Синт-Эстатиус и Саба</option>
        <option value="BA" v-if="hasPlan700Active">Босния и Герцеговина</option>
        <option value="BW" v-if="hasPlan700Active">Ботсвана</option>
        <option value="BV" v-if="hasPlan700Active">Остров Буве</option>
        <option value="BR" v-if="hasPlan700Active">Бразилия</option>
        <option value="IO" v-if="hasPlan700Active">Британская территория в Индийском океане</option>
        <option value="BN" v-if="hasPlan700Active">Бруней-Даруссалам</option>
        <option value="BG" v-if="hasPlan700Active">Болгария</option>
        <option value="BF" v-if="hasPlan700Active">Буркина-Фасо</option>
        <option value="BI" v-if="hasPlan700Active">Бурунди</option>
        <option value="KH" v-if="hasPlan700Active">Камбоджа</option>
        <option value="CM" v-if="hasPlan700Active">Камерун</option>
        <option value="CA" v-if="hasPlan700Active">Канада</option>
        <option value="CV" v-if="hasPlan700Active">Кабо-Верде</option>
        <option value="KY" v-if="hasPlan700Active">Каймановы острова</option>
        <option value="CF" v-if="hasPlan700Active">Центрально-Африканская Республика</option>
        <option value="TD" v-if="hasPlan700Active">Чад</option>
        <option value="CL" v-if="hasPlan700Active">Чили</option>
        <option value="CN" v-if="hasPlan700Active">Китай</option>
        <option value="CX" v-if="hasPlan700Active">Остров Рождества</option>
        <option value="CC" v-if="hasPlan700Active">Кокосовые (Килинг) острова</option>
        <option value="CO" v-if="hasPlan700Active">Колумбия</option>
        <option value="KM" v-if="hasPlan700Active">Коморские острова</option>
        <option value="CG" v-if="hasPlan700Active">Конго</option>
        <option value="CD" v-if="hasPlan700Active">Конго, Демократическая Республика</option>
        <option value="CK" v-if="hasPlan700Active">Острова Кука</option>
        <option value="CR" v-if="hasPlan700Active">Коста-Рика</option>
        <option value="CI" v-if="hasPlan700Active">Кот-д'Ивуар</option>
        <option value="HR" v-if="hasPlan700Active">Хорватия</option>
        <option value="CU" v-if="hasPlan700Active">Куба</option>
        <option value="CW" v-if="hasPlan700Active">Кюрасао</option>
        <option value="CY" v-if="hasPlan700Active">Кипр</option>
        <option value="CZ" v-if="hasPlan700Active">Чешская Республика</option>
        <option value="DK" v-if="hasPlan700Active">Дания</option>
        <option value="DJ" v-if="hasPlan700Active">Джибути</option>
        <option value="DM" v-if="hasPlan700Active">Доминика</option>
        <option value="DO" v-if="hasPlan700Active">Доминиканская Республика</option>
        <option value="EC" v-if="hasPlan700Active">Эквадор</option>
        <option value="EG" v-if="hasPlan700Active">Египет</option>
        <option value="SV" v-if="hasPlan700Active">Сальвадор</option>
        <option value="GQ" v-if="hasPlan700Active">Экваториальная Гвинея</option>
        <option value="ER" v-if="hasPlan700Active">Эритрея</option>
        <option value="EE" v-if="hasPlan700Active">Эстония</option>
        <option value="ET" v-if="hasPlan700Active">Эфиопия</option>
        <option value="FK" v-if="hasPlan700Active">Фолклендские (Мальвинские) острова</option>
        <option value="FO" v-if="hasPlan700Active">Фарерские острова</option>
        <option value="FJ" v-if="hasPlan700Active">Фиджи</option>
        <option value="FI" v-if="hasPlan700Active">Финляндия</option>
        <option value="FR" v-if="hasPlan700Active">Франция</option>
        <option value="GF" v-if="hasPlan700Active">Французская Гвиана</option>
        <option value="PF" v-if="hasPlan700Active">Французская Полинезия</option>
        <option value="TF" v-if="hasPlan700Active">Южные Французские Территории</option>
        <option value="GA" v-if="hasPlan700Active">Габон</option>
        <option value="GM" v-if="hasPlan700Active">Гамбия</option>
        <option value="GE" v-if="hasPlan700Active">Грузия</option>
        <option value="DE" v-if="hasPlan700Active">Германия</option>
        <option value="GH" v-if="hasPlan700Active">Гана</option>
        <option value="GI" v-if="hasPlan700Active">Гибралтар</option>
        <option value="GR" v-if="hasPlan700Active">Греция</option>
        <option value="GL" v-if="hasPlan700Active">Гренландия</option>
        <option value="GD" v-if="hasPlan700Active">Гренада</option>
        <option value="GP" v-if="hasPlan700Active">Гваделупа</option>
        <option value="GU" v-if="hasPlan700Active">Гуам</option>
        <option value="GT" v-if="hasPlan700Active">Гватемала</option>
        <option value="GG" v-if="hasPlan700Active">Гернси</option>
        <option value="GN" v-if="hasPlan700Active">Гвинея</option>
        <option value="GW" v-if="hasPlan700Active">Гвинея-Бисау</option>
        <option value="GY" v-if="hasPlan700Active">Гайана</option>
        <option value="HT" v-if="hasPlan700Active">Гаити</option>
        <option value="HM" v-if="hasPlan700Active">Острова Херд и Макдональд</option>
        <option value="VA" v-if="hasPlan700Active">Святейший Престол (Государство-город Ватикан)</option>
        <option value="HN" v-if="hasPlan700Active">Гондурас</option>
        <option value="HK" v-if="hasPlan700Active">Гонконг</option>
        <option value="HU" v-if="hasPlan700Active">Венгрия</option>
        <option value="IS" v-if="hasPlan700Active">Исландия</option>
        <option value="IN" v-if="hasPlan700Active">Индия</option>
        <option value="ID" v-if="hasPlan700Active">Индонезия</option>
        <option value="IR" v-if="hasPlan700Active">Иран, Исламская Республика</option>
        <option value="IQ" v-if="hasPlan700Active">Ирак</option>
        <option value="IE" v-if="hasPlan700Active">Ирландия</option>
        <option value="IM" v-if="hasPlan700Active">Остров Мэн</option>
        <option value="IL" v-if="hasPlan700Active">Израиль</option>
        <option value="IT" v-if="hasPlan700Active">Италия</option>
        <option value="JM" v-if="hasPlan700Active">Ямайка</option>
        <option value="JP" v-if="hasPlan700Active">Япония</option>
        <option value="JE" v-if="hasPlan700Active">Джерси</option>
        <option value="JO" v-if="hasPlan700Active">Иордания</option>
        <option value="KZ" v-if="hasPlan700Active">Казахстан</option>
        <option value="KE" v-if="hasPlan700Active">Кения</option>
        <option value="KI" v-if="hasPlan700Active">Кирибати</option>
        <option value="KP" v-if="hasPlan700Active">Корейская Народно-Демократическая Республика</option>
        <option value="KR" v-if="hasPlan700Active">Корея, Республика</option>
        <option value="KW" v-if="hasPlan700Active">Кувейт</option>
        <option value="KG" v-if="hasPlan700Active">Кыргызстан</option>
        <option value="LA" v-if="hasPlan700Active">Лаосская Народно-Демократическая Республика</option>
        <option value="LV" v-if="hasPlan700Active">Латвия</option>
        <option value="LB" v-if="hasPlan700Active">Ливан</option>
        <option value="LS" v-if="hasPlan700Active">Лесото</option>
        <option value="LR" v-if="hasPlan700Active">Либерия</option>
        <option value="LY" v-if="hasPlan700Active">Ливия</option>
        <option value="LI" v-if="hasPlan700Active">Лихтенштейн</option>
        <option value="LT" v-if="hasPlan700Active">Литва</option>
        <option value="LU" v-if="hasPlan700Active">Люксембург</option>
        <option value="MO" v-if="hasPlan700Active">Макао</option>
        <option value="MK" v-if="hasPlan700Active">Македония, бывшая Югославская Республика</option>
        <option value="MG" v-if="hasPlan700Active">Мадагаскар</option>
        <option value="MW" v-if="hasPlan700Active">Малави</option>
        <option value="MY" v-if="hasPlan700Active">Малайзия</option>
        <option value="MV" v-if="hasPlan700Active">Мальдивы</option>
        <option value="ML" v-if="hasPlan700Active">Мали</option>
        <option value="MT" v-if="hasPlan700Active">Мальта</option>
        <option value="MH" v-if="hasPlan700Active">Маршалловы острова</option>
        <option value="MQ" v-if="hasPlan700Active">Мартиника</option>
        <option value="MR" v-if="hasPlan700Active">Мавритания</option>
        <option value="MU" v-if="hasPlan700Active">Маврикий</option>
        <option value="YT" v-if="hasPlan700Active">Майотта</option>
        <option value="MX" v-if="hasPlan700Active">Мексика</option>
        <option value="FM" v-if="hasPlan700Active">Микронезия, Федеративные Штаты</option>
        <option value="MD" v-if="hasPlan700Active">Молдова, Республика</option>
        <option value="MC" v-if="hasPlan700Active">Монако</option>
        <option value="MN" v-if="hasPlan700Active">Монголия</option>
        <option value="ME" v-if="hasPlan700Active">Черногория</option>
        <option value="MS" v-if="hasPlan700Active">Монтсеррат</option>
        <option value="MA" v-if="hasPlan700Active">Марокко</option>
        <option value="MZ" v-if="hasPlan700Active">Мозамбик</option>
        <option value="MM" v-if="hasPlan700Active">Мьянма</option>
        <option value="NA" v-if="hasPlan700Active">Намибия</option>
        <option value="NR" v-if="hasPlan700Active">Науру</option>
        <option value="NP" v-if="hasPlan700Active">Непал</option>
        <option value="NL" v-if="hasPlan700Active">Нидерланды</option>
        <option value="NC" v-if="hasPlan700Active">Новая Каледония</option>
        <option value="NZ" v-if="hasPlan700Active">Новая Зеландия</option>
        <option value="NI" v-if="hasPlan700Active">Никарагуа</option>
        <option value="NE" v-if="hasPlan700Active">Нигер</option>
        <option value="NG" v-if="hasPlan700Active">Нигерия</option>
        <option value="NU" v-if="hasPlan700Active">Ниуэ</option>
        <option value="NF" v-if="hasPlan700Active">Остров Норфолк</option>
        <option value="MP" v-if="hasPlan700Active">Северные Марианские острова</option>
        <option value="NO" v-if="hasPlan700Active">Норвегия</option>
        <option value="OM" v-if="hasPlan700Active">Оман</option>
        <option value="PK" v-if="hasPlan700Active">Пакистан</option>
        <option value="PW" v-if="hasPlan700Active">Палау</option>
        <option value="PS" v-if="hasPlan700Active">Государство Палестина</option>
        <option value="PA" v-if="hasPlan700Active">Панама</option>
        <option value="PG" v-if="hasPlan700Active">Папуа - Новая Гвинея</option>
        <option value="PY" v-if="hasPlan700Active">Парагвай</option>
        <option value="PE" v-if="hasPlan700Active">Перу</option>
        <option value="PH" v-if="hasPlan700Active">Филиппины</option>
        <option value="PN" v-if="hasPlan700Active">Питкэрн</option>
        <option value="PL" v-if="hasPlan700Active">Польша</option>
        <option value="PT" v-if="hasPlan700Active">Португалия</option>
        <option value="PR" v-if="hasPlan700Active">Пуэрто-Рико</option>
        <option value="QA" v-if="hasPlan700Active">Катар</option>
        <option value="RE" v-if="hasPlan700Active">Реюньон</option>
        <option value="RO" v-if="hasPlan700Active">Румыния</option>
        <option value="RU" v-if="hasPlan700Active">Российская Федерация</option>
        <option value="RW" v-if="hasPlan700Active">Руанда</option>
        <option value="BL" v-if="hasPlan700Active">Сент-Бартелеми</option>
        <option value="SH" v-if="hasPlan700Active">остров Св. Елены</option>
        <option value="KN" v-if="hasPlan700Active">Сент-Китс и Невис</option>
        <option value="LC" v-if="hasPlan700Active">Санкт-Люсия</option>
        <option value="MF" v-if="hasPlan700Active">Сен-Мартен (французская часть)</option>
        <option value="PM" v-if="hasPlan700Active">Сен-Пьер и Микелон</option>
        <option value="VC" v-if="hasPlan700Active">Сент-Винсент и Гренадины</option>
        <option value="WS" v-if="hasPlan700Active">Самоа</option>
        <option value="SM" v-if="hasPlan700Active">Сан-Марино</option>
        <option value="ST" v-if="hasPlan700Active">Сан-Томе и Принсипи</option>
        <option value="SA" v-if="hasPlan700Active">Саудовская Аравия</option>
        <option value="SN" v-if="hasPlan700Active">Сенегал</option>
        <option value="RS" v-if="hasPlan700Active">Сербия</option>
        <option value="SC" v-if="hasPlan700Active">Сейшельские Острова</option>
        <option value="SL" v-if="hasPlan700Active">Сьерра-Леоне</option>
        <option value="SG" v-if="hasPlan700Active">Сингапур</option>
        <option value="SX" v-if="hasPlan700Active">Синт-Маартен (голландская часть)</option>
        <option value="SK" v-if="hasPlan700Active">Словакия</option>
        <option value="SI" v-if="hasPlan700Active">Словения</option>
        <option value="SB" v-if="hasPlan700Active">Соломоновы острова</option>
        <option value="SO" v-if="hasPlan700Active">Сомали</option>
        <option value="ZA" v-if="hasPlan700Active">Южная Африка</option>
        <option value="GS" v-if="hasPlan700Active">Южная Георгия и Южные Сандвичевы острова</option>
        <option value="SS" v-if="hasPlan700Active">Южный Судан</option>
        <option value="ES" v-if="hasPlan700Active">Испания</option>
        <option value="LK" v-if="hasPlan700Active">Шри-Ланка</option>
        <option value="SD" v-if="hasPlan700Active">Судан</option>
        <option value="SR" v-if="hasPlan700Active">Суринам</option>
        <option value="SJ" v-if="hasPlan700Active">Свалбард и Ян Мейен острова</option>
        <option value="SZ" v-if="hasPlan700Active">Свазиленд</option>
        <option value="SE" v-if="hasPlan700Active">Швеция</option>
        <option value="CH" v-if="hasPlan700Active">Швейцария</option>
        <option value="SY" v-if="hasPlan700Active">Сирийская Арабская Республика</option>
        <option value="TW" v-if="hasPlan700Active">Тайвань, провинция Китая</option>
        <option value="TJ" v-if="hasPlan700Active">Таджикистан</option>
        <option value="TZ" v-if="hasPlan700Active">Танзания, Объединенная Республика</option>
        <option value="TH" v-if="hasPlan700Active">Таиланд</option>
        <option value="TL" v-if="hasPlan700Active">Тимор-Лешти</option>
        <option value="TG" v-if="hasPlan700Active">Того</option>
        <option value="TK" v-if="hasPlan700Active">Токелау</option>
        <option value="TO" v-if="hasPlan700Active">Тонга</option>
        <option value="TT" v-if="hasPlan700Active">Тринидад и Тобаго</option>
        <option value="TN" v-if="hasPlan700Active">Тунис</option>
        <option value="TR" v-if="hasPlan700Active">Турция</option>
        <option value="TM" v-if="hasPlan700Active">Туркменистан</option>
        <option value="TC" v-if="hasPlan700Active">Острова Теркс и Кайкос</option>
        <option value="TV" v-if="hasPlan700Active">Тувалу</option>
        <option value="UG" v-if="hasPlan700Active">Уганда</option>
        <option value="UA" v-if="hasPlan700Active">Украина</option>
        <option value="AE" v-if="hasPlan700Active">Объединенные Арабские Эмираты</option>
        <option value="GB" v-if="hasPlan700Active">Великобритания</option>
        <option value="US" v-if="hasPlan700Active">Соединенные Штаты</option>
        <option value="UM" v-if="hasPlan700Active">Малые Удалённые Острова США</option>
        <option value="UY" v-if="hasPlan700Active">Уругвай</option>
        <option value="UZ" v-if="hasPlan700Active">Узбекистан</option>
        <option value="VU" v-if="hasPlan700Active">Вануату</option>
        <option value="VE" v-if="hasPlan700Active">Венесуэла, Боливарианская Республика</option>
        <option value="VN" v-if="hasPlan700Active">Вьетнам</option>
        <option value="VG" v-if="hasPlan700Active">Виргинские о-ва, Великобритания</option>
        <option value="VI" v-if="hasPlan700Active">Виргинские острова, США</option>
        <option value="WF" v-if="hasPlan700Active">Уоллис и Футуна</option>
        <option value="EH" v-if="hasPlan700Active">Западная Сахара</option>
        <option value="YE" v-if="hasPlan700Active">Йемен</option>
        <option value="ZM" v-if="hasPlan700Active">Замбия</option>
        <option value="ZW" v-if="hasPlan700Active">Зимбабве</option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useTrendingVideosStore } from '@/store/tools/v1/TrendingVideos';

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const hasPlan700Active = computed(() => authStore.hasSpecificPlanActive(700));

const trendingVideosStore = useTrendingVideosStore();

const localSelectedSubscribersFilter = ref(trendingVideosStore.subscribersFilter);
const localSelectedCountryFilter = ref(trendingVideosStore.countryFilter);
const localSelectedYearFilter = ref(trendingVideosStore.yearFilter);

const updateSubscribersFilter = () => {
  trendingVideosStore.updateSubscribersFilter(localSelectedSubscribersFilter.value);
};

const updateCountryFilter = () => {
  trendingVideosStore.updateCountryFilter(localSelectedCountryFilter.value);
};

const updateYearFilter = () => {
  trendingVideosStore.updateYearFilter(localSelectedYearFilter.value);
};
</script>

<style scoped>
.form-select {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.form-select:disabled {
  background-color: var(--card-bg-color);
  opacity: 0.5;
}

.form-select:hover {
  cursor: pointer;
}

.responsive-outer::-webkit-scrollbar {
  display: none;
}

.responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}
</style>