<template>
  <footer class="fixed-bottom">
    <div class="container">
      <div class="mobileMenu pt-3 pb-3 d-flex justify-content-center">
        <ul class="p-0 m-0">
          <li>
            <router-link to="/" class="fw-bolder text-decoration-none" :class="{ 'router-link-active': $route.path === '/' }">
              <font-awesome-icon icon="home" />
            </router-link>
          </li>
          <li>
            <router-link to="/tools" class="fw-bolder text-decoration-none" :class="{ 'router-link-active': $route.path.startsWith('/tools/') }">
              <font-awesome-icon icon="tools" />
            </router-link>
          </li>
          <li>
            <router-link to="/subscriptions" class="fw-bolder text-decoration-none" :class="{ 'router-link-active': $route.path === '/subscriptions' }">
              <font-awesome-icon icon="receipt" />
            </router-link>
          </li>
<!--          <li>-->
<!--            <router-link to="/partners" class="fw-bolder text-decoration-none" :class="{ 'router-link-active': $route.path.startsWith('/partners') }">-->
<!--              <font-awesome-icon icon="handshake-simple" />-->
<!--            </router-link>-->
<!--          </li>-->
          <li>
            <router-link to="/faq" class="fw-bolder text-decoration-none" :class="{ 'router-link-active': $route.path.startsWith('/faq/') }">
              <font-awesome-icon icon="circle-question" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>

</script>

<style scoped>

li:not(:last-child) {
  border-right: 1px #ffffff26 solid;
  padding-right: 30px;
}
li:not(:first-child) {
  padding-left: 30px;
}

li>a {
  color: #777e8c;
  font-size: 20px;
}

.router-link-active {
  color: white;
}

footer {
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: rgb(119 126 140 / 0%);
  box-shadow: 0 0 0 1px var(--theme-color-brand-header);
}

.mobileMenu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Для плавной прокрутки на мобильных устройствах */
  scrollbar-width: none; /* Для Firefox */
}

.mobileMenu ul::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}

.mobileMenu li {
  flex: 0 0 auto;
  white-space: nowrap; /* Предотвращает перенос элементов на новую строку */
}

</style>