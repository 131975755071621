<template>
  <div class="row user-select-none">
    <div class="col-12">
      <div class="card rounded-4 mb-3">
        <div class="card-header">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <h2 class="mb-2 mb-xxl-0 mb-xl-0 fs-5 flex-grow-1 d-flex align-items-center">Фильтры похожих запросов</h2>
              <div class="d-flex flex-grow-1 flex-wrap align-items-center">
                <select class="form-select me-3 rounded-4" :disabled="isLoading" v-model="selectedCompetition" style="flex: 1">
                  <option>Любая конкуренция</option>
                  <option>Очень низкая</option>
                  <option>Низкая</option>
                  <option>Средняя</option>
                  <option>Высокая</option>
                  <option>Очень высокая</option>
                </select>
                <select class="form-select rounded-4" :disabled="isLoading" v-model="selectedOverall" style="flex: 1">
                  <option>Любой общий балл</option>
                  <option>Очень высокий</option>
                  <option>Высокий</option>
                  <option>Средний</option>
                  <option>Низкий</option>
                  <option>Очень низкий</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <ul class="nav nav-tabs nav-pills nav-fill" id="keywordTabs" role="tablist">
            <li class="nav-item me-0 me-xxl-3 me-xl-3 me-lg-3 mb-2 mb-lg-0" role="presentation">
              <button class="nav-link rounded-4 active" id="related-tab" data-bs-toggle="tab" data-bs-target="#related" type="button" role="tab" aria-controls="related" aria-selected="false" :disabled="isLoading" @click="changeTab('related')">Похожие запросы</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link rounded-4" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true" :disabled="isLoading" @click="changeTab('questions')">Запросы вопросного типа</button>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="related" role="tabpanel" aria-labelledby="related-tab">
            <div class="card-body position-relative pb-0" style="bottom: -35px; top: 0;">
              <div class="table-responsive-outer" style="overflow: auto; max-height: 710px;" v-if="filteredPermutations && filteredPermutations.length">
                <div class="table-responsive-inner">
                  <table class="table mb-0" v-if="isLoading">
                    <thead class="sticky-top">
                    <tr>
                      <th class="nowrap">Ключевое слово</th>
                      <th class="nowrap text-center">Показы</th>
                      <th class="nowrap text-center">Конкуренция</th>
                      <th class="nowrap text-center">Общий балл</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="n in Array.from({ length: 25 }, (_, i) => i + 1)" :key="n">
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 350px; height: 38px;"></div>
                      </td>
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 130px; height: 38px;"></div>
                      </td>
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 170px; height: 38px;"></div>
                      </td>
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 160px; height: 38px;"></div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table mb-0" v-else>
                    <thead class="sticky-top">
                    <tr>
                      <th class="nowrap">
                        <div class="row">
                          <div class="col-12 d-flex align-items-center">
                            <div class="form-check me-2">
                              <input class="form-check-input" type="checkbox" @click="toggleAllKeywords" v-if="selectedKeywords.length > 1">
                            </div>
                            <div>Ключевое слово</div>
                          </div>
                        </div>
                      </th>
                      <th class="nowrap text-center">Показы</th>
                      <th class="nowrap text-center">Конкуренция</th>
                      <th class="nowrap text-center">Общий балл</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(keyword, index) in filteredPermutations" :key="'p-' + index" class="keywords-list">
                      <td class="nowrap text-muted">
                        <div class="row">
                          <div class="col-12 d-flex align-items-center">
                            <div class="form-check me-2">
                              <input class="form-check-input" type="checkbox" v-model="keyword.selected" @change="updateSelectedKeywords(keyword)">
                            </div>
                            <button class="me-3 ps-0 pe-0 btn" @click="copyToClipboard(keyword.keyword)" style="height: 85%;">
                              <font-awesome-icon icon="copy"/>
                            </button>
                            <div class="text-muted keyword" @click="searchKeyword(keyword.keyword)">{{ truncateString(keyword.keyword, 50) }}</div>
                          </div>
                        </div>
                      </td>
                      <td class="nowrap text-muted text-center">{{ Math.floor(keyword.estimated_monthly_search).toLocaleString() }}</td>
                      <td class="nowrap">
                        <div class="competition-badge p-1 pe-3 ps-3" :class="getCompetitionClass(keyword.competition)">
                          {{ getCompetitionText(keyword.competition) }}
                        </div>
                      </td>
                      <td class="nowrap">
                        <div class="competition-badge p-1 pe-3 ps-3" :class="getTotalScoreClass(keyword.overall)">
                          {{ getTotalScoreText(keyword.overall) }}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="sticky-bottom p-2" v-if="selectedKeywords.length > 0">
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <button class="btn btn-outline-warning w-100 rounded-4" @click="copySelectedKeywords">
                        {{ selectedKeywords.length > 1 ? 'Скопировать ключевые слова' : 'Скопировать ключевое слово' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card-body" style="padding-top: 50px; padding-bottom: 50px;">
                <div class="row justify-content-center align-items-center">
                  <div class="col-6 d-flex flex-column justify-content-center">
                    <h4 class="text-center">Похожих запросов, к сожалению, нет</h4>
                    <small class="text-muted text-center">Такое бывает, если основной запрос слишком длинный или слишком не популярный.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="questions" role="tabpanel" aria-labelledby="questions-tab">
            <div class="card-body position-relative" style="bottom: -35px; top: 0;">
              <div class="table-responsive-outer" style="overflow: auto; max-height: 710px;" v-if="filteredQuestions && filteredQuestions.length">
                <div class="table-responsive-inner">
                  <table class="table mb-0" v-if="isLoading">
                    <thead class="sticky-top">
                    <tr>
                      <th class="nowrap">Ключевое слово</th>
                      <th class="nowrap text-center">Показы</th>
                      <th class="nowrap text-center">Конкуренция</th>
                      <th class="nowrap text-center">Общий балл</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="n in Array.from({ length: 25 }, (_, i) => i + 1)" :key="n">
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 350px; height: 38px;"></div>
                      </td>

                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 130px; height: 38px;"></div>
                      </td>
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 170px; height: 38px;"></div>
                      </td>
                      <td style="padding: 15px;">
                        <div class="loading-skeleton rounded-4" style="width: 160px; height: 38px;"></div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table mb-0" v-else>
                    <thead class="sticky-top">
                    <tr>
                      <th class="nowrap">
                        <div class="row">
                          <div class="col-12 d-flex align-items-center">
                            <div class="form-check me-2">
                              <input class="form-check-input" type="checkbox" @click="toggleAllKeywords" v-if="selectedKeywords.length > 1">
                            </div>
                            <div>Ключевое слово</div>
                          </div>
                        </div>
                      </th>
                      <th class="nowrap text-center">Показы</th>
                      <th class="nowrap text-center">Конкуренция</th>
                      <th class="nowrap text-center">Общий балл</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(keyword, index) in filteredQuestions" :key="'q-' + index" class="keywords-list">
                      <td class="nowrap text-muted">
                        <div class="row">
                          <div class="col-12 d-flex align-items-center">
                            <div class="form-check me-2">
                              <input class="form-check-input" type="checkbox" v-model="keyword.selected" @change="updateSelectedKeywords(keyword)">
                            </div>
                            <button class="me-3 ps-0 pe-0 btn" @click="copyToClipboard(keyword.keyword)">
                              <font-awesome-icon icon="copy"/>
                            </button>
                            <div class="text-muted keyword" @click="searchKeyword(keyword.keyword)">{{ truncateString(keyword.keyword, 50) }}</div>
                          </div>
                        </div>
                      </td>
                      <td class="nowrap text-muted text-center">{{ Math.floor(keyword.estimated_monthly_search).toLocaleString() }}</td>
                      <td class="nowrap">
                        <div class="competition-badge p-1 pe-3 ps-3" :class="getCompetitionClass(keyword.competition)">
                          {{ getCompetitionText(keyword.competition) }}
                        </div>
                      </td>
                      <td class="nowrap">
                        <div class="competition-badge p-1 pe-3 ps-3" :class="getTotalScoreClass(keyword.overall)">
                          {{ getTotalScoreText(keyword.overall) }}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="sticky-bottom p-2" v-if="selectedKeywords.length > 0">
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <button class="btn btn-outline-warning w-100 rounded-4" @click="copySelectedKeywords">
                        {{ selectedKeywords.length > 1 ? 'Скопировать ключевые слова' : 'Скопировать ключевое слово' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card-body" style="padding-top: 50px; padding-bottom: 50px;">
                <div class="row justify-content-center align-items-center">
                  <div class="col-6 d-flex flex-column justify-content-center">
                    <h4 class="text-center">Запросов, к сожалению, нет</h4>
                    <small class="text-muted text-center">Такое бывает, если основной запрос слишком длинный или слишком не популярный.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import { useKeywordStore } from '@/store/tools/v1/KeywordsResearch';
import { useToast } from "vue-toastification";

const store = useKeywordStore();
const toast = useToast();
const keywordData = computed(() => store.keywordData);
const keywordStore = useKeywordStore();
const isLoading = computed(() => keywordStore.isLoading);
const selectedCompetition = ref('Любая конкуренция');
const selectedOverall = ref('Любой общий балл');
const selectedKeywords = ref([]);
const activeTab = ref('related'); // Добавим переменную для отслеживания активной вкладки

const toggleAllKeywords = () => {
  const currentKeywords = activeTab.value === 'related' ? filteredPermutations.value : filteredQuestions.value;
  const isSelectedAll = selectedKeywords.value.length === currentKeywords.length;
  currentKeywords.forEach(keyword => {
    keyword.selected = !isSelectedAll;
  });
  if (!isSelectedAll) {
    selectedKeywords.value = [...currentKeywords];
  } else {
    selectedKeywords.value = [];
  }
};

// Обновляем функцию watch для обработки переключения вкладок
watch(() => [selectedCompetition.value, selectedOverall.value], () => {
  keywordStore.setCompetitionFilter(selectedCompetition.value);
  keywordStore.setOverallFilter(selectedOverall.value);
}, { immediate: true });

// Добавить обработчик на изменение вкладки
const changeTab = (tabName) => {
  activeTab.value = tabName;
  selectedKeywords.value = []; // Сбрасываем выбор при смене вкладки
};

const updateSelectedKeywords = (keyword) => {
  if (keyword.selected) {
    selectedKeywords.value.push(keyword);
  } else {
    const index = selectedKeywords.value.findIndex(k => k === keyword);
    selectedKeywords.value.splice(index, 1);
  }
};

const copySelectedKeywords = async () => {
  const keywordsText = selectedKeywords.value.map(k => k.keyword).join(', ');
  try {
    await navigator.clipboard.writeText(keywordsText);
    toast.info(`Скопировано: ${selectedKeywords.value.length} ключевых слов(а)`);
  } catch (error) {
    toast.error('Ошибка при копировании в буфер');
  }
};

const filterKeywords = (keywords) => {
  if (!keywords) {
    return keywords;
  }

  return keywords.filter(keyword => {
    let meetsCompetitionCriteria = true;
    let meetsOverallCriteria = true;

    if (store.competitionFilter !== 'Любая конкуренция') {
      let competitionLevel = getCompetitionText(keyword.competition).toLowerCase();
      meetsCompetitionCriteria = store.competitionFilter.toLowerCase() === competitionLevel;
    }

    if (store.overallFilter !== 'Любой общий балл') {
      let overallLevel = getTotalScoreText(keyword.overall).toLowerCase();
      meetsOverallCriteria = store.overallFilter.toLowerCase() === overallLevel;
    }

    return meetsCompetitionCriteria && meetsOverallCriteria;
  });
};

const filteredPermutations = computed(() => filterKeywords(keywordData.value?.permutations));
const filteredQuestions = computed(() => filterKeywords(keywordData.value?.questions));

const copyToClipboard = async (keyword) => {
  try {
    await navigator.clipboard.writeText(keyword);
    toast.info('Ключевое слово скопировано');
  } catch (error) {
    toast.error('Ошибка копирования');
  }
};

const searchKeyword = async (keyword) => {
  await store.fetchKeywordData(keyword);
};

const getCompetitionText = (competition) => {
  if (competition >= 80) { return 'очень высокая'; }
  else if (competition >= 60) { return 'высокая'; }
  else if (competition >= 40) { return 'средняя'; }
  else if (competition >= 20) { return 'низкая'; }
  else { return 'очень низкая'; }
};

const getCompetitionClass = (competition) => {
  if (competition >= 80) { return 'high-competition'; }
  else if (competition >= 60) { return 'moderate-competition'; }
  else if (competition >= 40) { return 'medium-competition'; }
  else if (competition >= 20) { return 'low-competition'; }
  else { return 'very-low-competition'; }
};

const getTotalScoreText = (overall) => {
  if (overall >= 80) { return 'очень высокий'; }
  else if (overall >= 60) { return 'высокий'; }
  else if (overall >= 40) { return 'средний'; }
  else if (overall >= 20) { return 'низкий'; }
  else { return 'очень низкий'; }
};

const getTotalScoreClass = (overall) => {
  if (overall >= 80) { return 'very-low-score'; }
  else if (overall >= 60) { return 'low-score'; }
  else if (overall >= 40) { return 'medium-score'; }
  else if (overall >= 20) { return 'moderate-score'; }
  else { return 'high-score'; }
};

watch(selectedCompetition, (newValue) => {
  keywordStore.setCompetitionFilter(newValue);
});

watch(selectedOverall, (newValue) => {
  keywordStore.setOverallFilter(newValue);
});

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
</script>

<style scoped>
.keywords-list {
  border-bottom: 1px solid var(--bs-card-border-color);
  font-size: 18px;
  padding: 15px;
}

.keyword:hover {
  color: #0d6efd!important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.form-select:focus {
  border-color: #ced0d1;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-fill .nav-item .nav-link {
  color: #777e8c;
  font-weight: bolder !important;
  font-size: 1.25rem !important;
}

.nav-pills .nav-link.active {
  font-weight: bolder !important;
  font-size: 1.25rem !important;
  color: white;
}

.nav-tabs .nav-link:hover {
  background-color: var(--bs-nav-pills-link-active-bg);
  color: white;
}

.table-responsive-outer::-webkit-scrollbar {
  display: none;
}

.table-responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.nowrap {
  white-space: nowrap;
  padding: 15px;
}

.competition-badge {
  text-align: center;
}

.form-select {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.form-select:disabled {
  background-color: var(--card-bg-color);
  opacity: 0.5;
}

.form-select:hover {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled,.input-group-text {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.sticky-bottom {
  background: #110e2500;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
</style>