import { defineStore } from 'pinia';
import { trendingVideosApi } from "@/api/tools/v1/TrendingVideosApi";
import { useAuthStore } from "@/store/auth";

export const useTrendingVideosStore = defineStore('trendingVideosStore', {
    state: () => ({
        trendingVideosData: null,
        top50TrendingVideosData: null,
        error: null,
        isLoading: false,
        dateFilter: 'now-48H',
        categoryFilter: '',
        subscribersFilter: '',
        countryFilter: '',
        yearFilter: '',
        viewsFilter: '',
        keywordFilter: '',
        currentPage: 1,
        excludedChannelIds: [],
        excludedCountryIds: [],
        noMoreResults: false,
        isFirstLoad: true
    }),
    actions: {
        async fetchTrendingVideosData({ page = 1 } = {}) {
            if (this.noMoreResults || this.isLoading) return;

            this.error = null;
            this.isLoading = true;
            const authStore = useAuthStore();
            if (!authStore.accessToken) {
                this.error = 'Требуется авторизация для поиска трендовых видео';
                this.isLoading = false;
                return;
            }
            try {
                const data = await trendingVideosApi.fetchTrendingVideosData(
                    authStore.accessToken,
                    this.dateFilter,
                    this.categoryFilter,
                    this.subscribersFilter,
                    this.countryFilter,
                    this.yearFilter,
                    this.viewsFilter,
                    this.excludedChannelIds,
                    this.excludedCountryIds,
                    page,
                    this.keywordFilter
                );

                if (data.result && data.result.includes("no results")) {
                    this.noMoreResults = true;
                } else if (data.results && data.results.length === 0) {
                    this.noMoreResults = true;
                } else {
                    this.trendingVideosData = page === 1 ? data : { results: [...this.trendingVideosData.results, ...data.results] };
                    this.currentPage = data.next_page || page + 1;
                    if (page === 1) {
                        this.isFirstLoad = false;
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    this.noMoreResults = true;
                } else {
                    this.error = `Ошибка при поиске трендовых видео: ${error.message || error}`;
                }
            } finally {
                this.isLoading = false;
            }
        },

        async fetchTop50TrendingVideos() {
            this.error = null;
            this.isLoading = true;
            try {
                this.top50TrendingVideosData = await trendingVideosApi.fetchTop50TrendingVideos();
            } catch (error) {
                this.error = `Ошибка при получении ТОП-50 трендовых видео: ${error.message || error}`;
            } finally {
                this.isLoading = false;
            }
        },

        updateDateFilter(newFilter) {
            this.dateFilter = newFilter;
        },
        updateCategoryFilter(newFilter) {
            this.categoryFilter = newFilter;
        },
        updateSubscribersFilter(newFilter) {
            this.subscribersFilter = newFilter;
        },
        updateCountryFilter(newFilter) {
            this.countryFilter = newFilter;
        },
        updateYearFilter(newFilter) {
            this.yearFilter = newFilter;
        },
        updateViewsFilter(newFilter) {
            this.viewsFilter = newFilter;
        },
        updateKeywordFilter(newFilter) {
            this.keywordFilter = newFilter;
        },
        setExcludedChannelIds(ids) {
            this.excludedChannelIds = ids;
        },
        setExcludedCountryIds(ids) {
            this.excludedCountryIds = ids;
        },
    }
});
