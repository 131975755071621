export function formatNumber(number) {
    return new Intl.NumberFormat('ru-RU').format(number);
}

export function formatDate(date) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('ru-RU', options);
}

export function formatLargeNumber(value) {
    if (value < 1e6) return value.toLocaleString();
    if (value < 1e9) return (value / 1e6).toFixed(1) + ' млн';
    return (value / 1e9).toFixed(1) + ' млрд';
}
