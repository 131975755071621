<template>
  <div class="modal fade" ref="hiddenCountryModalRef" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="hiddenCountryModalLabel" aria-hidden="true">
    <div class="modal-dialog user-select-none" style="max-width: 400px;">
      <div class="modal-content rounded-4">
        <div class="modal-header border-bottom pe-4 ps-4">
          Скрытые страны
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="hiddenCountries.length === 0">Нет скрытых стран</div>
          <div class="responsive-outer" v-else style="overflow: auto; max-height: 400px;">
            <div v-for="country in hiddenCountries" :key="country" class="d-flex align-items-center channelList">
              <div class="flex-grow-1 text-truncate">
                {{ country }}
              </div>
              <button @click="removeCountry(country)" class="btn btn-danger btn-sm rounded-4">Удалить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap';

export default {
  name: "HiddenCountryModal",
  setup() {
    const hiddenCountryModalRef = ref(null);
    let modalInstance = null;

    const hiddenCountries = ref(JSON.parse(localStorage.getItem('excludedCountries')) || []);

    const updateHiddenCountries = () => {
      hiddenCountries.value = JSON.parse(localStorage.getItem('excludedCountries')) || [];
    };

    onMounted(() => {
      if (hiddenCountryModalRef.value) {
        modalInstance = new Modal(hiddenCountryModalRef.value);
      }

      window.addEventListener('storage', updateHiddenCountries);

      return () => {
        window.removeEventListener('storage', updateHiddenCountries);
      };
    });

    const showModal = () => {
      modalInstance?.show();
    };
    const closeModal = () => modalInstance?.hide();

    const removeCountry = (country) => {
      const updatedCountries = hiddenCountries.value.filter(c => c !== country);
      hiddenCountries.value = updatedCountries;
      localStorage.setItem('excludedCountries', JSON.stringify(updatedCountries));
      window.dispatchEvent(new Event('storage'));
    };

    return { showModal, closeModal, hiddenCountryModalRef, hiddenCountries, removeCountry };
  }
}
</script>

<style scoped>
.responsive-outer::-webkit-scrollbar {
  display: none;
}

.responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
}

.channelList:not(:last-child) {
  border-bottom: 1px var(--theme-color-border) solid;
  padding-bottom: 10px;
  padding-top: 10px;
}

.channelList:last-child {
  padding-top: 10px;
}
</style>
