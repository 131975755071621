import axios from 'axios';
import { API_URL } from '@/config';
export const CompetitorAnalysisApi = {

    async fetchMyProjects(token) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-projects`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchProjectById(token, projectId) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/${projectId}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchAverageStatisticsByLongVideos(token, projectId) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/${projectId}/average-statistics/long`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchAverageStatisticsByShortsVideos(token, projectId) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/${projectId}/average-statistics/shorts`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchCompetitors(token, projectId) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/competitors/${projectId}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchPotentialCompetitors(token, projectId) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/potential-competitors/${projectId}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async confirmPotentialCompetitor(token, { topic_id, potential_channel_id, is_confirmed }) {
        const url = `${API_URL}/tools/beta/competitor-analysis/my-project/potential-competitors/confirm`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            topic_id,
            potential_channel_id,
            is_confirmed
        };
        const response = await axios.post(url, data, config);
        return response.data;
    },

    async createProject(token, { title }) {
        const url = `${API_URL}/tools/beta/competitor-analysis/create`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            title
        };
        const response = await axios.post(url, data, config);
        return response.data;
    },

    async addChannelsToProject(token, { projectId, channels}) {
        const url = `${API_URL}/tools/beta/competitor-analysis/add-channels`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            projectId,
            channels
        };
        const response = await axios.post(url, data, config);
        return response.data;
    }
};