<template>
  <div class="notificationMenu" style="position: relative; display: inline-block; margin-right: 30px; padding-top: 9px;" @click="toggleNotifications">
    <div v-if="notificationCount > 0" class="notificationCount" style="cursor: pointer">{{ notificationCount }}</div>
    <font-awesome-icon icon="bell" class="fs-4" style="color: #444863; cursor: pointer" />
    <div v-if="isNotificationsVisible" class="dropdownMenu rounded-4" style="position: absolute; top: 53px; width: 350px; right: -60px;">
      <div class="card rounded-4">
        <div class="card-header">Уведомления</div>
        <div class="card-body customScroll pt-0 pb-0">
          <div v-if="notifications.length > 0" class="row">
            <div v-for="notification in notifications" :key="notification.id" class="col-12 notification">
              <div class="row">
                <div class="col-2 d-flex align-items-center justify-content-center">
                  <font-awesome-icon icon="robot" class="fs-2" style="color: #444863" />
                </div>
                <div class="col-10 d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <span>{{ notification.data.message }}</span>
                  </div>
                  <div class="text-muted" style="font-size: 1rem;">
                    {{ formatNotificationDate(notification.created_at) }}
                  </div>
                </div>
              </div>
              <div class="read-button rounded-start-3 fw-bolder" @click.stop="handleNotificationClick(notification)">Прочитано</div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12 d-flex flex-column justify-content-center p-5">
              <font-awesome-icon icon="bell-slash" class="fs-3 mb-2" style="color: #444863" />
              <h5 class="mb-0 text-center">Уведомлений нет</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useAuthStore } from '@/store/auth';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import { useToast } from 'vue-toastification';

dayjs.extend(relativeTime);
dayjs.locale('ru');

const store = useAuthStore();
const notifications = computed(() => store.notifications);
const notificationCount = computed(() => store.notificationCount);

const isNotificationsVisible = ref(false);

const toast = useToast();

const toggleNotifications = () => {
  isNotificationsVisible.value = !isNotificationsVisible.value;
};

const closeMenus = (event) => {
  if (!event.target.closest('.notificationMenu')) {
    isNotificationsVisible.value = false;
  }
};

const handleNotificationClick = notification => {
  event.stopPropagation();
  store.notificationRead(notification.id);
  toast.info('Уведомление прочитано и скрыто');
};

const formatNotificationDate = (date) => {
  return dayjs(date).fromNow();
};

const notificationSound = new Audio('/notification_sound.mp3');

watch(notificationCount, (newCount, oldCount) => {
  if (newCount > oldCount && notifications.value.length > 0) {
    notificationSound.play().catch(error => console.error("Failed to play sound:", error));
    const newNotification = notifications.value[0];
    toast.success(newNotification.data.message, {
      position: "bottom-right",
      timeout: false
    });
  }
}, { immediate: false });

onMounted(() => {
  document.addEventListener('click', closeMenus);
});

onUnmounted(() => {
  document.removeEventListener('click', closeMenus);
});
</script>



<style scoped>
.notificationCount {
  position: absolute;
  top: 0;
  right: -10px;
  color: rgb(255 255 255);
  font-size: 11px;
  background-color: #0d6efd;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.dropdownMenu {
  background-color: var(--card-bg-color);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1000;
}

.notificationMenu .dropdownMenu {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 1000;
}

.customScroll {
  scrollbar-width: none;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.customScroll::-webkit-scrollbar {
  display: none;
}

.notification {
  padding-bottom: 10px;
  padding-top: 10px;
}

.notification:not(:last-child) {
  border-bottom: 1px solid var(--bs-card-border-color);
}

.notification {
  position: relative;
  overflow: hidden; /* Обеспечивает, что дочерние элементы не выйдут за границы */
  transition: filter 0.3s; /* Плавная анимация размытия */
}

.read-button {
  cursor: pointer;
  position: absolute;
  right: -120px;
  top: 0;
  bottom: 0;
  width: 110px;
  background-color: #0d6efd;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 0.3s;
}

.notification:hover .read-button {
  right: 0;
}
</style>