import axios from 'axios';
import { API_URL } from '@/config';

export const keywordsResearchApi = {

    async fetchKeywordData(keyword, token) {
        const url = `${API_URL}/tools/v1/keyword-research`;
        const params = {
            keyword: keyword
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: params
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async fetchTop100Keywords(period, language) {
        const url = `${API_URL}/public/tools/v1/keyword-research/top-100`;
        const params = {
            period: period,
            language: language,
        };
        const config = {
            params: params
        };
        const response = await axios.get(url, config);
        return response.data;
    }
};