<template>
  <div class="modal fade" ref="modalElement" id="subscriptionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="subscriptionModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg user-select-none">
      <div class="modal-content rounded-4">
        <div class="modal-header border-bottom pe-4 ps-4">
          <h5 class="modal-title" id="subscriptionModalLabel">Подписка «{{ selectedSubscription.name }} {{ selectedSubscription.version }}»</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body pt-3 pb-3">
          <div class="card rounded-4 mb-3">
            <div class="card-header">Способы оплаты</div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3 mb-xxl-0 mb-xl-0" @click="selectPaymentMethod('Usd')" :class="{disabled: loading || qrCodeUrl || isRedirecting}">
                  <div class="subscriptionDays" :class="{active: paymentMethod === 'Usd'}">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">USDT TRC-20</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3 mb-xxl-0 mb-xl-0 mb-md-0 mb-sm-0" @click="selectPaymentMethod('QR')" :class="{disabled: loading || qrCodeUrl || isRedirecting}">
                  <div class="subscriptionDays" :class="{active: paymentMethod === 'QR'}">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">СБП</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3 mb-xxl-0 mb-xl-0 disabled" @click="selectPaymentMethod('Card')" :class="{disabled: loading || qrCodeUrl || isRedirecting}">
                  <div class="subscriptionDays" :class="{active: paymentMethod === 'Card'}">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">Картой РФ</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 disabled" @click="selectPaymentMethod('QR')" :class="{disabled: loading || qrCodeUrl || isRedirecting}">
                  <div class="subscriptionDays" :class="{active: paymentMethod === 'Usd_card'}">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">Заруб. картой</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header border-top">
              <span :class="{disabled: loading || qrCodeUrl || isRedirecting || !paymentMethod}">Период действия подписки</span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3 subscriptionDaysCard" v-for="option in selectedSubscription.options" :key="option.id" @click="selectOption(option)" :class="{disabled: loading || qrCodeUrl || isRedirecting || !paymentMethod}">
                  <div class="subscriptionDays" :class="{active: selectedOption && selectedOption.id === option.id}">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">{{ formatLength(option.length) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer" v-if="!paymentMethod || !selectedOption || loading || isRedirecting || loading && !isRedirecting || qrCodeUrl">
              <div class="row" v-if="paymentMethod === 'QR' && selectedOption && !qrCodeUrl && !loading && isAuthenticated">
                <div class="col-12 d-flex justify-content-center">Сгенерируйте QR-код для оплаты подписки</div>
              </div>
              <div class="row" v-else-if="paymentMethod && !selectedOption">
                <div class="col-12 d-flex justify-content-center">Шаг второй: выберите период действия подписки</div>
              </div>
              <div class="row" v-else-if="!paymentMethod">
                <div class="col-12 d-flex justify-content-center">Шаг первый: выберите способ оплаты</div>
              </div>
              <div class="row">
                <div v-if="loading || isRedirecting" class="col-12 d-flex justify-content-center mb-2">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-if="loading && !isRedirecting" class="col-12 d-flex justify-content-center">
                  <small>генерация qr-кода...</small>
                </div>
                <div v-if="isRedirecting" class="col-12 d-flex justify-content-center">
                  <small>переход на форму оплаты...</small>
                </div>
                <div v-if="qrCodeUrl" class="col-7 d-flex flex-column align-content-center justify-content-center">
                  <div class="text-center" style="font-size: 20px;">Отсканируйте этот QR-КОД</div>
                  <div class="text-center">в мобильном приложении вашего банка и подтвердите платёж</div>
                </div>
                <div v-if="qrCodeUrl" class="col-5">
                  <img class="w-100" style="border-radius: 20px;" :src="qrCodeUrl" alt="QR Code"/>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex align-items-center" :class="paymentMethod && selectedOption ? 'justify-content-between' : 'justify-content-end'" v-if="paymentMethod && selectedOption && !loading && !isRedirecting">
              <div class="fs-4 fw-bold" v-if="paymentMethod && selectedOption">
                Итого к оплате: {{ formatNumber(totalPrice) }} {{ currencySymbol }}
              </div>
              <button v-if="!qrCodeUrl && paymentMethod === 'QR' && selectedOption && isAuthenticated" type="button" class="btn btn-outline-primary" @click="generateQrCode" :disabled="loading">
                Сгенерировать QR-КОД
              </button>
              <button v-if="!qrCodeUrl && paymentMethod === 'Card' && selectedOption && isAuthenticated" type="button" class="btn btn-outline-primary" @click="processCardPayment" :disabled="loading || isRedirecting">
                Перейти к оплате
              </button>
              <button v-if="!qrCodeUrl && paymentMethod === 'Usd' && selectedOption && isAuthenticated" type="button" class="btn btn-outline-primary" @click="processUsdPayment" :disabled="loading || isRedirecting">
                Перейти к оплате
              </button>
            </div>
          </div>
          <div class="alert alert-primary rounded-4 mb-0">
            Оплата картами РФ банков и картами зарубежных банков будет доступна
            чуть позже.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted, watch, computed} from 'vue';
import { Modal } from 'bootstrap';
import { useSubscriptionsStore } from "@/store/subscriptions/subscriptions";
import { useAuthStore } from '@/store/auth';
import { formatNumber } from "@/utils/utils";
import { useToast } from 'vue-toastification';

export default {
  methods: {
    formatNumber
  },
  props: {
    isVisible: Boolean,
    selectedSubscription: Object
  },
  setup(props, { emit }) {
    const subscriptionsStore = useSubscriptionsStore();
    const selectedOption = ref(null);
    const paymentMethod = ref(null);
    const totalPrice = ref('');
    const qrCodeUrl = ref('');
    const loading = ref(false);
    const isRedirecting = ref(false);
    const currencySymbol = ref('');

    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);

    const toast = useToast();

    const modalElement = ref(null);
    let modalInstance = null;

    async function generateQrCode() {
      if (!selectedOption.value) {
        toast.error('Необходимо выбрать опцию подписки');
        return;
      }
      loading.value = true;
      try {
        const result = await subscriptionsStore.initiatePayment(selectedOption.value.id);
        qrCodeUrl.value = result.url;
      } catch (error) {
        toast.error('Ошибка при генерации QR-кода');
      } finally {
        loading.value = false;
      }
    }

    async function processCardPayment() {
      if (!selectedOption.value) {
        toast.error('Необходимо выбрать опцию подписки');
        return;
      }
      loading.value = true;
      isRedirecting.value = true;
      try {
        const result = await subscriptionsStore.initiateCardPayment(selectedOption.value.id);
        window.open(result.url, '_blank'); // Открывает в новой вкладке
        modalInstance.hide(); // Закрывает модальное окно
      } catch (error) {
        toast.error('Ошибка при оплате картой');
        isRedirecting.value = false;
      } finally {
        loading.value = false;
      }
    }

    async function processUsdPayment() {
      if (!selectedOption.value) {
        toast.error('Необходимо выбрать опцию подписки');
        return;
      }
      loading.value = true;
      isRedirecting.value = true;
      try {
        const result = await subscriptionsStore.initiateUsdPayment(selectedOption.value.id);
        window.open(result.url, '_blank'); // Открывает в новой вкладке
        modalInstance.hide(); // Закрывает модальное окно
      } catch (error) {
        toast.error('Ошибка при оплате USDT');
        isRedirecting.value = false;
      } finally {
        loading.value = false;
      }
    }

    function formatLength(length) {
      const matches = length.match(/^(\d+)([dmy])/);
      if (!matches) return length;
      const num = matches[1];
      const unit = matches[2];
      switch (unit) {
        case 'd':
          return `${num} ${num === '1' ? 'день' : 'дней'}`;
        case 'm':
          return `${num} ${num % 10 === 1 && num % 100 !== 11 ? 'месяц' : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? 'месяца' : 'месяцев'}`;
        case 'y':
          return `${num} ${num === '1' ? 'год' : 'лет'}`;
        default:
          return length;
      }
    }

    function updatePrice() {
      if (!selectedOption.value) return;
      let priceInfo;
      if (paymentMethod.value === 'Card' || paymentMethod.value === 'Usd') {
        priceInfo = selectedOption.value.prices.find(price => price.currency === 'USD');
      } else if (paymentMethod.value === 'QR') {
        priceInfo = selectedOption.value.prices.find(price => price.currency === 'RUB');
      }

      totalPrice.value = priceInfo ? priceInfo.price : 0;
      currencySymbol.value = priceInfo && priceInfo.currency === 'USD' ? '$' : '₽';
    }

    function selectOption(option) {
      if (!paymentMethod.value) return;
      selectedOption.value = option;
      updatePrice();
    }

    function selectPaymentMethod(method) {
      paymentMethod.value = method;
      updatePrice();
    }

    onMounted(() => {
      modalElement.value = document.getElementById('subscriptionModal');
      if (props.isVisible) {
        modalInstance = new Modal(modalElement.value);
        modalInstance.show();
      }
    });

    watch(() => props.isVisible, (newVal) => {
      if (newVal) {
        if (!modalInstance) {
          modalInstance = new Modal(modalElement.value);
        }
        modalInstance.show();
      } else {
        modalInstance?.hide();
      }
    });

    function closeModal() {
      resetModalData();
      emit('close-modal');
    }

    function resetModalData() {
      selectedOption.value = null;
      paymentMethod.value = null;
      totalPrice.value = '';
      qrCodeUrl.value = '';
    }

    return { currencySymbol, generateQrCode, formatLength, selectOption, selectPaymentMethod, closeModal, qrCodeUrl, totalPrice, paymentMethod, selectedOption, processCardPayment, processUsdPayment, loading, isAuthenticated, isRedirecting };
  }
}
</script>

<style scoped>
.modal-body {
  border-bottom: 0;
  padding: 35px;
}

.modal-content {
  border: 0!important;
  background: var(--card-bg-color);
}

.fade {
  transition: opacity .30s linear;
}

.modal.fade .modal-dialog {
  transition: transform .3s linear;
}

.modal-header {
  color: #777e8c;
}

.btn-close {
  --bs-btn-close-color: #777e8c;
}

.subscriptionDays {
  border: 1px #777e8c solid;
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: bold;
}

.subscriptionDays.active {
  border-color: #4a90e2;
  background-color: #110e2545;
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.border-top {
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color) !important;
}
</style>
