<template>
  <div class="card-body">
    <div class="row">
      <template v-if="isLoading">
        <div class="col-12 col-xxl-4 col-md-6 subscriptionCard" v-for="n in 3" :key="n">
          <div class="card h-100 rounded-4">
            <div class="card-body d-flex justify-content-center align-items-center">
              <div class="loading-skeleton rounded-4" style="width: 200px; height: 28px;"></div>
            </div>
            <div class="card-footer d-flex justify-content-center">
              <div class="loading-skeleton rounded-4" style="width: 256px; height: 35px;"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-12 col-xxl-4 col-md-6 subscriptionCard" v-for="(subscription) in subscriptionsStore.subscriptionsList" :key="subscription.plan_id" @click="openModal(subscription)">
          <div class="card h-100 rounded-4 text-decoration-none">
            <div class="card-body">
              <div class="title-wrapper d-flex align-items-center justify-content-between">
                <div class="mb-0 fw-bolder fs-5">«{{ subscription.name }}»</div>
                <small class="toolVersion d-flex align-items-center">{{ subscription.version }}</small>
              </div>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-outline-primary w-100 rounded-4">Купить подписку</button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <subscription-modal
      :is-visible="isModalVisible"
      :selected-subscription="selectedSubscription"
      @close-modal="isModalVisible = false"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useSubscriptionsStore } from '@/store/subscriptions/subscriptions';
import SubscriptionModal from "@/components/modal/SubscriptionModal.vue";

const subscriptionsStore = useSubscriptionsStore();
const selectedSubscription = ref({});
const isModalVisible = ref(false);
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  await subscriptionsStore.fetchSubscriptions();
  isLoading.value = false;
});

function openModal(subscription) {
  selectedSubscription.value = subscription;
  isModalVisible.value = true;
}
</script>

<style scoped>
.toolVersion {
  font-size: 15px;
  background: #0D6EFDFF;
  color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.subscriptionCard {
  cursor: pointer;
}

@media (max-width: 767px) {
  .subscriptionCard:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
