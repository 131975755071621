import { defineStore } from 'pinia';
import { faqApi } from '@/api/faq';

export const useFaqStore = defineStore('faq', {
    state: () => ({
        faqAllInstructions: [],
        faqInstruction: []
    }),
    actions: {
        async getAllInstructions() {
            try {
                const allInstructions = await faqApi.getAllInstructions();
                this.faqAllInstructions = allInstructions;
            } catch (error) {
                console.error('Ошибка при получении инструкций', error);
            }
        },

        async fetchInstructionsBySlug(slug) {
            try {
                const instruction = await faqApi.fetchInstructionsBySlug(slug);
                this.faqInstruction = instruction;
            } catch (error) {
                console.error('Ошибка при получении инструкции', error);
            }
        }
    }
});
