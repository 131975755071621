<template>
  <div class="col-12">
    <div class="card rounded-4 user-select-none">
      <div class="card-header">
        <div class="row">
          <div class="col-12 d-flex flex-wrap justify-content-between">
            <h2 class="mb-2 mb-xxl-0 mb-xl-0 fs-5 flex-grow-1 d-flex align-items-center">ТОП-100 ключевых слов</h2>
            <div class="d-flex flex-grow-1 flex-wrap align-items-center">
              <select class="form-select me-3 rounded-4" style="flex: 1" v-model="selectedPeriod" @change="onChangePeriod" :disabled="isLoadingTop100">
                <option value="month">За этот месяц</option>
                <option value="week">За эту неделю</option>
                <option value="day">За сегодня</option>
              </select>
              <select class="form-select rounded-4" style="flex: 1" v-model="selectedLanguage" @change="onChangeLanguage" :disabled="isLoadingTop100">
                <option value="all">На всех языках</option>
                <option value="en">На Английском</option>
                <option value="ru">На Русском</option>
                <option value="es">На Испанском</option>
                <option value="pt">На Португальском</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body position-relative" style="bottom: -35px; top: 0;">
        <div class="table-responsive-outer" style="overflow: auto; max-height: 710px;">
          <div class="table-responsive-inner">
            <table class="table" v-if="isLoadingTop100">
              <thead class="sticky-top">
              <tr>
                <th class="nowrap">Ключевое слово</th>
                <th class="nowrap text-center">Показы в месяц</th>
                <th class="nowrap text-center">Конкуренция</th>
                <th class="nowrap text-center">Общий балл</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="n in Array.from({ length: 25 }, (_, i) => i + 1)" :key="n">
                <td style="padding: 15px;">
                  <div class="loading-skeleton rounded-4" style="width: 350px; height: 38px;"></div>
                </td>

                <td style="padding: 15px;">
                  <div class="loading-skeleton rounded-4" style="width: 130px; height: 38px;"></div>
                </td>
                <td style="padding: 15px;">
                  <div class="loading-skeleton rounded-4" style="width: 170px; height: 38px;"></div>
                </td>
                <td style="padding: 15px;">
                  <div class="loading-skeleton rounded-4" style="width: 160px; height: 38px;"></div>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table" v-else>
              <thead class="sticky-top">
              <tr>
                <th class="nowrap">Ключевое слово</th>
                <th class="nowrap text-center">Показы в месяц</th>
                <th class="nowrap text-center">Конкуренция</th>
                <th class="nowrap text-center">Общий балл</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(keyword, index) in topKeywords" :key="index" class="keywords-list">
                <td class="nowrap text-muted">
                  <div class="row">
                    <div class="col-12 d-flex align-items-center">
                      <button class="me-3 ps-0 pe-0 btn" @click="copyToClipboard(keyword.keyword)">
                        <font-awesome-icon icon="copy"/>
                      </button>
                      <div class="text-muted mb-1" v-if="!isAuthenticated">{{ truncateString(keyword.keyword, 50) }}</div>
                      <div class="text-muted mb-1" v-else-if="isAuthenticated && !hasPlan500Active">{{ truncateString(keyword.keyword, 50) }}</div>
                      <div class="text-muted keyword mb-1" @click="searchKeyword(keyword.keyword)" v-else>{{ truncateString(keyword.keyword, 50) }}</div>
                    </div>
                  </div>
                </td>
                <td class="nowrap text-muted text-center">{{ Math.floor(keyword.estimated_monthly_search).toLocaleString() }}</td>
                <td class="nowrap">
                  <div class="competition-badge p-1 pe-3 ps-3" :class="getCompetitionClass(keyword.competition)">
                    {{ getCompetitionText(keyword.competition) }}
                  </div>
                </td>
                <td class="nowrap">
                  <div class="competition-badge p-1 pe-3 ps-3" :class="getTotalScoreClass(keyword.overall)">
                    {{ getTotalScoreText(keyword.overall) }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useKeywordStore } from '@/store/tools/v1/KeywordsResearch';
import { useAuthStore } from "@/store/auth";
import { useToast } from "vue-toastification";

const store = useKeywordStore();
const selectedPeriod = ref('month');
const selectedLanguage = ref('all');
const toast = useToast();

const keywordStore = useKeywordStore();
const isLoadingTop100 = computed(() => keywordStore.isLoadingTop100);

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const hasPlan500Active = computed(() => authStore.hasSpecificPlanActive(500));


const topKeywords = computed(() => store.top100KeywordsData?.keywords || []);

onMounted(() => {
  store.fetchTop100Keywords(selectedPeriod.value, selectedLanguage.value);
});

const onChangePeriod = () => {
  store.fetchTop100Keywords(selectedPeriod.value, selectedLanguage.value);
};

const onChangeLanguage = () => {
  store.fetchTop100Keywords(selectedPeriod.value, selectedLanguage.value);
};

const copyToClipboard = async (keyword) => {
  try {
    await navigator.clipboard.writeText(keyword);
    toast.info('Ключевое слово скопировано');
  } catch (error) {
    toast.error('Ошибка копирования');
  }
};

const getCompetitionText = (competition) => {
  if (competition >= 80) { return 'очень высокая'; }
  else if (competition >= 60) { return 'высокая'; }
  else if (competition >= 40) { return 'средняя'; }
  else if (competition >= 20) { return 'низкая'; }
  else { return 'очень низкая'; }
};

const getCompetitionClass = (competition) => {
  if (competition >= 80) { return 'high-competition'; }
  else if (competition >= 60) { return 'moderate-competition'; }
  else if (competition >= 40) { return 'medium-competition'; }
  else if (competition >= 20) { return 'low-competition'; }
  else { return 'very-low-competition'; }
};

const getTotalScoreText = (competition) => {
  if (competition >= 80) { return 'очень высокий'; }
  else if (competition >= 60) { return 'высокий'; }
  else if (competition >= 40) { return 'средний'; }
  else if (competition >= 20) { return 'низкий'; }
  else { return 'очень низкий'; }
};

const getTotalScoreClass = (competition) => {
  if (competition >= 80) { return 'very-low-score'; }
  else if (competition >= 60) { return 'low-score'; }
  else if (competition >= 40) { return 'medium-score'; }
  else if (competition >= 20) { return 'moderate-score'; }
  else { return 'high-score'; }
};

const searchKeyword = async (keyword) => {
  await store.fetchKeywordData(keyword);
};

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
</script>

<style scoped>
.keywords-list {
  border-bottom: 1px solid var(--bs-card-border-color);
  font-size: 18px;
}

.competition-badge {
  text-align: center;
}

.form-select {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.form-select:disabled {
  background-color: var(--card-bg-color);
  opacity: 0.5;
}

.form-select:hover {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled,.input-group-text {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.keyword:hover {
  color: #0d6efd!important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.nowrap {
  white-space: nowrap;
  padding: 15px;
}

.btn:first-child:active {
  border-color: #ffffff00;
}

.table-responsive-outer::-webkit-scrollbar {
  display: none;
}

.table-responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}
</style>