<template>
  <header-component />
  <div class="container" style="padding-top: 100px; padding-bottom: 65px;">
    <div class="row">
      <div v-if="!isNarrowMenuScreen" :class="isToolsRoute ? 'col-1 col-xl-2 col-lg-1' : 'col-xxl-3 col-xl-4 col-lg-4'">
        <div class="sticky-top mb-3" style="top: 110px;">
          <left-menu />
        </div>
      </div>
      <div :class="isToolsRoute ? 'col-12 col-xl-10 col-lg-11' : 'col-12 col-xxl-9 col-xl-8 col-lg-8'">
        <router-view />
      </div>
    </div>
    <CookieConsent />
    <ScrollToTopButton />
  </div>
  <mobile-menu v-if="isNarrowMenuScreen" />
</template>


<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderComponent from '@/components/HeaderComponent.vue';
import LeftMenu from '@/components/MenuComponent.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import ScrollToTopButton from '@/components/ScrollToTopButton.vue';
import MobileMenu from "@/components/menu/MobileMenu.vue";

const isNarrowMenuScreen = ref(window.innerWidth < 992);
const route = useRoute();

const updateScreenSize = () => {
  isNarrowMenuScreen.value = window.innerWidth < 992;
};

const isToolsRoute = computed(() => route.path.startsWith('/tools/'));

onMounted(() => {
  window.addEventListener('resize', updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenSize);
});
</script>

