<template>
  <div class="card rounded-4 user-select-none">
    <div class="card-body">
      <nav aria-label="breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb m-0">
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="breadcrumb-item">
            <router-link to="/" itemprop="item" class="text-decoration-none text-muted">
              <span itemprop="name">Главная</span>
              <meta itemprop="position" content="1" />
            </router-link>
          </li>
          <li v-for="(crumb, index) in breadcrumbs" :key="crumb.path" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="breadcrumb-item" :class="{ 'active fw-bolder': crumb.isActive }">
            <router-link v-if="!crumb.isActive" :to="crumb.path" itemprop="item" class="text-decoration-none text-muted">
              <span itemprop="name">{{ crumb.title }}</span>
            </router-link>
            <span v-else>{{ crumb.title }}</span>
            <meta itemprop="position" :content="index + 2" />
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const props = defineProps({
  instructionTitle: String
});

const route = useRoute();
const router = useRouter();


const breadcrumbs = computed(() => {
  const pathArray = route.path.split('/').filter(p => p);
  let path = '';
  return pathArray.map((segment, index) => {
    path += '/' + segment;
    const routeMatch = router.getRoutes().find(r => r.path === path || r.alias === path);

    const isLast = index === pathArray.length - 1;
    return {
      path,
      name: routeMatch?.name,
      title: isLast && props.instructionTitle ? props.instructionTitle : (routeMatch?.meta.title || ''),
      isActive: isLast
    };
  });
});

</script>
