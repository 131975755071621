<template>
  <ul class="mt-1 p-0 menu user-select-none">
    <li>
      <router-link to="/" :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'fw-bolder text-decoration-none fs-5',
      $route.path === '/' ? 'router-link-active' : '',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <font-awesome-icon icon="home" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
        <span v-if="!(isToolsRoute && screenWidth < 1200)">Главная</span>
      </router-link>
    </li>
    <li>
      <router-link to="/tools" :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'fw-bolder text-decoration-none fs-5',
      $route.path.startsWith('/tools/') ? 'router-link-active' : '',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <font-awesome-icon
            icon="tools"
            :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"
        />
        <span v-if="!(isToolsRoute && screenWidth < 1200)">Инструменты</span>
      </router-link>
    </li>

    <li>
      <router-link to="/subscriptions" :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'fw-bolder text-decoration-none fs-5',
      $route.path.startsWith('/subscriptions/') ? 'router-link-active' : '',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <font-awesome-icon icon="receipt" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
        <span v-if="!(isToolsRoute && screenWidth < 1200)">Подписки</span>
      </router-link>
    </li>
    <li>
      <router-link to="/faq" :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'fw-bolder text-decoration-none fs-5',
      $route.path.startsWith('/faq/') ? 'router-link-active' : '',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <font-awesome-icon icon="circle-question" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
        <span v-if="!(isToolsRoute && screenWidth < 1200)">Инструкции</span>
      </router-link>
    </li>
    <li>
      <a :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'text-decoration-none fs-5 disabled d-flex justify-content-between user-select-none',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <span>
          <font-awesome-icon icon="handshake-simple" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
          <span v-if="!(isToolsRoute && screenWidth < 1200)">Партнерка</span>
        </span>
        <small class="soon" v-if="!isToolsRoute">Скоро</small>
      </a>
    </li>
    <li>
      <a :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'text-decoration-none fs-5 disabled d-flex justify-content-between user-select-none',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <span>
          <font-awesome-icon icon="newspaper" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
          <span v-if="!(isToolsRoute && screenWidth < 1200)">Статьи</span>
        </span>
        <small class="soon" v-if="!isToolsRoute">Скоро</small>
      </a>
    </li>
    <li>
      <a :class="[
      isToolsRoute ? (screenWidth < 1200 ? 'fs-4 text-center ps-1' : 'fs-6') : 'text-decoration-none fs-5 disabled d-flex justify-content-between user-select-none',
      isToolsRoute ? (screenWidth <= 1399 ? (screenWidth < 1200 ? 'p-1 ps-1' : 'p-1 ps-3') : '') : '']"
      >
        <span>
          <font-awesome-icon icon="users" :class="isToolsRoute && screenWidth < 1200 ? 'me-0' : isToolsRoute ? 'me-2' : 'me-3'"/>
          <span v-if="!(isToolsRoute && screenWidth < 1200)">Сообщество</span>
        </span>
        <small class="soon" v-if="!isToolsRoute">Скоро</small>
      </a>
    </li>
  </ul>
<!--  <ul class="p-0 menu user-select-none" style="margin-top: 120px;">-->
<!--    <li>-->
<!--      <ThemeSwitcher />-->
<!--    </li>-->
<!--  </ul>-->
  <ul class="p-0 mb-0 docs user-select-none" v-if="!(isToolsRoute && screenWidth < 1200)">
    <li :class="[isToolsRoute && screenWidth <= 1399 ? 'p-1' : '']">
      <router-link to="/offer" class="text-muted text-decoration-none fs-6">
        <span>Публичная оферта</span>
      </router-link>
    </li>
    <li :class="[isToolsRoute && screenWidth <= 1399 ? 'p-1' : '']">
      <router-link to="/confidential" class="text-muted text-decoration-none fs-6">
        <span>Политика конфиденциальности</span>
      </router-link>
    </li>
    <li :class="[isToolsRoute && screenWidth <= 1399 ? 'p-1' : '']">
      <a href="https://t.me/YoumeGG" class="text-decoration-none text-muted fs-6" target="_blank">Наш телеграм-канал</a>
    </li>
    <li :class="[isToolsRoute && screenWidth <= 1399 ? 'p-1' : '']">
      <a href="https://t.me/+hhg3-yuObuBkZjky" class="text-decoration-none text-muted fs-6" target="_blank">Наш телеграм-чат</a>
    </li>
    <li :class="[isToolsRoute && screenWidth <= 1399 ? 'p-1' : '']">
      <small class="text-muted" style="font-size: 9px;">ИП Ерасова Е.В. ИНН: 632524064755 ОГРН: 324632700053071</small>
    </li>
  </ul>
</template>

<script setup>
import { useRoute } from 'vue-router';
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
// import ThemeSwitcher from "@/components/ThemeSwitcher.vue";

const route = useRoute();

const isToolsRoute = computed(() => route.path.startsWith('/tools/'));

const screenWidth = ref(window.innerWidth);

const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
.menu {
  list-style: none; /* Убрать маркеры списка */
  padding-left: 0; /* Убрать отступ слева */
}

.menu > li {
  padding-bottom: 10px;
}

/* Стили для активной ссылки */
.menu > li .router-link-active {
  background-color: #0d6efd; /* Основной синий фон */
  color: white;
  border-radius: 1.25rem;
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s; /* Плавный переход цвета */
}

/* Стили для активной ссылки при наведении */
.menu > li .router-link-active:hover {
  background-color: #0b5ed7; /* Темно-синий фон при наведении */
  color: #e2e6ea; /* Светло-серый текст при наведении */
}

/* Стили для ссылок при наведении */
.menu > li a:hover {
  background-color: var(--menu-bg-color);
  color: var(--menu-text-color);
  border-radius: 1.25rem;
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s; /* Плавный переход цвета */
}

/* Обеспечение отступов для ссылок без класса active */
.menu > li a {
  display: block;
  padding: 1rem 2rem;
  color: #777e8c; /* Серый цвет текста для неактивных ссылок */
  text-decoration: none;
  border-radius: 1.25rem;
  transition: background-color 0.3s, color 0.3s; /* Плавный переход цвета */
}

.soon {
  font-size: 12px;
  background: #0D6EFDFF;
  color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.docs>li {
  list-style: none;
  padding: 0.5rem 0 0 2rem;
}
.docs>li>a {
  display: block;
}
</style>

