import { createPinia, setActivePinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from "@/store/auth";
import OfferPage from "@/views/OfferPage.vue";
import ConfidentialPage from "@/views/ConfidentialPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import ToolsPage from "@/views/page/tools/ToolsPage.vue";
import KeywordResearchV1Page from "@/views/page/tools/keywordResearchV1/KeywordResearchV1Page.vue";
import FaqPage from "@/views/page/faq/FaqPage.vue";
import {useFaqStore} from "@/store/faq";
import FaqInstructionPage from "@/views/page/faq/FaqInstructionPage.vue";
import SubscriptionsPage from "@/views/page/subscriptions/SubscriptionsPage.vue";
import TrendingShortsPage from "@/views/page/tools/v1/TrendingShorts/TrendingShortsPage.vue";
import TrendingVideosPage from "@/views/page/tools/v1/TrendingVideos/TrendingVideosPage.vue";
// import CompetitorAnalysisPage from "@/views/page/tools/beta/CompetitorAnalysis/CompetitorAnalysisPage.vue";
import {useCompetitorAnalysisStore} from "@/store/tools/beta/CompetitorAnalysis";
// import MyProjectPage from "@/views/page/tools/beta/CompetitorAnalysis/MyProject/MyProjectPage.vue";
// import MyCompetitorsPage from "@/views/page/tools/beta/CompetitorAnalysis/MyProject/Competitors/MyCompetitorsPage.vue";
import ForgotPasswordPage from "@/views/page/auth/ForgotPasswordPage.vue";
// import PartnerPage from "@/views/page/partners/PartnerPage.vue";
// import UserProfilePage from "@/views/page/userProfile/UserProfilePage.vue";

const pinia = createPinia();
setActivePinia(pinia);

const Home = () => import('@/components/MainComponent');

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: { requiresAuth: false, title: 'Главная' }
    },
    {
        name: 'ToolsPage',
        path: '/tools',
        component: ToolsPage,
        meta: { requiresAuth: false, title: 'Инструменты' }
    },
    {
        name: 'KeywordResearchV1Page',
        path: '/tools/keyword-research-v1',
        component: KeywordResearchV1Page,
        meta: { requiresAuth: false, title: 'Поиск ключевых слов v1' }
    },
    {
        name: 'TrendingVideosPage',
        path: '/tools/trending-videos-v1',
        component: TrendingVideosPage,
        meta: { requiresAuth: false, title: 'Трендовые видео v1' }
    },
    {
        name: 'TrendingShortsPage',
        path: '/tools/trending-shorts-v1',
        component: TrendingShortsPage,
        meta: { requiresAuth: false, title: 'Трендовые Shorts v1' }
    },
    // {
    //     name: 'CompetitorAnalysisPage',
    //     path: '/tools/competitor-analysis',
    //     component: CompetitorAnalysisPage,
    //     meta: { requiresAuth: false, title: 'Анализ конкурентов (Beta)' }
    // },
    // {
    //     name: 'MyProjectPage',
    //     path: '/tools/competitor-analysis/:projectId',
    //     component: MyProjectPage,
    //     meta: { requiresAuth: true, title: '' }
    // },
    // {
    //     name: 'MyCompetitorsPage',
    //     path: '/tools/competitor-analysis/:projectId/competitors',
    //     component: MyCompetitorsPage,
    //     meta: { requiresAuth: true, title: 'Конкуренты' }
    // },
    {
        name: 'SubscriptionsPage',
        path: '/subscriptions',
        component: SubscriptionsPage,
        meta: { requiresAuth: false, title: 'Подписки' }
    },
    {
        name: 'offer',
        path: '/offer',
        component: OfferPage,
        meta: { requiresAuth: false, title: 'Публичная оферта' }
    },
    {
        name: 'confidential',
        path: '/confidential',
        component: ConfidentialPage,
        meta: { requiresAuth: false, title: 'Политика конфиденциальности' }
    },
    {
        name: 'faq',
        path: '/faq',
        component: FaqPage,
        meta: { requiresAuth: false, title: 'Инструкции' }
    },
    // {
    //     name: 'PartnerPage',
    //     path: '/partners',
    //     component: PartnerPage,
    //     meta: { requiresAuth: false, title: 'Партнерская программа' }
    // },
    // {
    //     name: 'UserProfilePage',
    //     path: '/:login',
    //     component: UserProfilePage,
    //     meta: { requiresAuth: false, title: 'Профиль пользователя' }
    // },
    {
        path: '/faq/:slug',
        component: FaqInstructionPage,
        meta: { requiresAuth: false, title: '' }
    },
    {
        path: '/auth/forgot/:token',
        component: ForgotPasswordPage,
        meta: { requiresAuth: false, title: 'Восстановление пароля' }
    },
    {
        name: 'referralRegister',
        path: '/register/:referralCode',
        beforeEnter: (to, from, next) => {
            localStorage.setItem('referralCode', to.params.referralCode);
            next({ name: 'home' });
        },
        meta: { requiresAuth: false, title: 'Главная' }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
        meta: { title: 'Такой старницы нет, 404' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Мидлвари для проверки аутентификации
async function authMiddleware(to, from, next) {
    const authStore = useAuthStore();
    if (to.meta.requiresAuth && !authStore.isAuthenticated) {
        next('/auth');
        return;
    }

    if (!to.meta.requiresAuth && authStore.isAuthenticated && to.name === 'auth') {
        next('/');
        return;
    }
    next();
}

// Мидлвари для FAQ
async function faqMiddleware(to, from, next) {
    if (to.path.startsWith('/faq/') && to.params.slug) {
        const faqStore = useFaqStore();
        try {
            await faqStore.fetchInstructionsBySlug(to.params.slug);
            if (faqStore.faqInstruction.seo_title) {
                to.meta.title = faqStore.faqInstruction.seo_title;
            }
            next();
        } catch (error) {
            console.error('Ошибка при получении данных инструкции FAQ', error);
            next(false);
        }
    } else {
        next();
    }
}

async function projectMiddleware(to, from, next) {
    if (to.path.startsWith('/competitor-analysis/') && to.params.projectId) {
        const competitorAnalysisStore = useCompetitorAnalysisStore();
        try {
            await competitorAnalysisStore.fetchProjectById(to.params.projectId);
            if (competitorAnalysisStore.currentProject) {
                to.meta.title = competitorAnalysisStore.currentProject.title;
            }
            next();
        } catch (error) {
            console.error('Ошибка при получении данных проекта', error);
            next(false);
        }
    } else {
        next();
    }
}

// Использование мидлвари
router.beforeEach(authMiddleware);
router.beforeEach(faqMiddleware);
router.beforeEach(projectMiddleware);

// Обновление заголовка страницы
router.afterEach((to) => {
    const projectName = 'Youme';
    let title = projectName;

    if (to.meta.title) {
        title = `${to.meta.title} / ${projectName}`;
    } else if (to.name === 'home') {
        title = `Главная`;
    }

    document.title = title;
});

export default router;