import { createApp } from 'vue'
import { pinia } from './store';
import { useAuthStore } from "@/store/auth";
import App from './App.vue'
import router from '@/router/router'
import Toast from 'vue-toastification'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUsers, faTools, faReceipt, faGear, faNewspaper, faMoon, faSun, faCircleLeft, faCopy, faUserGroup, faCamera, faArrowUp, faRotate, faHandshakeSimple, faCalendarAlt, faThumbsUp, faThumbsDown, faCheck, faCheckDouble, faAngleDown, faAngleUp, faBell, faBellSlash, faRightToBracket, faAnglesRight, faMagnifyingGlass, faCircleQuestion, faRobot, faArrowTrendUp} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


// Добавляем иконки в библиотеку
library.add(faHome, faUsers, faTools, faReceipt, faGear, faNewspaper, faMoon, faSun, faCircleLeft, faCopy, faUserGroup, faCamera, faArrowUp, faRotate, faHandshakeSimple, faCalendarAlt, faThumbsUp, faThumbsDown, faCheck, faCheckDouble, faAngleDown, faAngleUp, faBell, faBellSlash, faRightToBracket, faAnglesRight, faMagnifyingGlass, faCircleQuestion, faRobot, faArrowTrendUp)

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'vue-toastification/dist/index.css';

// Стили тем
import '@/assets/style/themes.css'

// Мои стили
import '@/assets/style/main.css'

const app = createApp(App)

const THEME_KEY = 'theme';

function setInitialTheme() {
    const savedTheme = localStorage.getItem(THEME_KEY) || 'dark';
    document.body.setAttribute('data-bs-theme', savedTheme);
}

setInitialTheme();

app.use(router)
app.use(Toast)
app.use(pinia);

const authStore = useAuthStore();
authStore.restoreState();

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app').$nextTick(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
        preloader.remove();
    }
});
