import axios from 'axios';
import { API_URL } from '@/config';

export const subscriptionsApi = {
    async fetchSubscriptions() {
        const response = await axios.get(`${API_URL}/subscriptions`);
        return response.data;
    },

    async initiateSbpPayment(subscriptionId, token) {
        const url = `${API_URL}/payment`;
        const params = {
            subscription: subscriptionId,
            currency: 'RUB',
            service: 'paykeeper'
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: params
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async initiateCardPayment(subscriptionId, token) {
        const url = `${API_URL}/payment`;
        const params = {
            subscription: subscriptionId,
            currency: 'RUB',
            service: 'tinkoff'
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: params
        };
        const response = await axios.get(url, config);
        return response.data;
    },

    async initiateUsdPayment(subscriptionId, token) {
        const url = `${API_URL}/payment`;
        const params = {
            subscription: subscriptionId,
            currency: 'USD',
            service: 'cryptomus'
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: params
        };
        const response = await axios.get(url, config);
        return response.data;
    }
};
