import axios from 'axios';
import { API_URL } from '@/config';

export const trendingVideosApi = {

    async fetchTrendingVideosData(token, dateFilter, categoryFilter, subscribersFilter, countryFilter, yearFilter, viewsFilter, excludedChannelIds, excludedCountryIds, page, keywordFilter) {
        const url = `${API_URL}/tools/v1/trending-videos`;
        const params = {
            date_published_at: dateFilter,
            ...parseSubscribersFilter(subscribersFilter),
            page: page
        };

        if (keywordFilter) {
            params.search_query = keywordFilter;
        }

        if (categoryFilter) {
            params.category = categoryFilter;
        }

        if (countryFilter) {
            params.country = countryFilter;
        }

        if (yearFilter) {
            params.year = yearFilter;
        }

        if (viewsFilter) {
            params.min_views = viewsFilter;
        }

        const data = {
            excluded_channel_ids: excludedChannelIds,
            excluded_countries: excludedCountryIds
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: params
        };
        const response = await axios.post(url, data, config);
        return response.data;
    },

    async fetchTop50TrendingVideos() {
        const url = `${API_URL}/public/tools/v1/trending-videos/top-50`;
        const response = await axios.get(url);
        return response.data;
    }
};

function parseSubscribersFilter(filter) {
    if (!filter) return {};
    const parts = filter.split('__');
    return parts.reduce((acc, part) => {
        const [key, value] = part.split('_channel_subscribers_');
        if (key && value) {
            acc[`${key}_channel_subscribers`] = value;
        }
        return acc;
    }, {});
}