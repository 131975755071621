<template>
  <div class="card-body position-relative" style="bottom: -35px; top: 0;">
    <div class="table-responsive-outer" style="overflow-x: auto; max-height: 710px;" v-if="sortedOrders.length > 0">
      <div class="table-responsive-inner" style="min-width: 800px;">
        <table class="table text-center mb-0">
          <thead class="sticky-top">
          <tr>
            <th class="nowrap">Название подписки</th>
            <th class="nowrap text-center">Операция</th>
            <th class="nowrap text-center">Способ оплаты</th>
            <th class="nowrap text-center">Дата оплаты</th>
            <th class="nowrap text-center">Срок подписки</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="order in sortedOrders" :key="order.id" class="keywords-list">
            <td class="nowrap">«{{ order.plan_name }}»</td>
            <td class="nowrap">{{ order.order_type === 'buy' ? 'Покупка' : 'Продление' }}</td>
            <td class="nowrap">{{ order.payment_name }}</td>
            <td class="nowrap">{{ formatDate(order.payment_date) }}</td>
            <td class="nowrap">{{ getSubscriptionDuration(order.paid.from, order.paid.to) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="padding-top: 50px; padding-bottom: 35px;" v-else>
      <div class="row justify-content-center align-items-center">
        <div class="col-6">
          <h4 class="text-center">Вы еще ничего не покупали</h4>
          <h4 class="text-center mb-0 fs-6">История будет доступна после первой покупки</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useAuthStore } from "@/store/auth";
import { differenceInDays, format, parseISO } from "date-fns";

const authStore = useAuthStore();

onMounted(() => {
  authStore.loadOrdersFromStorage();
});

const formatPaymentName = (name) => {
  switch (name) {
    case 'cryptomus':
      return 'USDT';
    case 'paykeeper':
      return 'СБП';
    default:
      return name;
  }
};

const sortedOrders = computed(() => {
  return authStore.orders
      .map(order => ({
        ...order,
        payment_name: formatPaymentName(order.payment_name)
      }))
      .sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));
});

function formatDate(date) {
  return format(parseISO(date), 'dd.MM.yyyy');
}

function getSubscriptionDuration(from, to) {
  const days = differenceInDays(parseISO(to), parseISO(from));

  if (days === 1) {
    return `${days} день`;
  }

  if (days < 7) {
    return `${days} дней`;
  }

  if (days < 30) {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;
    return remainingDays > 0 `${weeks} неделя`;
  }

  const months = Math.floor(days / 31);
  const remainingDays = days % 31;
  return remainingDays > 0
      ? `${months} месяц(а) и ${remainingDays} день(ей)`
      : `${months} месяц(а)`;
}
</script>

<style scoped>
.table-responsive-outer::-webkit-scrollbar {
  display: none;
}

.table-responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}
</style>