<template>
  <div class="row user-select-none mb-3" v-if="isLoading">
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-header">
          <div class="loading-skeleton rounded-4" style="width: 270px; height: 26px;"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 d-flex flex-column justify-content-center align-items-center">
              <div class="text-center fs-5 text-muted mb-2">Конкуренция</div>
              <div class="loading-skeleton mb-2 rounded-4" style="width: 70px; height: 26px;"></div>
              <div class="loading-skeleton rounded-4" style="width: 100px; height: 26px;"></div>
            </div>
            <div class="col-6 d-flex flex-column justify-content-center align-items-center">
              <div class="text-center fs-5 text-muted mb-2">Показов в месяц</div>
              <div class="loading-skeleton rounded-4" style="width: 180px; height: 26px;"></div>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center">
              <div class="text-center fs-5 text-muted mb-2">Общий балл</div>
              <div class="loading-skeleton mb-2 rounded-4" style="width: 70px; height: 26px;"></div>
              <div class="loading-skeleton rounded-4" style="width: 100px; height: 26px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <template v-else>
    <div class="row user-select-none mb-3" v-if="keywordData">
      <div class="col-12 mb-xxl-0">
        <div class="card rounded-4" v-for="(data, keyword) in keywordData.keyword" :key="keyword">
          <div class="card-header">Результат поиска по запросу «<b>{{ keyword }}</b>»</div>
          <div class="card-body">
            <div class="row">
              <div class="col-4 d-flex flex-column justify-content-center">
                <div class="text-center fs-5 text-muted mb-2">Конкуренция</div>
                <div class="text-center fs-3 fw-bold mb-2">{{ formatNumber(data.competition) }}</div>
                <div class="competition-badge text-center" :class="getCompetitionClass(data.competition)">
                  {{ getCompetitionText(data.competition) }}
                </div>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                <div class="text-center fs-5 text-muted mb-2">Показов в месяц</div>
                <div class="text-center fs-3 fw-bold">{{ formatNumber(data.estimated_monthly_search) }}</div>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                <div class="text-center fs-5 text-muted mb-2">Общий балл</div>
                <div class="text-center fs-3 fw-bold mb-2">{{ formatNumber(data.overall) }}</div>
                <div class="competition-badge text-center" :class="getTotalScoreClass(data.overall)">
                  {{ getTotalScoreText(data.overall) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed } from 'vue';
import { useKeywordStore } from '@/store/tools/v1/KeywordsResearch';

const keywordStore = useKeywordStore();
const keywordData = computed(() => keywordStore.keywordData);
const isLoading = computed(() => keywordStore.isLoading);

const getCompetitionText = (competition) => {
  if (competition >= 80) { return 'очень высокая'; }
  else if (competition >= 60) { return 'высокая'; }
  else if (competition >= 40) { return 'средняя'; }
  else if (competition >= 20) { return 'низкая'; }
  else { return 'очень низкая'; }
};

const getCompetitionClass = (competition) => {
  if (competition >= 80) { return 'high-competition'; }
  else if (competition >= 60) { return 'moderate-competition'; }
  else if (competition >= 40) { return 'medium-competition'; }
  else if (competition >= 20) { return 'low-competition'; }
  else { return 'very-low-competition'; }
};

const getTotalScoreText = (competition) => {
  if (competition >= 80) { return 'очень высокий'; }
  else if (competition >= 60) { return 'высокий'; }
  else if (competition >= 40) { return 'средний'; }
  else if (competition >= 20) { return 'низкий'; }
  else { return 'очень низкий'; }
};

const getTotalScoreClass = (competition) => {
  if (competition >= 80) { return 'very-low-score'; }
  else if (competition >= 60) { return 'low-score'; }
  else if (competition >= 40) { return 'medium-score'; }
  else if (competition >= 20) { return 'moderate-score'; }
  else { return 'high-score'; }
};

function formatNumber(number) {
  const wholeNumber = Math.floor(number);
  return new Intl.NumberFormat('ru-RU').format(wholeNumber);
}

</script>
