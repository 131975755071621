<template>
  <div class="fixed-bottom" style="bottom: 75px" v-if="!isConsentGiven">
    <div class="row justify-content-center">
      <div class="col-12 col-xxl-5 col-xl-6 col-sm-8">
        <div class="card rounded-5 user-select-none">
          <div class="card-body d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
            <div class="fs-3 fw-bold mb-3 mb-lg-0">🍪 Мы используем Cookie</div>
            <button class="btn btn-primary rounded-5" @click="giveConsent">Я не против</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isConsentGiven = ref(false);

onMounted(() => {
  const consent = localStorage.getItem('cookieConsent');
  isConsentGiven.value = consent === 'true';
});

function giveConsent() {
  localStorage.setItem('cookieConsent', 'true');
  isConsentGiven.value = true;
}
</script>

<style scoped>
</style>
