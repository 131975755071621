<template>
  <skeleton-trending-videos v-if="store.isLoading && store.isFirstLoad" />
  <template v-else>
    <div class="card-body">
      <h2 class="mb-0 fs-5">Результаты поиска</h2>
    </div>
    <div class="card-footer" v-if="filteredResults && filteredResults.length > 0">
      <div class="row mb-3" v-for="item in filteredResults" :key="item.video.video_id">
        <div class="col-12 col-xxl-5 col-xl-6 col-lg-5 col-md-6 col-sm-5">
          <div class="sticky-top" style="top: 85px;">
            <div class="card rounded-4">
              <div class="card-header">
                <div class="row">
                  <div class="col-12 mb-2 d-flex justify-content-center">
                    <img class="w-50" :src="item.channel.channel_avatar" alt="" style="border-radius: 100%">
                  </div>
                  <div class="col-12">
                    <h3 class="fs-6 mb-0 text-center">
                      <a class="text-decoration-none" :href="`https://www.youtube.com/channel/${item.channel.channel_id}`" target="_blank">{{ item.channel.channel_title }}</a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="card-body pt-2 pb-2">Статистика канала</div>
              <div class="card-footer">
                <ul class="list-group list-group-flush">
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Подписчики:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_subscribers) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Просмотры:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_view_count) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Видео:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_video_count) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.channel.channel_country">
                    <div class="me-auto">Страна:</div>
                    <span class="rounded-pill">{{ item.channel.channel_country }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Создан:</div>
                    <span class="rounded-pill">{{ formatDate(item.channel.channel_creation_date) }}</span>
                  </li>
                </ul>
              </div>
              <div class="card-footer hideBtn">
                <span class="text-muted me-2">Скрыть:</span>
                <a type="button" class="text-decoration-none me-2 text-danger" @click="excludeChannel(item.channel)">канал</a>
                <span class="text-muted" v-if="item.channel.channel_country">/</span>
                <a type="button" class="text-decoration-none ms-2 text-danger" v-if="item.channel.channel_country" @click="excludeCountry(item.channel.channel_country)">страну</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-7 col-xl-6 col-lg-7 col-md-6 col-sm-7">
          <div class="card rounded-4">
            <div class="card-header p-0 position-relative">
              <img class="w-100 rounded-top-4" :src="`https://i.ytimg.com/vi/${item.video.video_id}/hqdefault.jpg`" alt="">
              <span class="position-absolute duration">
                {{ formatDuration(item.video.duration) }}
              </span>
            </div>
            <div class="card-body border-bottom">
              <h3 class="fs-6 mb-0">
                <a class="text-decoration-none" :href="`https://youtu.be/${item.video.video_id}`" target="_blank">{{ item.video.title }}</a>
              </h3>
            </div>
            <div class="card-body pt-2 pb-2">Статистика видео</div>
            <div class="card-footer">
              <ul class="list-group list-group-flush">
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                  <div class="me-auto">Просмотры:</div>
                  <span class="rounded-pill">{{ formatNumber(item.video.views) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.video.vph">
                  <div class="me-auto">Просмотры в час:</div>
                  <span class="rounded-pill">{{ formatNumber(item.video.vph) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.video.category">
                  <div class="me-auto">Категория:</div>
                  <span class="rounded-pill">{{ getCategoryTranslation(item.video.category) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                  <div class="me-auto">Опубликовано:</div>
                  <span class="rounded-pill">{{ formatDate(item.video.date_published_at) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-0" v-if="store.isLoading && !store.isFirstLoad">
      <div class="row mb-3" v-for="n in 5" :key="n">
        <div class="col-12 col-xxl-5 col-xl-6 col-lg-5 col-md-6 col-sm-5">
          <div class="sticky-top" style="top: 85px;">
            <div class="card rounded-4">
              <div class="card-header">
                <div class="row">
                  <div class="col-12 mb-2 d-flex justify-content-center">
                    <div class="loading-skeleton" style="border-radius: 100%; width: 115px; height: 115px;"></div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <div class="loading-skeleton rounded-4" style="width: 156px; height: 15px;"></div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-2 pb-2">
                <div class="loading-skeleton rounded-4" style="width: 156px; height: 30px;"></div>
              </div>
              <div class="card-footer">
                <ul class="list-group list-group-flush">
                  <li class="ps-0 pe-0 list-group-item">
                    <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                  </li>
                  <li class="ps-0 pe-0 list-group-item">
                    <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                  </li>
                  <li class="ps-0 pe-0 list-group-item">
                    <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                  </li>
                  <li class="ps-0 pe-0 list-group-item">
                    <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                  </li>
                  <li class="ps-0 pe-0 list-group-item">
                    <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-7 col-xl-6 col-lg-7 col-md-6 col-sm-7">
          <div class="card rounded-4">
            <div class="card-header p-0">
              <div class="loading-skeleton mb-3 rounded-top-4" style="width: 379px; height: 284px;"></div>
              <div class="loading-skeleton rounded-4 mb-3 me-3 ms-3" style="width: 320px; height: 20px;"></div>
            </div>
            <div class="card-body pt-2 pb-2">
              <div class="loading-skeleton rounded-4" style="width: 200px; height: 30px;"></div>
            </div>
            <div class="card-footer">
              <ul class="list-group list-group-flush">
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
                <li class="ps-0 pe-0 list-group-item">
                  <div class="loading-skeleton rounded-4" style="width: 200px; height: 25px;"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="store.noMoreResults">
      <p class="text-center text-muted mb-0">Результатов больше нет</p>
    </div>
  </template>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useTrendingShortsStore } from "@/store/tools/v1/TrendingShorts";
import SkeletonTrendingVideos from "@/components/tools/v1/TrendingVideos/SkeletonTrendingShorts.vue";

const store = useTrendingShortsStore();
const excludedChannels = ref(JSON.parse(localStorage.getItem('excludedChannels_shorts')) || []);
const excludedCountries = ref(JSON.parse(localStorage.getItem('excludedCountries_shorts')) || []);

const filteredResults = computed(() => {
  return store.trendingShortsData ? store.trendingShortsData.results.filter(item =>
      !excludedChannels.value.some(ch => ch.id === item.channel.channel_id) &&
      !excludedCountries.value.includes(item.channel.channel_country)
  ) : [];
});

const categoryTranslations = {
  "Pets & Animals": "Животные",
  "Gaming": "Игры",
  "People & Blogs": "Люди и блоги",
  "Music": "Музыка",
  "Science & Technology": "Наука и техника",
  "News & Politics": "Новости и политика",
  "Education": "Образование",
  "Nonprofits & Activism": "Общественная деятельность",
  "Travel & Events": "Путешествия",
  "Entertainment": "Развлечения",
  "Sports": "Спорт",
  "Autos & Vehicles": "Транспорт",
  "Film & Animation": "Фильмы и анимация",
  "Howto & Style": "Хобби и стиль",
  "Comedy": "Юмор"
};

function getCategoryTranslation(category) {
  return categoryTranslations[category] || category;
}

function handleScroll() {
  const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
  if (bottomOfWindow && !store.noMoreResults && !store.isLoading) {
    store.fetchTrendingShortsData({ page: store.currentPage });
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  if (!store.isFirstLoad) {
    store.fetchTrendingShortsData({ page: store.currentPage });
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

function formatNumber(value) {
  return parseInt(value).toLocaleString('ru-RU');
}

function formatDate(dateString) {
  const targetDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (targetDate.toDateString() === today.toDateString()) {
    return 'Сегодня';
  } else if (targetDate.toDateString() === yesterday.toDateString()) {
    return 'Вчера';
  } else {
    return targetDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
}

function excludeChannel(channel) {
  excludedChannels.value.push({
    id: channel.channel_id,
    title: channel.channel_title,
    avatar: channel.channel_avatar
  });
  localStorage.setItem('excludedChannels_shorts', JSON.stringify(excludedChannels.value));
  store.setExcludedChannelIds(excludedChannels.value.map(ch => ch.id));
  store.trendingShortsData.results = store.trendingShortsData.results.filter(item => item.channel.channel_id !== channel.channel_id);

  window.dispatchEvent(new Event('storage'));
}

function excludeCountry(country) {
  if (!excludedCountries.value.includes(country)) {
    excludedCountries.value.push(country);
    localStorage.setItem('excludedCountries_shorts', JSON.stringify(excludedCountries.value));
    store.setExcludedCountryIds(excludedCountries.value);
  }
  store.trendingShortsData.results = store.trendingShortsData.results.filter(item => item.channel.channel_country !== country);

  window.dispatchEvent(new Event('storage'));
}

function formatDuration(seconds) {
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHrs = hrs > 0 ? `${String(hrs).padStart(2, '0')}:` : '';
  const formattedMin = `${String(min).padStart(2, '0')}:`;
  const formattedSecs = String(secs).padStart(2, '0');

  return `${formattedHrs}${formattedMin}${formattedSecs}`;
}
</script>

<style scoped>
.list-group-item {
  background-color: #ffffff00!important;
}

.duration {
  bottom: 10px;
  right: 15px;
  background: #ffffff59;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 700;
}
</style>