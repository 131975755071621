<template>
  <div class="card-body d-flex justify-content-between align-items-center border-bottom">
    <h2 class="fs-5 mb-0">Скрыто</h2>
    <div class="hiddenResults">
      <button type="button" class="me-3 btn btn-outline-warning rounded-4" @click="openHiddenChannelsModal" :disabled="!hasPlan700Active || trendingVideosStore.isLoading">{{ hiddenChannelsCount }} {{ channelWord }}</button>
      <button type="button" class="btn btn-outline-warning rounded-4" @click="openHiddenCountryModal" :disabled="!hasPlan700Active || trendingVideosStore.isLoading">{{ hiddenCountriesCount }} {{ countryWord }}</button>
    </div>
  </div>
  <hidden-channels-modal ref="hiddenChannelsModalRef" />
  <hidden-country-modal ref="hiddenCountryModalRef"/>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import hiddenChannelsModal from "@/components/tools/v1/TrendingVideos/HiddenModals/HiddenChannelsModal.vue";
import HiddenCountryModal from "@/components/tools/v1/TrendingVideos/HiddenModals/HiddenCountryModal.vue";
import {useTrendingVideosStore} from "@/store/tools/v1/TrendingVideos";
import {useAuthStore} from "@/store/auth";

function getPluralForm(n, forms) {
  if (n % 10 === 1 && n % 100 !== 11) {
    return forms[0];
  } else if ([2, 3, 4].includes(n % 10) && ![12, 13, 14].includes(n % 100)) {
    return forms[1];
  } else {
    return forms[2];
  }
}

const authStore = useAuthStore();
const trendingVideosStore = useTrendingVideosStore();
const hasPlan700Active = computed(() => authStore.hasSpecificPlanActive(700));

const hiddenChannelsModalRef = ref(null);
const hiddenCountryModalRef = ref(null);

const excludedChannels = ref(JSON.parse(localStorage.getItem('excludedChannels')) || []);
const excludedCountries = ref(JSON.parse(localStorage.getItem('excludedCountries')) || []);

const hiddenChannelsCount = computed(() => excludedChannels.value.length);
const hiddenCountriesCount = computed(() => excludedCountries.value.length);

const channelWord = computed(() => getPluralForm(hiddenChannelsCount.value, ['канал', 'канала', 'каналов']));
const countryWord = computed(() => getPluralForm(hiddenCountriesCount.value, ['страна', 'страны', 'стран']));

const updateExcludedData = () => {
  excludedChannels.value = JSON.parse(localStorage.getItem('excludedChannels')) || [];
  excludedCountries.value = JSON.parse(localStorage.getItem('excludedCountries')) || [];
};

const openHiddenChannelsModal = () => {
  hiddenChannelsModalRef.value?.showModal();
};

const openHiddenCountryModal = () => {
  hiddenCountryModalRef.value?.showModal();
};

onMounted(() => {
  window.addEventListener('storage', updateExcludedData);
  updateExcludedData();
});

onUnmounted(() => {
  window.removeEventListener('storage', updateExcludedData);
});
</script>

<style scoped>
.hiddenResults>span {
  border: 1px #ffc107 solid;
  border-radius: 50px;
  padding: 5px 20px;
  cursor: pointer;
}
</style>