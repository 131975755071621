<template>
  <skeleton-trending-videos  v-if="store.isLoading" />
  <template v-else>
    <div class="card-body">
      <h2 class="mb-0 fs-5">ТОП-50 трендовых видео за неделю</h2>
    </div>
    <div class="card-footer" v-if="store.top50TrendingVideosData">
      <div class="row mb-3" v-for="item in store.top50TrendingVideosData.results" :key="item.video.video_id">
        <div class="col-12 col-xxl-5 col-xl-6 col-lg-5 col-md-6 col-sm-5">
          <div class="sticky-top" style="top: 85px;">
            <div class="card rounded-4">
              <div class="card-header">
                <div class="row">
                  <div class="col-12 mb-2 d-flex justify-content-center">
                    <img class="w-50" :src="`${item.channel.channel_avatar}`" alt="" style="border-radius: 100%">
                  </div>
                  <div class="col-12">
                    <h3 class="fs-6 mb-0 text-center">
                      <a class="text-decoration-none" :href="`https://www.youtube.com/channel/${item.channel.channel_id}`" target="_blank">{{ item.channel.channel_title }}</a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="card-body pt-2 pb-2">Статистика канала</div>
              <div class="card-footer">
                <ul class="list-group list-group-flush">
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Подписчики:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_subscribers) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Просмотры:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_view_count) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Видео:</div>
                    <span class="rounded-pill">{{ formatNumber(item.channel.channel_video_count) }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.channel.channel_country != null">
                    <div class="me-auto">Страна:</div>
                    <span class="rounded-pill">{{ item.channel.channel_country }}</span>
                  </li>
                  <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">Создан:</div>
                    <span class="rounded-pill">{{ formatDate(item.channel.channel_creation_date) }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-7 col-xl-6 col-lg-7 col-md-6 col-sm-7">
          <div class="card rounded-4">
            <div class="card-header p-0 position-relative">
              <img class="w-100 rounded-top-4" :src="`https://i.ytimg.com/vi/${item.video.video_id}/hqdefault.jpg`" alt="">
              <span class="position-absolute duration">
                {{ formatDuration(item.video.duration) }}
              </span>
            </div>
            <div class="card-body border-bottom">
              <h3 class="fs-6 mb-0">
                <a class="text-decoration-none" :href="`https://youtu.be/${item.video.video_id}`" target="_blank">{{ item.video.title }}</a>
              </h3>
            </div>
            <div class="card-body pt-2 pb-2">Статистика видео</div>
            <div class="card-footer">
              <ul class="list-group list-group-flush">
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                  <div class="me-auto">Просмотры:</div>
                  <span class="rounded-pill">{{ formatNumber(item.video.views) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.video.vph != null">
                  <div class="me-auto">Просмотры в час:</div>
                  <span class="rounded-pill">{{ formatNumber(item.video.vph) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start" v-if="item.video.category != null">
                  <div class="me-auto">Категория:</div>
                  <span class="rounded-pill">{{ getCategoryTranslation(item.video.category) }}</span>
                </li>
                <li class="ps-0 pe-0 list-group-item d-flex justify-content-between align-items-start">
                  <div class="me-auto">Опубликовано:</div>
                  <span class="rounded-pill">{{ formatDate(item.video.date_published_at) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { useTrendingVideosStore } from "@/store/tools/v1/TrendingVideos";
import { onMounted } from "vue";
import SkeletonTrendingVideos from "@/components/tools/v1/TrendingVideos/SkeletonTrendingShorts.vue";

const store = useTrendingVideosStore();

onMounted(async () => {
  if (!store.top50TrendingVideosData) {
    await store.fetchTop50TrendingVideos();
  }
});

function formatNumber(value) {
  return parseInt(value).toLocaleString('ru-RU');
}

function formatDate(dateString) {
  const targetDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (targetDate.toDateString() === today.toDateString()) {
    return 'Сегодня';
  } else if (targetDate.toDateString() === yesterday.toDateString()) {
    return 'Вчера';
  } else {
    return targetDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
}

const categoryTranslations = {
  "Pets & Animals": "Животные",
  "Gaming": "Игры",
  "People & Blogs": "Люди и блоги",
  "Music": "Музыка",
  "Science & Technology": "Наука и техника",
  "News & Politics": "Новости и политика",
  "Education": "Образование",
  "Nonprofits & Activism": "Общественная деятельность",
  "Travel & Events": "Путешествия",
  "Entertainment": "Развлечения",
  "Sports": "Спорт",
  "Autos & Vehicles": "Транспорт",
  "Film & Animation": "Фильмы и анимация",
  "Howto & Style": "Хобби и стиль",
  "Comedy": "Юмор"
};

function getCategoryTranslation(category) {
  return categoryTranslations[category] || category;
}

function formatDuration(seconds) {
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHrs = hrs > 0 ? `${String(hrs).padStart(2, '0')}:` : '';
  const formattedMin = `${String(min).padStart(2, '0')}:`;
  const formattedSecs = String(secs).padStart(2, '0');

  return `${formattedHrs}${formattedMin}${formattedSecs}`;
}
</script>

<style scoped>
.list-group-item {
  background-color: #ffffff00!important;
}

.duration {
  bottom: 10px;
  right: 15px;
  background: #ffffff59;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 700;
}
</style>