<template>
  <div class="row user-select-none mb-3">
    <div class="col-12 mb-3">
      <h1 class="fw-bolder mb-0">{{ faqInstruction.h1 }}</h1>
    </div>
    <div class="col-12">
      <breadcrumbs-component :instruction-title="faqInstruction.h1"/>
    </div>
  </div>
  <div class="row user-select-none">
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-body" v-html="faqInstruction.content"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import { useFaqStore } from '@/store/faq';

const route = useRoute();
const store = useFaqStore();

onMounted(async () => {
  await store.fetchInstructionsBySlug(route.params.slug);
});

const faqInstruction = computed(() => store.faqInstruction);
</script>

<style>
.quote {
  padding: 10px;
  background-color: #0d6efd;
  color: white;
  border-radius: 1rem;
}
</style>