<template>
  <div class="row user-select-none">
    <div class="col-12 mb-3">
      <h1 class="fw-bolder mb-0">Подписки</h1>
    </div>
    <div class="col-12 mb-3">
      <breadcrumbs-component />
    </div>
    <div class="col-12">
      <div class="alert alert-primary rounded-4">Приобретая подписку, вы получаете полный доступ ко всем функциям оплаченного инструмента.</div>
    </div>
  </div>
  <div class="row user-select-none" v-if="isAuthenticated">
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-header">
          <ul class="nav nav-tabs nav-pills nav-fill" id="subscriptionTabs" role="tablist">
            <li class="nav-item me-0 me-xxl-3 me-xl-3 me-lg-3 mb-2 mb-lg-0" role="presentation">
              <button class="nav-link rounded-4 active" id="available-tab" data-bs-toggle="tab" data-bs-target="#available" type="button" role="tab" aria-controls="available" aria-selected="false">Подписки</button>
            </li>
            <li class="nav-item me-0 me-xxl-3 me-xl-3 me-lg-3 mb-2 mb-lg-0" role="presentation">
              <button class="nav-link rounded-4" id="active-tab" data-bs-toggle="tab" data-bs-target="#active" type="button" role="tab" aria-controls="active" aria-selected="true">Активные подписки</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link rounded-4" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="true">Мои покупки</button>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="available" role="tabpanel" aria-labelledby="available-tab">
            <subscriptions-list />
          </div>
          <div class="tab-pane fade" id="active" role="tabpanel" aria-labelledby="active-tab">
            <my-active-subscriptions />
          </div>
          <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
            <my-subscriptions-history />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row user-select-none" v-else>
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-header">Доступные подписки</div>
        <subscriptions-list />
      </div>
    </div>
  </div>
</template>

<script setup>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import SubscriptionsList from "@/components/subscriptions/SubscriptionsList.vue";
import MyActiveSubscriptions from "@/components/subscriptions/MyActiveSubscriptions.vue";
import { useAuthStore } from '@/store/auth';
import { computed } from "vue";
import MySubscriptionsHistory from "@/components/subscriptions/MySubscriptionsHistory.vue";

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
</script>

<style scoped>
.keywords-list {
  border-bottom: 1px solid var(--bs-card-border-color);
  font-size: 20px;
  padding: 15px;
}

.keyword:hover {
  color: #0d6efd!important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.card-footer {
  background-color: var(--card-bg-color);
}

.form-select:focus {
  border-color: #ced0d1;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-fill .nav-item .nav-link {
  color: #777e8c;
  font-weight: bolder !important;
  font-size: 1.25rem !important;
}

.nav-pills .nav-link.active {
  font-weight: bolder !important;
  font-size: 1.25rem !important;
  color: white;
}

.nav-tabs .nav-link:hover {
  background-color: var(--bs-nav-pills-link-active-bg);
  color: white;
}

.table-responsive-outer::-webkit-scrollbar {
  display: none;
}

.table-responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.nowrap {
  white-space: nowrap;
  padding: 15px;
}

.competition-badge {
  text-align: center;
}

.table-responsive-outer {
  cursor: grab;
}

.form-select {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.form-select:disabled {
  background-color: var(--card-bg-color);
  opacity: 0.5;
}

.form-select:hover {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled,.input-group-text {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}
</style>