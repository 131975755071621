<template>
  <div class="row user-select-none">
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-header">Инструкции к инструментам</div>
        <div class="card-body">
          <div class="row" v-if="isLoading">
            <div class="col-12 col-xxl-4 col-sm-6" v-for="n in 3" :key="n">
              <div class="faqCategory rounded-4" style="border: 2px #1d1a31 solid!important;">
                <div class="card-body d-flex flex-column justify-content-center align-items-center">
                  <div class="loading-skeleton rounded-4 mb-2" style="width: 200px; height: 22px;"></div>
                  <div class="loading-skeleton rounded-4" style="width: 180px; height: 22px;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 col-xxl-4 col-sm-6" v-for="instruction in faqAllInstructions" :key="instruction.id">
              <div class="faqCategory rounded-4">
                <router-link :to="`/faq/${instruction.slug}`" class="text-decoration-none w-100 d-block p-3 text-muted text-center" :class="{ 'router-link-active': $route.path === `/faq/${instruction.slug}` }">
                  {{ instruction.title }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import { useFaqStore } from '@/store/faq';

const store = useFaqStore();
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  await store.getAllInstructions();
  isLoading.value = false;
});

const faqAllInstructions = computed(() => store.faqAllInstructions);
</script>


<style scoped>
.faqCategory {
  border: 2px #777e8c solid;
}
.faqCategory:hover {
  border: 2px var(--menu-bg-color) solid;
  transition: background-color 0.3s, color 0.3s;
}
.faqCategory a:hover {
  color: var(--menu-text-color)!important;
  transition: background-color 0.3s, color 0.3s;
}
</style>