<template>
  <div class="row user-select-none mb-3">
    <not-authorized v-if="!isAuthenticated" />
    <no-subscription v-if="isAuthenticated && !hasPlan500Active" />
    <div class="col-12">
      <form @submit.prevent="fetchKeyword">
        <div class="input-group">
          <input v-model="keyword" class="form-control form-control-lg rounded-4 rounded-end-0" type="text" placeholder="Введите ключевое слово" :disabled="!isAuthenticated || !hasPlan500Active || isLoading">
          <button type="submit" class="btn btn-outline-primary rounded-end-4" :disabled="!isAuthenticated || !hasPlan500Active || isLoading">
            <font-awesome-icon icon="search" class="pe-3 ps-3"/>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useKeywordStore } from '@/store/tools/v1/KeywordsResearch';
import { useAuthStore } from "@/store/auth";
import('@/components/tools/notifications/NoSubscription.vue');
import NotAuthorized from "@/components/tools/notifications/NotAuthorized.vue";
import NoSubscription from "@/components/tools/notifications/NoSubscription.vue";


const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const hasPlan500Active = computed(() => authStore.hasSpecificPlanActive(500));
const keywordStore = useKeywordStore();
const isLoading = computed(() => keywordStore.isLoading);

const keyword = computed({
  get: () => keywordStore.currentKeyword,
  set: (newKeyword) => {
    keywordStore.currentKeyword = newKeyword;
  }
});

const fetchKeyword = () => {
  if (keyword.value.trim()) {
    keywordStore.fetchKeywordData(keyword.value);
  }
};
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled {
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
  background-color: var(--card-bg-color);
}

.form-control:disabled {
  opacity: 0.8;
}

.input-group .btn {
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
  background-color: var(--card-bg-color);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: #c5c4ca;
  background-color: var(--card-bg-color);
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width)* 2));
  padding: 0.1rem 1rem;
  font-size: 2rem;
}
</style>