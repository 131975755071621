import {defineStore} from 'pinia';
import {keywordsResearchApi} from "@/api/tools/v1/KeywordsResearchApi";
import {useAuthStore} from '@/store/auth';

export const useKeywordStore = defineStore('keywordStore', {
    state: () => ({
        keywordData: null,
        top100KeywordsData: null,
        isLoading: false,
        isLoadingTop100: false,
        error: null,
        currentKeyword: '',
        competitionFilter: 'Любая конкуренция',
        overallFilter: 'Любой общий балл'
    }),
    actions: {
        async fetchKeywordData(keyword) {
            this.competitionFilter = 'Любая конкуренция';
            this.overallFilter = 'Любой общий балл';
            this.currentKeyword = keyword;
            this.isLoading = true;
            this.isLoadingTop100 = true;
            this.error = null;
            const authStore = useAuthStore();

            if (!authStore.accessToken) {
                this.error = 'Требуется авторизация для поиска по ключевым словам';
                this.isLoading = false;
                this.isLoadingTop100 = false;
                return;
            }

            try {
                this.keywordData = await keywordsResearchApi.fetchKeywordData(keyword, authStore.accessToken);
            } catch (error) {
                this.error = `Ошибка при поиске по ключевым словам: ${error.message || error}`;
            } finally {
                this.isLoading = false;
                this.isLoadingTop100 = false;
            }
        },

        async fetchTop100Keywords(period, language) {
            this.isLoadingTop100 = true;
            this.error = null;

            try {
                this.top100KeywordsData = await keywordsResearchApi.fetchTop100Keywords(period, language);
            } catch (error) {
                this.error = `Ошибка при получении ТОП-100 ключевых слов: ${error.message || error}`;
            } finally {
                this.isLoadingTop100 = false;
            }
        },

        setCompetitionFilter(competition) {
            this.competitionFilter = competition;
        },

        setOverallFilter(overall) {
            this.overallFilter = overall;
        },
    }
});