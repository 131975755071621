<template>
  <div class="row user-select-none mb-3">
    <div class="col-12 mb-3">
      <h1 class="fw-bolder mb-0">Сброс пароля</h1>
    </div>
    <div class="col-12">
      <div class="card rounded-4">
        <div class="card-body p-5">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-6 col-lg-8 col-md-7">
              <div class="card rounded-4">
                <div class="card-body p-5">
                  <form @submit.prevent="submitNewPassword">
                    <div class="d-flex flex-column mb-0">
                      <input type="password" v-model="newPassword" class="form-control rounded-top-4 rounded-bottom-0 border-bottom-0" style="border-color: #ffc107;" id="newPassword" placeholder="Новый пароль" required>
                      <input type="password" v-model="confirmPassword" class="form-control rounded-0 border-bottom-0" style="border-color: #ffc107;" id="confirmPassword" placeholder="Пароль еще раз" required>
                    </div>
                    <button type="submit" class="btn btn-outline-warning w-100 rounded-top-0 rounded-bottom-4">Сбросить пароль</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useToast } from 'vue-toastification';

const newPassword = ref('');
const confirmPassword = ref('');
const router = useRouter();
const route = useRoute();
const toast = useToast();
const authStore = useAuthStore();

const submitNewPassword = async () => {
  if (newPassword.value !== confirmPassword.value) {
    toast.error("Пароли не совпадают");
    return;
  }

  try {
    await authStore.resetPassword(route.params.token, newPassword.value, confirmPassword.value);
    toast.success("Вы успешно сменили пароль");
    await router.push('/');
  } catch (error) {
    toast.error('Ошибка при сбросе пароля', error);
  }
};
</script>


<style scoped>
.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled {
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
  background-color: var(--card-bg-color);
}

.form-control:disabled {
  opacity: 0.8;
}

.input-group .btn {
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
  background-color: var(--card-bg-color);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: #c5c4ca;
  background-color: var(--card-bg-color);
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width)* 2));
  padding: 0.1rem 1rem;
  font-size: 1.4rem;
}
</style>