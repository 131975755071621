<template>
  <div :class="isNarrowMenuScreen ? 'border-top-0 card-header' : 'card-header border-top'">Для видео</div>
  <div class="card-body responsive-outer" style="overflow: auto; max-height: 250px;">
    <div class="mb-2">
      <label :class="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Ключевое слово</label>
      <input v-model="localKeywordFilter" class="form-control rounded-4" type="text" placeholder="*можно оставить пустым"
             :disabled="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading"
             :style="{ cursor: !hasPlan750Active ? 'not-allowed' : 'auto' }"
             @input="updateKeywordFilter">
    </div>
    <div class="mb-2">
      <label :class="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Дата публикации</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading"
              :style="{ cursor: !hasPlan750Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedDateFilter"
              @change="updateFilter">
        <option value="now-48H">За посл. 48 часов</option>
        <option value="now-1w" v-if="hasPlan750Active">За эту неделю</option>
        <option value="now-1M" v-if="hasPlan750Active">За этот месяц</option>
        <option value="now-6M" v-if="hasPlan750Active">За посл. 6 месяцев</option>
      </select>
    </div>
    <div class="mb-2">
      <label :class="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Категория</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading"
              :style="{ cursor: !hasPlan750Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedCategoryFilter"
              @change="updateCategoryFilter">
        <option value="">Любая категория</option>
        <option value="Pets & Animals">Животные</option>
        <option value="Gaming">Игры</option>
        <option value="People & Blogs">Люди и блоги</option>
        <option value="Music">Музыка</option>
        <option value="Science & Technology">Наука и техника</option>
        <option value="News & Politics">Новости и политика</option>
        <option value="Education">Образование</option>
        <option value="Nonprofits & Activism">Общественная деятельность</option>
        <option value="Travel & Events">Путешествия</option>
        <option value="Entertainment">Развлечения</option>
        <option value="Sports">Спорт</option>
        <option value="Autos & Vehicles">Транспорт</option>
        <option value="Film & Animation">Фильмы и анимация</option>
        <option value="Howto & Style">Хобби и стиль</option>
        <option value="Comedy">Юмор</option>
      </select>
    </div>
    <div>
      <label :class="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading ? 'opacity-50 form-label' : 'form-label'">Просмотры</label>
      <select class="form-select rounded-4"
              :disabled="!isAuthenticated || !hasPlan750Active || trendingShortsStore.isLoading"
              :style="{ cursor: !hasPlan750Active ? 'not-allowed' : 'auto' }"
              v-model="localSelectedViewsFilter"
              @change="updateViewsFilter">
        <option value="">Любое значение</option>
        <option value="0" v-if="hasPlan750Active">До 1 000</option>
        <option value="1000" v-if="hasPlan750Active">Более 1 000</option>
        <option value="10000" v-if="hasPlan750Active">Более 10 000</option>
        <option value="100000" v-if="hasPlan750Active">Более 100 000</option>
        <option value="1000000" v-if="hasPlan750Active">Более 1 000 000</option>
        <option value="10000000" v-if="hasPlan750Active">Более 10 000 000</option>
        <option value="100000000" v-if="hasPlan750Active">Более 100 000 000</option>
        <option value="1000000000" v-if="hasPlan750Active">Более 1 000 000 000</option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useTrendingShortsStore } from '@/store/tools/v1/TrendingShorts';

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const hasPlan750Active = computed(() => authStore.hasSpecificPlanActive(750));

const trendingShortsStore = useTrendingShortsStore();

const localSelectedDateFilter = ref(trendingShortsStore.dateFilter);
const localSelectedCategoryFilter = ref(trendingShortsStore.categoryFilter);
const localSelectedViewsFilter = ref(trendingShortsStore.viewsFilter);
const localKeywordFilter = ref('');

const updateFilter = () => {
  trendingShortsStore.updateDateFilter(localSelectedDateFilter.value);
};

const updateCategoryFilter = () => {
  trendingShortsStore.updateCategoryFilter(localSelectedCategoryFilter.value);
};

const updateViewsFilter = () => {
  trendingShortsStore.updateViewsFilter(localSelectedViewsFilter.value);
};

const updateKeywordFilter = () => {
  trendingShortsStore.updateKeywordFilter(localKeywordFilter.value);
};

const isNarrowMenuScreen = ref(window.innerWidth < 1200);

const updateScreenSize = () => {
  isNarrowMenuScreen.value = window.innerWidth < 1200;
};

onMounted(() => {
  window.addEventListener('resize', updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenSize);
});
</script>

<style scoped>
.form-select {
  border: 2px var(--card-border-color) solid;
  box-shadow: none;
  background-color: var(--card-bg-color);
}

.form-select:disabled {
  background-color: var(--card-bg-color);
  opacity: 0.5;
}

.form-select:hover {
  cursor: pointer;
}

.responsive-outer::-webkit-scrollbar {
  display: none;
}

.responsive-outer {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
}

.form-control,.form-control:disabled {
  border: 2px var(--card-border-color) solid;
  box-shadow: 5px 5px 10px 0 var(--card-shadow-color);
  background-color: var(--card-bg-color);
}

.form-control:disabled {
  opacity: 0.8;
}
</style>