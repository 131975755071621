<template>
  <div class="row">
    <div class="col-12">
      <breadcrumbs-component />
    </div>
    <div class="col-12">
      <div class="card rounded-4 user-select-none mt-3">
        <div class="card-header">
          <h1 class="mb-0 text-center fs-4">Политика конфиденциальности</h1>
        </div>
        <div class="card-body">
          <p>
            Настоящая Политика конфиденциальности в отношении обработки персональных данных (далее – «Политика»)
            составлена в соответствии с требованиями Федерального закона от 27.07.2006 №152-ФЗ
            «О персональных данных» (далее - «Федеральный закон») и определяет порядок обработки персональных
            данных и меры по обеспечению безопасности персональных данных со стороны ИП Ерасова Е.В.
            (далее по тексту – «Оператор»).
          </p>
          <p>
            Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение
            прав и свобод человека и гражданина при обработке его персональных данных, в
            том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
          </p>
          <p>
            Настоящая политика Оператора в отношении обработки персональных данных применяется ко
            всей информации, которую Оператор может получить о Пользователях Сервиса.
          </p>
          <p>
            Используя Сервис и предоставляя свои персональные данные Оператору, Пользователь дает
            согласие на обработку персональных данных в соответствии с данной Политикой, подтверждает,
            что ознакомлен со всеми пунктами настоящей Политики и принимает их без исключений и оговорок.
          </p>
          <p>
            Пользователь выражает свое полное согласие с условиями Политики путем нажатия специальных кнопок
            на страницах Сервиса при заполнении форм. В случае несогласия с любым из пунктов
            Политики Пользователь не имеет права использовать Сервис. Также Пользователю запрещено
            использовать Сервис, если он не достиг установленного законом возраста, когда он имеет
            право заключать соглашения с Оператором.
          </p>
          <ol>
            <li class="mb-4">
              <h2>Основные понятия, используемые в Политике</h2>
              <ol class="sub-list">
                <li>Сервис – интернет-ресурс, располагаемый по сетевому адресу https://youme.gg,
                  администрируемый Оператором.</li>
                <li>Обработка персональных данных – любое действие (операция) или совокупность действий
                  (операций), совершаемых с использованием средств автоматизации или без использования
                  таких средств с персональными данными, включая сбор, запись, систематизацию, накопление,
                  хранение, уточнение (обновление, изменение), извлечение, использование, передачу
                  (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
                  уничтожение персональных данных.</li>
                <li>Оператор – гражданка РФ ИП Ерасова Е.В. (ИНН 632524064755, ОГРН 324632700053071),
                  самостоятельно или совместно с другими лицами организующий и (или)
                  осуществляющий обработку персональных данных, а также определяющий цели
                  обработки персональных данных, состав персональных данных, подлежащих обработке,
                  действия (операции), совершаемые с персональными данными.</li>
                <li>Персональные данные – любая информация, относящаяся прямо или косвенно к определенному
                  или определяемому Пользователю Сервиса.</li>
                <li>Пользователь – посетитель Сервиса, желающий воспользоваться и/или пользующийся
                  услугами Оператора посредством Сервиса.</li>
                <li>Услуги – услуги, оказываемые Оператором посредством Сервиса.</li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Персональные данные Пользователя, которые обрабатывает Оператор</h2>
              <ol class="sub-list">
                <li>Оператор обрабатывает персональную информацию, которую Пользователь предоставляет о себе
                  самостоятельно в процессе использования Сервиса и внесения данных в соответствующие формы,
                  расположенные на Сервисе, включая следующие персональные данные Пользователя: имя; логин;
                  адрес электронной почты; идентификатор аккаунта Google; идентификатор аккаунта Telegram;
                  номер банковского счёта (по желанию Пользователя); веб-ссылки на социальные сети
                  (по желанию Пользователя). Обязательная для предоставления информация помечена специальным
                  знаком «*». Иная информация предоставляется Пользователем на его усмотрение.</li>
                <li>На Сервисе происходит сбор и обработка обезличенных данных о посетителях
                  (в т.ч. файлов «Cookie», IP-адрес Пользователя). Эти данные используются для обеспечения
                  безопасности Сервиса и его Пользователей, улучшения и упрощения процесса посещения Сервиса.
                  Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено
                  в настройках браузера Пользователя (включено сохранение файлов «Cookie» и использование
                  технологии JavaScript). Оператор не использует файлы «Cookie» для хранения персональной
                  информации или раскрытия информации третьим сторонам. Отключение «Cookie» может повлечь
                  невозможность доступа к частям Сервиса, требующим авторизации. Статистика об IP-адресе
                  Пользователя используется с целью выявления и решения технических проблем, для контроля
                  законности проводимых операций.</li>
                <li>Оператор не обрабатывает персональные данные специальной категории, в том числе данные
                  о политических, религиозных и иных убеждениях, о членстве в общественных объединениях и
                  профсоюзной деятельности, о частной и интимной жизни Пользователя.</li>
                <li>В общем случае Оператор не проверяет достоверность персональной информации, предоставляемой
                  Пользователем, и не осуществляет контроль за их дееспособностью.</li>
                <li>Настоящая Политика применяется только к Сервису. Оператор не контролирует и не несет ответственность
                  за Сервисы третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сервисе.</li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Цели обработки персональных данных</h2>
              <ol class="sub-list">
                <li>Оператор собирает и хранит только те персональные данные,
                  которые необходимы для оказания Услуг Пользователю.</li>
                <li>Цели обработки персональных данных Пользователя:
                  <ol class="sub-sub-list">
                    <li>Идентификация стороны в рамках использования Сервиса.</li>
                    <li>Осуществление оказания Услуг и возможности использования Сервиса Пользователем.</li>
                    <li>Предоставление Пользователю доступа к информации, содержащейся на Сервисе.</li>
                    <li>Улучшение качества Сервиса, удобства его использования, разработка новых сервисов, Услуг.</li>
                    <li>Установление с Пользователем обратной связи в случае необходимости, в том числе направление
                      уведомлений, запросов и информации, связанных с использованием Сервиса.</li>
                    <li>Проведение статистических и иных исследований, на основе обезличенных данных.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Правовые основания обработки персональных данных. Согласие Пользователя.</h2>
              <ol class="sub-list">
                <li>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения
                  и/или отправки Пользователем самостоятельно через формы, расположенные на Сервисе.</li>
                <li>Заполняя добровольно соответствующие формы и/или отправляя свои персональные данные
                  Оператору, Пользователь выражает свое согласие с данной Политикой. Согласие Пользователя
                  на обработку персональных данных является конкретным, информированным и сознательным.</li>
                <li>Настоящее согласие Пользователя признается исполненным в простой письменной форме.</li>
                <li>Пользователь как субъект персональных данных имеет право на получение информации, касающейся
                  обработки его персональных данных, в том числе содержащей:
                  <ol class="sub-sub-list">
                    <li>подтверждение факта обработки персональных данных;</li>
                    <li>правовые основания и цели обработки персональных данных;</li>
                    <li>цели и применяемые оператором способы обработки персональных данных;</li>
                    <li> наименование и место нахождения оператора, сведения о лицах (за исключением
                      работников оператора), которые имеют доступ к персональным данным или которым
                      могут быть раскрыты персональные данные на основании договора с оператором
                      или на основании федерального закона;</li>
                    <li>обрабатываемые персональные данные, относящиеся к соответствующему субъекту
                      персональных данных, источник их получения, если иной порядок представления
                      таких данных не предусмотрен Федеральным законом;</li>
                    <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
                    <li>порядок осуществления субъектом персональных данных прав, предусмотренных
                      Федеральным законом;</li>
                    <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
                    <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных
                      данных по поручению Оператора, если обработка поручена или будет поручена такому лицу;</li>
                    <li>иные сведения, предусмотренные Федеральным законом или другими федеральными законами.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Порядок и условия сбора, хранения, передачи и других видов обработки персональных данных</h2>
              <ol class="sub-list">
                <li>Безопасность персональных данных, обрабатываемых Оператором, обеспечивается путем
                  реализации правовых, организационных и технических мер, необходимых для выполнения в
                  полном объеме требований действующего законодательства в области защиты
                  персональных данных.</li>
                <li>Обработка персональных данных Пользователя осуществляется любым законным способом,
                  в том числе в информационных системах персональных данных с использованием средств
                  автоматизации или без использования таких средств.</li>
                <li>В отношении персональной информации Пользователя сохраняется ее конфиденциальность.
                  Оператор обеспечивает сохранность персональных данных и принимает все возможные меры,
                  исключающие доступ к персональным данным неуполномоченных лиц.</li>
                <li>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
                  третьим лицам, за исключением следующих случаев:
                  <ol class="sub-sub-list">
                    <li>Пользователь выразил свое согласие на такие действия.</li>
                    <li>Передача необходима в рамках использования Пользователем Сервиса
                      либо для оказания Услуг Пользователю.</li>
                    <li>Передача предусмотрена российским или иным применимым законодательством в
                      рамках установленной законодательством процедуры.</li>
                    <li>Такая передача происходит в рамках продажи или иной передачи бизнеса
                      (полностью или в части), при этом к приобретателю переходят все обязательства по
                      соблюдению условий настоящей Политики применительно к полученной им персональной
                      информации.</li>
                  </ol>
                </li>
                <li>Доступ к персональным данным имеют только Оператор. В целях обеспечения
                  конфиденциальности информации и защиты персональных данных Оператор принимает все меры,
                  необходимые для предотвращения несанкционированного доступа.</li>
                <li>При утрате или разглашении персональных данных Оператор информирует Пользователя об
                  утрате или разглашении персональных данных.</li>
                <li>В случае выявления неточностей в персональных данных, Пользователь может актуализировать
                  (изменить, обновить, дополнить) их самостоятельно, путем отправки Оператору
                  письма-уведомления на адрес электронной почты Оператора admin@youme.gg с пометкой
                  «Актуализация/изменение персональных данных».</li>
                <li>Срок обработки персональных данных является неограниченным. Пользователь может в любой
                  момент отозвать свое согласие на обработку персональных данных, направив Оператору
                  письмо-уведомление посредством электронной почты на электронный адрес Оператора
                  admin@youme.gg с пометкой «Отзыв согласия на обработку персональных данных».</li>
                <li>Удаление, уничтожение персональных данных осуществляются в порядке, предусмотренном
                  действующим законодательством и нормативными актами Оператора. Удаление,
                  уничтожение персональных данных осуществляется ответственными лицами:
                  <ol class="sub-sub-list">
                    <li>в случаях, предусмотренных законом;</li>
                    <li>по требованию Пользователя, государственного органа или суда;</li>
                    <li>по истечении сроков хранения;</li>
                    <li>за истечением надобности.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Ответственность</h2>
              <ol class="sub-list">
                <li>Политика конфиденциальности не регулирует, и Оператор не несет ответственности за
                  получение, хранение, обработку, использование и раскрытие персональных данных Пользователя
                  третьими лицами, не находящимися во владении или под управлением Оператора, и физическими
                  лицами, не являющимися партнерами Оператора, даже если Пользователь получил доступ к
                  Сервисам товаров или услуг этих лиц с помощью Сервиса или рассылки.</li>
                <li>Пользователь признает, что в случае халатного отношения Пользователя к безопасности и
                  защите своих персональных данных и данных авторизации, третьи лица могут получить
                  несанкционированный доступ к учетной записи, персональным и другим данным Пользователя.
                  В данном случае Оператор не несет ответственности за убытки, причиненные Пользователю
                  и/или третьим лицам таким доступом.</li>
                <li>В случае утраты или разглашения конфиденциальной информации Оператор не несёт
                  ответственность, если данная информация:
                  <ol class="sub-sub-list">
                    <li>стала публичным достоянием до её утраты или разглашения;</li>
                    <li>была получена от третьей стороны до момента её получения Оператором;</li>
                    <li>была разглашена с согласия Пользователя.</li>
                  </ol>
                </li>
                <li>Оператор не несет ответственности за ущерб или убытки, которые понес Пользователь
                  и/или третьи лица в результате ошибочного понимания или непонимания условий Политики,
                  инструкций или указаний о порядке использования Сервиса, относительно порядка
                  размещения данных и других технических вопросов.</li>
              </ol>
            </li>
            <li class="mb-4">
              <h2>Заключительные положения</h2>
              <ol class="sub-list">
                <li>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
                  обработки его персональных данных, обратившись к Оператору по электронной
                  почте admin@youme.gg.</li>
                <li>Оператор имеет право вносить изменения в настоящую Политику в любое время и по своему
                  усмотрению. При внесении изменений в актуальной редакции указывается дата последнего
                  обновления. Новая редакция Политики вступает в силу с момента ее размещения на
                  Сервисе, если иное не предусмотрено новой редакцией Политики.</li>
                <li>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
                  <router-link to="/confidential" class="text-muted text-decoration-none fs-6">
                    <span>https://youme.gg/confidential</span>
                  </router-link>.
                </li>
                <li>Настоящая Политика применима только к Сервису.</li>
                <li>Политика распространяется на Пользователя с момента, когда во время использования Сервиса
                  он при передаче своих персональных данных согласился с условиями Политики, и действует
                  до тех пор, пока у Оператора хранится любая информация о Пользователе, в том числе,
                  персональные данные.</li>
                <li>В случае, если какое-либо положение Политики, включая любое предложение, пункт или их часть,
                  признается противоречащим закону или недействительным, это не повлияет на остальные положения,
                  которые не противоречат закону, они остаются в полной силе и являются действительными,
                  а любое недействительное положение или положение, которое не может быть выполнено без
                  дальнейших действий Сторон, считается измененным, исправленным в той мере, в какой это
                  необходимо для обеспечения его действительности и возможности выполнения.</li>
                <li>Оператор не принимает предложения от Пользователей относительно
                  изменений настоящей Политики.</li>
                <li>Все споры между сторонами решаются путем переговоров. Претензионный порядок решения
                  споров является обязательным, срок ответа на претензию составляет 10 (десять) рабочих дней.</li>
                <li>В случае невозможности достижения согласия путем переговоров, споры решаются в судебном
                  порядке по месту нахождения Оператора.</li>
              </ol>
            </li>
          </ol>
          <h3>Реквизиты Администрации</h3>
          <p class="m-0">ИП Ерасова Е.В.</p>
          <p class="m-0">ИНН: 632524064755</p>
          <p class="m-0">ОГРН: 324632700053071</p>
          <p class="m-0">Электронная почта: admin@youme.gg</p>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 d-flex justify-content-between">
              <p class="mb-0">Дата вступления в силу: 28 марта 2024</p>
              <p class="mb-0">Постоянная ссылка:
                <router-link to="/confidential" class="text-muted text-decoration-none fs-6">
                  <span>https://youme.gg/confidential</span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';


const BreadcrumbsComponent = defineAsyncComponent(() =>
    import("@/components/BreadcrumbsComponent.vue")
);
</script>

<style scoped>
ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.sub-list {
  counter-reset: subitem;
  padding-top: 5px;
}
.sub-list li:before {
  content: "1." counter(subitem) " ";
  counter-increment: subitem;
}

ol {
  counter-reset: section; /* Сбрасываем счетчик для основного списка */
}
ol li {
  counter-increment: section; /* Увеличиваем счетчик на 1 для каждого элемента списка */
}
ol li:before {
  content: counters(section, ".") " "; /* Выводим счетчик и разделитель */
}

.sub-list li {
  counter-increment: subsection;
  padding-bottom: 5px;
}
.sub-list li:before {
  content: counters(section, ".") "." counters(subsection, ".") " "; /* Выводим счетчик и разделитель */
}

.sub-sub-list {
  counter-reset: subsubsection; /* Сбрасываем счетчик для под-подсписка */
}
.sub-sub-list li {
  counter-increment: subsubsection; /* Увеличиваем счетчик на 1 для каждого элемента под-подсписка */
}
.sub-sub-list li:before {
  content: counters(section, ".") "." counters(subsection, ".") "." counter(subsubsection) " "; /* Выводим счетчик и разделитель */
}
li h2 {
  display: inline; /* Или 'inline-block', в зависимости от ваших нужд */
  margin-right: 10px; /* Добавляет небольшой отступ справа, если нужно */
  font-size: 20px;
}
h3 {
  font-size: 19px;
}
</style>