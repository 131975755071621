<template>
  <div class="card-body">
    <div class="row" v-if="activeSubscriptions.length > 0">
      <div
          class="col-12 col-xxl-4 col-md-6 subscriptionCard"
          v-for="subscription in activeSubscriptions"
          :key="subscription.plan_id"
      >
        <div class="card h-100 rounded-4 text-decoration-none">
          <div class="card-body">
            <div class="title-wrapper d-flex align-items-center justify-content-between">
              <div class="mb-0 fw-bolder fs-5">«{{ parsePlanName(subscription.plan.name).name }}»</div>
              <small class="toolVersion d-flex align-items-center" v-if="parsePlanName(subscription.plan.name).version">v{{ parsePlanName(subscription.plan.name).version }}</small>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <div class="text-center">{{ formatDuration(subscription.to) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 50px; padding-bottom: 50px;" v-else>
      <div class="row justify-content-center align-items-center">
        <div class="col-6">
          <h4 class="text-center mb-0">Активных подписок нет</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();

const activeSubscriptions = computed(() => {
  const now = new Date();
  const uniqueSubscriptions = new Map();

  authStore.subscriptions.forEach(sub => {
    const to = new Date(sub.to);
    if (to > now) {
      if (!uniqueSubscriptions.has(sub.plan_id) || new Date(uniqueSubscriptions.get(sub.plan_id).to) < to) {
        uniqueSubscriptions.set(sub.plan_id, sub);
      }
    }
  });

  return Array.from(uniqueSubscriptions.values());
});

const parsePlanName = (name) => {
  const versionMatch = name.match(/(v\d+)$/);
  if (versionMatch) {
    return {
      name: name.replace(versionMatch[0], '').trim(),
      version: versionMatch[1].substring(1)
    };
  }
  return { name, version: null };
};

const formatDuration = (toDate) => {
  const now = new Date();
  const endDate = new Date(toDate);
  const totalSeconds = Math.ceil((endDate - now) / 1000);

  if (totalSeconds < 0) {
    return 'срок истек';
  }

  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const getWord = (n, singular, few, many) => {
    if (n % 10 === 1 && n % 100 !== 11) {
      return singular;
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
      return few;
    } else {
      return many;
    }
  };

  const yearWord = (n) => getWord(n, 'год', 'года', 'лет');
  const monthWord = (n) => getWord(n, 'месяц', 'месяца', 'месяцев');
  const dayWord = (n) => getWord(n, 'день', 'дня', 'дней');
  const hourWord = (n) => getWord(n, 'час', 'часа', 'часов');
  const minuteWord = (n) => getWord(n, 'минута', 'минуты', 'минут');

  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);
  const remainingDays = days % 365 % 30;

  if (days > 0) {
    if (days >= 365) {
      return `${years} ${yearWord(years)}${months > 0 ? ` и ${months} ${monthWord(months)}` : ''}`;
    } else if (days >= 30) {
      return `${months} ${monthWord(months)}${remainingDays > 0 ? ` и ${remainingDays} ${dayWord(remainingDays)}` : ''}`;
    } else {
      return `${remainingDays} ${dayWord(remainingDays)} и ${hours} ${hourWord(hours)}`;
    }
  } else {
    return `${hours} ${hourWord(hours)} и ${minutes} ${minuteWord(minutes)}`;
  }
};


onMounted(() => {
  authStore.restoreState();
});
</script>

<style scoped>
.toolVersion {
  font-size: 15px;
  background: #0D6EFDFF;
  color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .subscriptionCard:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>