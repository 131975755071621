<template>
  <div class="row user-select-none mb-3">
    <div class="col-12 mb-3 d-flex align-items-center d-none d-xl-block">
      <h1 class="fw-bolder mb-0 d-flex align-items-center pe-2">Поиск ключевых слов v1</h1>
    </div>
    <div class="col-12">
      <breadcrumbs-component />
    </div>
  </div>
  <search-form />
  <keyword-data />
  <related-results v-if="keywordStore.keywordData" />
  <trending-now v-else />
</template>

<script setup>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import SearchForm from "@/components/tools/keywordResearchV1/SearchForm.vue";
import TrendingNow from "@/components/tools/keywordResearchV1/TrendingNow.vue";
import KeywordData from "@/components/tools/keywordResearchV1/searchResult/KeywordData.vue";
import RelatedResults from "@/components/tools/keywordResearchV1/searchResult/RelatedResults.vue";
import { useKeywordStore } from '@/store/tools/v1/KeywordsResearch';

const keywordStore = useKeywordStore();
</script>
